import React from 'react';

const Coordinate_Report: React.FC = () => {
	return (
		<>
			<h1>Coordinate_Report</h1>
		</>
	);
};

export default Coordinate_Report;

