import { APP_ROUTES } from '../../service/routes/AppRouter.constants';
import { Route } from '../../components/RouteTabs/RouteTabs.types';

export const dashboardRoutes: Route[] = [
	{
		label: 'Site info',
		id: APP_ROUTES.DASHBOARD.SITEINFO,
	},
	{
		label: 'Siteconfig',
		id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.INDEX,
	},
	{
		label: 'Contact persons',
		id: APP_ROUTES.DASHBOARD.SITECONTACTS,
		disabled: true,
	},
	{
		label: 'Images',
		id: APP_ROUTES.DASHBOARD.SITEIMAGES,
		disabled: true,
	},
	{
		label: 'Network components',
		id: APP_ROUTES.DASHBOARD.SITENETWORKCOMPONENTS,
		disabled: true,
	},
	{
		label: 'Equipment',
		id: APP_ROUTES.DASHBOARD.SITEEQUIPMENT,
		disabled: true,
	},
	{
		label: 'Optional equipment',
		id: APP_ROUTES.DASHBOARD.SITEOPTIONALEQUIPMENT,
		disabled: true,
	},
	{
		label: 'Interference',
		id: APP_ROUTES.DASHBOARD.SITEINTERFERENCE,
		disabled: true,
	},
];
