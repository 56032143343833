import { Country, CountryListType } from './country.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ApplicationStateType } from '../store';

const initialState = null as CountryListType | null;

export const countrySlice = createSlice({
	name: 'country',
	initialState,
	reducers: {
		setCountryList: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return action.payload;
		},
		addCountry: (state, action: PayloadAction<Country | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					countryList: [action.payload],
				};
			}

			return {
				countryList: [...state.countryList, action.payload],
			};
		},
		updateCountry: (state, action: PayloadAction<Country | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					countryList: [action.payload],
				};
			}

			return {
				countryList: state.countryList.map(country => {
					if (country.id === action.payload?.id) {
						return action.payload;
					}

					return country;
				}),
			};
		},
	},
});

export const selectCountry = (state: ApplicationStateType) => state.countryState;

export default countrySlice.reducer;
