import { Controller } from 'react-hook-form';
import { InputProps } from './Form.types';
import React from 'react';
import TextField from '@mui/material/TextField';

/**
 * Text input form field.
 */
export const TextInput: React.FC<InputProps> = props => {
	const {
		control,
		name,
		label,
		id,
		type = 'text',
		helperText,
		disabled = false,
		required = false,
		multiline = false,
	} = props;

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, ...fieldProps }, fieldState: { error } }) => {
				return (
					<TextField
						label={label}
						id={id}
						disabled={disabled}
						required={required}
						multiline={multiline}
						variant='outlined'
						type={type}
						inputProps={{ onBlur }}
						value={value || ''}
						{...fieldProps}
						fullWidth={true}
						error={Boolean(error)}
						helperText={error?.message ? error.message : helperText}
					/>
				);
			}}
		/>
	);
};
