export const paperStyles = {
	root: {
		padding: '10px',
	},

	// Red background and border for the MuiPaper component
	error: {
		backgroundColor: 'rgba(244, 67, 54, 0.08)',
		border: 'solid 2px rgba(244, 67, 54, 0.4)',
	}
};
