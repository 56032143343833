import { AntennaHist, AntennaHistCreateParamType } from '../../../../redux/AntennaHist/antennaHist.types';
import { FormListType, addForm, getAttributeList, validateForm } from './HistService';
import React, { useEffect, useState } from 'react';
import {
	addAntennaHistElement,
	deleteAntennaHistElement,
	putAntennaHistElement,
	putSiteConfigHistTimestampUpdated,
} from '../../../../redux/Site/siteData.actions';
import { groupBy, sortGrouped } from '../../../../service/groupListService';
import MuiAccordion from '../../../../components/Accordion/Accordion';
import MuiTable from '../../../../components/Table/Table/Table';
import { SiteConfig } from '../../../../redux/SiteConfig/siteConfig.types';
import { SiteConfigHist } from '../../../../redux/SiteConfigHist/siteConfigHist.types';
import { momentPrettyFormat } from '../../../../service/moment';
import { regexList } from '../../../../service/regex';
import { selectSiteData } from '../../../../redux/Site/siteData.slice';
import { useAppSelector } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';

const SiteConfigAntennaHist = (): JSX.Element => {
	const { siteConfigId } = useParams<{ siteConfigId: string }>();
	// Get fourCharId from siteconfigId
	const siteData = useAppSelector(selectSiteData);

	let fourCharId = '';

	if (siteData !== null && siteConfigId !== undefined) {
		const siteConfigs = siteData.siteConfigs;

		for (const siteConfig of siteConfigs) {
			if (siteConfig.id.toString() === siteConfigId) {
				fourCharId = siteConfig.fourCharId;
			}
		}
	}

	const [antennaHistDataList, setAntennaHistDataList] = useState<any[]>([]);
	const [antennaHistGrouped, setAntennaHistGrouped] = useState<any>();

	const [formValues, setFormValues] = useState<AntennaHistCreateParamType>({
		antennaId: 0,
		timestampValidFrom: '',
		preparedBy: '',
		toRadomeId: 0,
	});
	const [error, setError] = useState<any>(undefined);
	const formList: FormListType[] = [
		{
			label: 'Valid from',
			keyName: 'timestampValidFrom',
			regex: regexList.dateTimePrettyFormat,
			number: false,
		},
		{
			label: 'Established by',
			keyName: 'preparedBy',
			regex: regexList.preparedBy,
			number: false,
		},
		{
			label: 'To radome id',
			keyName: 'toRadomeId',
			regex: regexList.radomeId,
			number: true,
		},
	];

	useEffect(() => {
		const tmpList: any[] = [];

		if (siteData !== null) {
			siteData.siteConfigs.forEach((siteConfig: SiteConfig) => {
				if (siteConfig.siteConfigHists !== null) {
					siteConfig.siteConfigHists.forEach((siteConfigHistsElement: SiteConfigHist) => {
						if (siteConfigHistsElement.toAntenna !== null) {
							if (siteConfigHistsElement.toAntenna.antennaHists !== undefined) {
								const fromDate = momentPrettyFormat(siteConfigHistsElement.timestampValidFrom);

								siteConfigHistsElement.toAntenna.antennaHists.forEach((antennaHistsElement: AntennaHist) => {
									const timestamp = momentPrettyFormat(antennaHistsElement.timestampValidFrom);

									const tmp = {
										'Config': siteConfig.fourCharId,
										'SiteConfigHist': siteConfigHistsElement,
										'Antenne': siteConfigHistsElement.toAntenna?.serialNumber,
										'Type': siteConfigHistsElement.toAntenna?.antennaType.antennaTypeName,
										'Fra dato': fromDate,
										'id': antennaHistsElement.id,
										'Established by': antennaHistsElement.preparedBy,
										'Valid from': timestamp,
										'Fra Radome Id': antennaHistsElement.fromRadomeId,
										'To radome id': antennaHistsElement.toRadomeId,
									};

									// Get AntennaHists for the current fourCharId
									if (tmp.Config === fourCharId) {
										if (tmpList.find(x => x.id === tmp.id) === undefined) {
											tmpList.push(tmp);
										}
									}
								});
							}
						}
					});
				}
			});
		}

		setAntennaHistDataList(tmpList);

	}, [siteData, fourCharId]);

	useEffect(() => {
		if (antennaHistDataList.length > 0) {
			const groupedData = groupBy(antennaHistDataList, 'Antenne');
			const sortedGroupedtAntennaHistList = sortGrouped(groupedData, 'Antenne', 'Fra dato');

			setAntennaHistGrouped(sortedGroupedtAntennaHistList);
		}
	}, [antennaHistDataList]);

	const handleFormChange = (keyProp: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues, [keyProp]: event.target.value });
	};

	return (
		<>
			{
				antennaHistDataList.length > 0 &&
				antennaHistGrouped !== undefined &&
				<>
					{
						antennaHistGrouped.map((serialNumber: any[], index: number) =>
							<MuiAccordion
								key={serialNumber[0].Antenne}
								id={index}
								name={serialNumber[0].Antenne}
								title={[
									{
										label: 'Antenna:',
										value: serialNumber[0].Antenne,
									},
									{
										label: serialNumber[0].Config,
										value: serialNumber[0].Config,
										badge: true,
									},
									{
										label: 'Type:',
										value: serialNumber[0].Type,
									},
									{
										label: 'Valid from:',
										value: serialNumber[0]['Fra dato'],
										editable: {
											editFuncCallback: putSiteConfigHistTimestampUpdated,
											param: serialNumber[0].SiteConfigHist,
											validation: regexList.dateTimePrettyFormat,
										}
									}
								]}
								body={
									<MuiTable
										name={'Site-Antenna-Historikk'}
										head={getAttributeList(formList, 'label')}
										body={serialNumber}
										editable={{
											editFuncCallback: putAntennaHistElement,
											validation: getAttributeList(formList, 'regex'),
										}}
										deleteFuncCallback={deleteAntennaHistElement}
										addable={{
											title: 'Add antenna history',
											form: {
												modal: addForm(formValues, formList, error, handleFormChange),
												values: formValues,
												validate: () => {
													const validatedState = validateForm(formValues, formList);

													setError(validatedState.tmpErrObj);

													return validatedState.errorMsgStatus;
												},
											},
											buttonText: 'Add',
											addFuncCallback: addAntennaHistElement,
											param: serialNumber[0].Antenne
										}}
									/>
								}
							/>
						)
					}
				</>
			}
		</>
	);
};

export default SiteConfigAntennaHist;
