import { AntRefPointType, AntRefPointTypeListType } from './antRefPointType.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ApplicationStateType } from '../store';

const initialState = null as AntRefPointTypeListType | null;

export const antRefPointTypeSlice = createSlice({
	name: 'antRefPointType',
	initialState,
	reducers: {
		setAntRefPointTypeList: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return action.payload;
		},
		addAntRefPointType: (state, action: PayloadAction<AntRefPointType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					antRefPointTypeList: [action.payload],
				};
			}

			return {
				antRefPointTypeList: [...state.antRefPointTypeList, action.payload],
			};
		},
		updateAntRefPointType: (state, action: PayloadAction<AntRefPointType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					antRefPointTypeList: [action.payload],
				};
			}

			return {
				antRefPointTypeList: state.antRefPointTypeList.map(antRefPointType => {
					if (antRefPointType.id === action.payload?.id) {
						return action.payload;
					}

					return antRefPointType;
				})
			};
		},
	},
});

export const selectAntRefPointType = (state: ApplicationStateType) => state.antRefPointTypeState;

export default antRefPointTypeSlice.reducer;
