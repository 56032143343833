export interface FoundationType {
	id: number,
	foundationTypeName: string
}

export const foundationTypeTemplate: FoundationType = {
	id: 0,
	foundationTypeName: '',
};

