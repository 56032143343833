import React, { useEffect } from 'react';
import { generatePath, useMatch, useMatches, useNavigate, useParams } from 'react-router-dom';

import { APP_ROUTES } from '../../../../service/routes/AppRouter.constants';
import Alert from '@mui/material/Alert';
import { ResolvedRoute } from '../../../../components/RouteTabs/RouteTabs.types';
import RouteTabs from '../../../../components/RouteTabs/RouteTabs';
import { selectSiteData } from '../../../../redux/Site/siteData.slice';
import { useAppSelector } from '../../../../redux/hooks';

// Displayed on press Home -> SITECONFIG
const Konfigurasjon = (): JSX.Element => {
	const loading =
		<Alert severity='warning'>
			{'Loading site data...'}
		</Alert>;

	const navigate = useNavigate();

	const matches = useMatches();

	const { siteId, siteConfigId } = useParams<{ siteId: string, siteConfigId: string }>();

	const siteData = useAppSelector(selectSiteData);

	const indexMatch = useMatch(APP_ROUTES.DASHBOARD.SITECONFIGINFO.INDEX);

	const singleMatch = useMatch(APP_ROUTES.DASHBOARD.SITECONFIGINFO.DETAILS);

	useEffect(() => {
		const extraRoutes = [
			// APP_ROUTES.DASHBOARD.SITECONFIGINFO.ADD_SITECONFIG,
			APP_ROUTES.DASHBOARD.SITECONFIGINFO.RECEIVER_HISTORY,
			APP_ROUTES.DASHBOARD.SITECONFIGINFO.ANTENNA_HISTORY,
			APP_ROUTES.DASHBOARD.SITECONFIGINFO.MARKREFPOINT_HISTORY,
		];

		// If we are already at the route for a single site config, don't redirect.
		if (singleMatch !== null) {
			return;
		}

		// On the extra routes, don't redirect.
		if (extraRoutes.some(route => matches.some(({ id }) => id === route))) {
			return;
		}

		// Automatically redirect.
		if (siteConfigId !== undefined) {
			navigate(generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.DETAILS, { siteId, siteConfigId: Number.parseInt(siteConfigId, 10) }));
		} else if (siteData !== null && siteData.siteConfigs.length > 0) {
			navigate(generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.DETAILS, { siteId, siteConfigId: siteData.siteConfigs[0].id }));
		} else {
			throw new Error('Could not redirect');
		}
	});

	// If we are on the index route we return early; the useEffect above will redirect to the correct route.
	// The reason we return early is because the Tabs component below expects specific route urls,
	// and will complain if it finds something unexpected.
	if (indexMatch !== null) {
		return loading;
	}

	// Site id shouldn't ever be undefined, but we do this check to make the TypeScript type checker happy.
	if (siteId === undefined) {
		return loading;
	}

	if (siteData === null) {
		return loading;
	}

	if (siteData.siteConfigs.length === 0) {
		return (
			<Alert severity='error'>
				{'Couldn\'t find any site configurations.'}
			</Alert>
		);
	}

	const stateIsSynced = siteData.siteConfigs.find(({ id }) => id === Number.parseInt(siteConfigId || '0', 10)) !== undefined;

	// When navigating directly to another site config, there might be a moment where the redux store
	// is not synced with the site config id obtained from the url. The component will re-render on sync.
	if (siteConfigId !== undefined && !stateIsSynced) {
		return loading;
	}

	const routes: ResolvedRoute[] = [
		...(siteData?.siteConfigs || []).map(({ id, fourCharId }) => ({
			id: generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.SINGLE, { siteId, siteConfigId: id }),
			url: generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.SINGLE, { siteId, siteConfigId: id }),
			label: fourCharId
		})),
		// {
		// 	id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.ADD_SITECONFIG,
		// 	url: generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.ADD_SITECONFIG, { siteId }),
		// 	label: 'Add siteconfig',
		// },
	];

	return <RouteTabs routes={routes} />;
};

export default Konfigurasjon;
