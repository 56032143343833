import MuiChip from './Chip';
import React from 'react';
import { SiteConfigStatChipPropsType } from './Chip.types';

const SiteConfigStatChip: React.FC<SiteConfigStatChipPropsType> = (props: SiteConfigStatChipPropsType) => {
	return (
		<MuiChip
			{...props}
			label={props.type}
			type={
				props.type === 'Operational' ? 'primary'
					: props.type === 'Temporary' ? 'warning'
						: props.type === 'Closed' ? 'error'
							: 'locked'
			}
		/>
	);
};

export default SiteConfigStatChip;
