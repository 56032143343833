import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material';
import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Icon from '../../Icon/Icon';
import MuiTableRow from './TableRow';
import { TablePropsType } from '../Table.types';
import { tableStyles } from '../Table.styles';
import { useAppDispatch } from '../../../redux/hooks';

const MuiTable: React.FC<TablePropsType> = (props: TablePropsType) => {
	const classes = tableStyles;
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	const handleAdd = async (): Promise<void> => {
		if (props.addable !== undefined) {
			const inputErrorStatus = props.addable.form.validate();

			if (!inputErrorStatus) {
				await dispatch(props.addable.addFuncCallback(props.addable.form.values, props.addable.param));
				setOpenDialog(false);
			}
		}
	};

	return (
		<TableContainer component={Paper} className={[classes.root, props.className].join(' ')}>
			<Table aria-label={props.name} size={props.size || 'medium'}>
				<TableHead>
					<TableRow>
						{props.head.map((headRowElement: string, index: number) =>
							<TableCell key={index} sx={classes.headCell} align={'center'}>{headRowElement}</TableCell>
						)}
						{
							props.addable !== undefined
								? <TableCell sx={classes.headCell} align={'center'}>
									<Icon
										primary={true}
										sx={{ color: 'white', '&:hover': { backgroundColor: 'primary.light' } }}
										IconComponent={AddIcon}
										onClick={() => setOpenDialog(true)}
										hoverText={props.addable.buttonText}
									/>
								</TableCell>
								: props.editable && <TableCell sx={classes.headCell} align={'center'}></TableCell>
						}
					</TableRow>
				</TableHead>
				<TableBody>
					{props.body.map((bodyRowElement, index: number) =>
						<MuiTableRow
							key={index}
							keys={props.head}
							editable={props.editable}
							deletable={props.deletable}
							deleteFuncCallback={props.deleteFuncCallback}
							row={bodyRowElement} />
					)}

					{
						props.addable && <Dialog
							open={openDialog}
							keepMounted
							onClose={() => setOpenDialog(false)}
						>
							<Grid item xs={12} sx={classes.dialog}>
								<Grid item xs={12} style={{ display: 'flex' }}>
									<Grid item xs={10}>
										{props.addable.title && <DialogTitle>{props.addable.title}</DialogTitle>}
									</Grid>
									<Grid item xs={2}>
										<Icon IconComponent={CloseIcon} error onClick={() => setOpenDialog(false)} hoverText={'Close'}/>
									</Grid>
								</Grid>
								<DialogActions sx={classes.dialog}>
									<Grid item xs={12}>
										{props.addable.form.modal}
										<Grid item xs={12} style={{ marginTop: '15px' }}>
											<Button variant={'contained'} color={'primary'}
												onClick={handleAdd}>
												{props.addable.buttonText}
											</Button>
										</Grid>
									</Grid>
								</DialogActions>
							</Grid>
						</Dialog>
					}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default MuiTable;
