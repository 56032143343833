/**
 * Represents a single receiver type
 */
export interface ReceiverType {
    id: number;
    receiverTypeName: string;
}

/**
 * Represents a list of receiver types
 */
export interface ReceiverTypeListType {
    receiverTypeList: ReceiverType[];
}

/**
 * Represents a id-less receiver type, suitable for adding new receiver type with the api
 */
export type AddReceiverTypeType = Omit<ReceiverType, 'id'>;

export const receiverTypeTemplate: ReceiverType = {
	id: 0,
	receiverTypeName: '',
};
