import { CableType, cableTypeTemplate } from '../CableType/cableType.types';

export interface Cable {
	id: number;
	fourCharIds: string[];
	length: number;
	timestampRegistered: string;
	additionalInformation: string | null;
	cableType: CableType;
}

export const cableTemplate: Cable = {
	id: 0,
	fourCharIds: [],
	length: 0,
	timestampRegistered: '',
	additionalInformation: '',
	cableType: cableTypeTemplate,
};
