import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AlertStateType } from './alert.types';
import type { ApplicationStateType } from '../store';

const initialState = {
	open: false,
	severity: 'info',
	title: '',
	message: '',
	constant: false,
} as AlertStateType;

export const alertSlice = createSlice({
	name: 'alert',
	initialState,
	reducers: {
		setAlertState: (state, action: PayloadAction<typeof initialState>) => ({
			...state,
			...action.payload
		}),
	},
});

export const selectAlertState = (state: ApplicationStateType) => state.alertState;

export default alertSlice.reducer;
