import { Link, Outlet, useMatches } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import React from 'react';
import { RouteTabsPropType } from './RouteTabs.types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { tabStyles } from '../Tabs/Tabs.styles';

const RouteTabs: React.FC<RouteTabsPropType> = ({ routes: tabs }) => {
	const matches = useMatches();

	const classes = tabStyles;

	const candidateRoutes: Set<string> = new Set(tabs.flatMap(({ id, url }) => [id, url]));

	let selection: string | null = null;

	for (const { id, pathname } of [...matches].reverse()) {
		if (candidateRoutes.has(id) || candidateRoutes.has(pathname)) {
			selection = pathname;
		}
	}

	if (selection === null) {
		return (
			<Alert severity='error'>
				{'Cannot resolve route tabs against the current route.'}
			</Alert>
		);
	}

	return (
		<React.Fragment>
			<AppBar position={'static'} sx={classes.root}>
				<Tabs
					// The value of the tabs selection is the current route id, allowing to refresh and still be
					// in the correct tab.
					value={selection}
					scrollButtons={'auto'}
					variant={'scrollable'}
					TabIndicatorProps={{ sx: classes.tabIndicator }}
					textColor={'inherit'}>
					{
						tabs.map(({ label, id, url, disabled }) =>
							<Tab
								key={id}
								label={label}
								value={url}
								to={url}
								component={Link}
								sx={
									disabled === true
										? { ...classes.root, ...classes.disabled }
										: classes.root
								} />
						)
					}
				</Tabs>
			</AppBar>
			<Outlet />
		</React.Fragment>
	);
};

export default RouteTabs;
