import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ApplicationStateType } from '../store';
import { TagListType } from './tag.types';

const initialState = null as TagListType | null;

export const tagListSlice = createSlice({
	name: 'tag',
	initialState,
	reducers: {
		setTagList: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return action.payload;
		},
		resetTagList: () => null,
	},
});

export const selectTagList = (state: ApplicationStateType) => state.tagListState;

export default tagListSlice.reducer;
