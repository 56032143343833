import React from 'react';

const Igs_Sitelog: React.FC = () => {
	return (
		<>
			<h1>Igs_Sitelog</h1>
		</>
	);
};

export default Igs_Sitelog;

