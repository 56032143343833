import HorizontalTable from '../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../components/Table/Table.types';
import { MarkAntRefPoint } from '../../redux/MarkAntRefPoint/markAntRefPoint.types';
import MuiPaper from '../../components/Paper/Paper';
import React from 'react';
import { momentPrettyFormat } from '../../service/moment';
import { paperStyles } from '../../components/Paper/Paper.styles';

const MarkAntRefPointDetails = (markAntRefPoint: MarkAntRefPoint): JSX.Element => {
	const getContent = (markAntRefPointData: MarkAntRefPoint): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = markAntRefPointData.id !== 0;

		contentList.push(
			{
				title: 'ID:',
				body: isPresent ? markAntRefPointData.id : '',
			},
			{
				title: 'dN:',
				body: isPresent ? markAntRefPointData.deltaNorth : '',
			},
			{
				title: 'dE:',
				body: isPresent ? markAntRefPointData.deltaEast : '',
			},
			{
				title: 'dH:',
				body: isPresent ? markAntRefPointData.deltaHeight : '',
			},
			{
				title: 'Registered:',
				body: isPresent ? momentPrettyFormat(markAntRefPointData.timestampRegistered) : '',
			},
			{
				title: 'Additional information:',
				body: isPresent ? markAntRefPointData.additionalInformation : '',
			},
		);

		return contentList;
	};

	return (
		<MuiPaper style={markAntRefPoint.id === 0 ? paperStyles.error : {}} title={'Eccentricity'}>
			<HorizontalTable content={getContent(markAntRefPoint)} />
		</MuiPaper >
	);
};

export default MarkAntRefPointDetails;
