import { Chip } from '@mui/material';
import { ChipPropsType } from './Chip.types';
import React from 'react';

const MuiChip: React.FC<ChipPropsType> = (props: ChipPropsType) => {
	let color:
		| 'default'
		| 'primary'
		| 'warning'
		| 'error' = 'default';

	if (props.type === 'primary') {
		color = props.type;
	}

	if (props.type === 'warning') {
		color = props.type;
	}

	if (props.type === 'error') {
		color = props.type;
	}

	return (
		<Chip
			{...props}
			color={color}
			variant={props.type === 'locked' ? 'outlined' : 'filled'}
			sx={[
				{ fontWeight: 'bold', padding: '5px', margin: '2px' },
				...Array.isArray(props.sx) ? props.sx : [props.sx]
			]}
			size={props.size}
			label={props.label}
			className={props.className}
			style={props.style}
		/>
	);
};

export default MuiChip;
