import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { fetchAntenna, resetAntennaState } from '../../../redux/Antenna/antenna.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { APP_ROUTES } from '../../../service/routes/AppRouter.constants';
import { Antenna } from '../../../redux/Antenna/antenna.types';
import AntennaDetails from './AntennaDetails';
import AntennaHistory from './AntennaHistory';
import { AntennaUpdate } from './AntennaUpdate';
import Grid from '@mui/material/Grid';
import Loader from '../../../components/Loader/Loader';
import MuiPaper from '../../../components/Paper/Paper';
import MuiTabs from '../../../components/Tabs/Tabs';
import { selectAntennaState } from '../../../redux/Antenna/antenna.slice';

const Content = (antenna: Antenna): JSX.Element => {
	return (
		<div>
			<MuiTabs name={`Antenna-${antenna.id}-Alternatives`} tabsList={[
				{ label: 'Details', component: <AntennaDetails {...antenna} /> },
				{ label: 'Edit', component: <AntennaUpdate /> },
				{ label: 'History', component: <AntennaHistory {...antenna} /> },
			]} />
		</div>
	);
};

const AntennaSingle: React.FC = () => {
	const { id = '0' } = useParams<{ id: string }>();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchAntenna({ antennaId: Number.parseInt(id) }));

		return () => {
			dispatch(resetAntennaState());
		};
	}, [dispatch, id]);

	const antenna = useAppSelector(selectAntennaState);

	if (antenna === null) {
		return <Loader/>;
	}

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<p><Link to={APP_ROUTES.UTILITIES.ANTENNAS}>← Back to Antennas</Link></p>
					<h1> Antenna {antenna.antennaType.antennaTypeName} {antenna.serialNumber} (ID: {antenna.id})</h1>
					<Grid item xs={12}>
						<Content {...antenna} />
					</Grid>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default AntennaSingle;
