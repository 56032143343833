import { AddRadomeTypeType, RadomeType } from './radomeType.types';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { radomeTypeListMock, radomeTypeMock } from './radomeType.mock';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { getEnvStateData } from '../../service/envStateData';
import { radomeTypeSlice } from './radomeType.slice';

export const { setRadomeTypeList, addRadomeType, updateRadomeType } = radomeTypeSlice.actions;

/**
 * Updates the store with all radome types obtained from the api
 */
export const fetchRadomeTypeList = () => {
	return async (dispatch: ApplicationDispatch) => {
		const radomeTypeList: RadomeType[] | null =
			await getEnvStateData(getRequest<RadomeType[]>(API_ENDPOINTS.GET_RADOMETYPES), radomeTypeListMock);

		const result = radomeTypeList && { radomeTypeList };

		return dispatch(setRadomeTypeList(result));
	};
};

/**
 * Adds an radome type with the api and updates the store with the newly obtained radome type
 */
export const addRadomeTypeElement = ({ radomeTypeName }: AddRadomeTypeType) => {
	return async (dispatch: ApplicationDispatch) => {
		const radomeType: RadomeType | null =
			await getEnvStateData(
				postRequest<RadomeType, AddRadomeTypeType>(
					API_ENDPOINTS.POST_RADOMETYPE,
					{ radomeTypeName }
				),
				radomeTypeMock,
			);

		return dispatch(addRadomeType(radomeType));
	};
};

/**
 * Updates an existing radome type with the api and updates the store with the updated radome type
 */
export const updateRadomeTypeElement = (oldRadomeType: RadomeType) => {
	return async (dispatch: ApplicationDispatch) => {
		const newRadomeType: RadomeType | null =
			await getEnvStateData(
				putRequest<RadomeType, RadomeType>(
					API_ENDPOINTS.PUT_RADOMETYPE,
					oldRadomeType,
				),
				radomeTypeMock,
			);

		return dispatch(updateRadomeType(newRadomeType));
	};
};
