import { ExtClockType, extClockTypeTemplate } from '../ExtClockType/extClockType.types';
import { SiteMinimal, siteMinimalTemplate } from '../Site/site.types';

export interface ExtClock {
	id: number,
	extClockType: ExtClockType,
	site: SiteMinimal,
	timestampRegistered: string,
	serialNumber: string,
	frequencyMHZ: number | null,
	ipAddress: string,
	exist: number | null,
	equipmentLabel: string
}

export const extClockTemplate: ExtClock = {
	id: 0,
	extClockType: extClockTypeTemplate,
	site: siteMinimalTemplate,
	timestampRegistered: '',
	serialNumber: '',
	frequencyMHZ: null,
	ipAddress: '',
	exist: null,
	equipmentLabel: ''
};
