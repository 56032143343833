import { Grid } from '@mui/material';
import HorizontalTable from '../../../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../../../components/Table/Table.types';
import { LocalTie } from '../../../../../redux/LocalTie/localTie.types';
import { MarkRefPoint } from '../../../../../redux/MarkRefPoint/markRefPoint.types';
import MuiPaper from '../../../../../components/Paper/Paper';
import React from 'react';
import { momentPrettyFormat } from '../../../../../service/moment';

const CoordinateInfoCard = (props: MarkRefPoint): JSX.Element => {
	const getContent = (coordinateData: MarkRefPoint): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		contentList.push({
			title: 'Markrefpoint ID:',
			body: coordinateData.id,
		});

		contentList.push({
			title: 'Marker registered:',
			body: coordinateData.timestampRegistered ? momentPrettyFormat(coordinateData.timestampRegistered) : undefined,
		});

		contentList.push({
			title: 'Marker description:',
			body: coordinateData.markerDescription || undefined,
		});

		contentList.push({
			title: 'Solution flag name:',
			body: coordinateData.solutionFlag ? coordinateData.solutionFlag.solutionFlagName : undefined,
		});

		contentList.push({
			title: 'Solution counter:',
			body: coordinateData.solutionCounter || undefined,
		});

		contentList.push({
			title: 'Solution description:',
			body: coordinateData.solutionDescription || undefined,
		});

		contentList.push({
			title: '',
			body: undefined,
		});

		if (coordinateData.referenceSystemPosRef !== null) {
			if (coordinateData.referenceSystemPosRef.referenceSystemName !== null) {
				contentList.push({
					title: 'Coordinate reference system:',
					body: coordinateData.referenceSystemPosRef.referenceSystemName,
					subheader: true,
				});
			}
		} else {
			contentList.push({
				title: 'Coordinate reference system:',
				body: undefined,
				subheader: true,
			});
		}

		if (coordinateData.epochPosRef !== null) {
			contentList.push({
				title: 'Coordinate epoch:',
				body: momentPrettyFormat(coordinateData.epochPosRef.toString()),
			});
		} else {
			contentList.push({
				title: 'Coordinate epoch:',
				body: undefined
			});
		}

		if (coordinateData.posXRef !== null) {
			if (coordinateData.sigmaPosXRef !== null) {
				contentList.push({
					title: 'X:',
					body: coordinateData.posXRef + ' +/- ' + coordinateData.sigmaPosXRef,
				});
			} else {
				contentList.push({
					title: 'X:',
					body: coordinateData.posXRef,
				});
			}
		} else {
			contentList.push({
				title: 'X:',
				body: undefined,
			});
		}

		if (coordinateData.posYRef !== null) {
			if (coordinateData.sigmaPosYRef !== null) {
				contentList.push({
					title: 'Y:',
					body: coordinateData.posYRef + ' +/- ' + coordinateData.sigmaPosYRef,
				});
			} else {
				contentList.push({
					title: 'Y:',
					body: coordinateData.posYRef,
				});
			}
		} else {
			contentList.push({
				title: 'Y:',
				body: undefined,
			});
		}

		if (coordinateData.posZRef !== null) {
			if (coordinateData.sigmaPosZRef !== null) {
				contentList.push({
					title: 'Z:',
					body: coordinateData.posZRef + ' +/- ' + coordinateData.sigmaPosZRef,
				});
			} else {
				contentList.push({
					title: 'Z:',
					body: coordinateData.posZRef,
				});
			}
		} else {
			contentList.push({
				title: 'Z:',
				body: undefined,
			});
		}

		contentList.push({
			title: '',
			body: undefined,
		});

		if (coordinateData.referenceSystemPos !== null) {
			if (coordinateData.referenceSystemPos.referenceSystemName !== null) {
				contentList.push({
					title: 'Coordinate reference system:',
					body: coordinateData.referenceSystemPos.referenceSystemName,
					subheader: true,
				});
			}
		} else {
			contentList.push({
				title: 'Coordinate reference system:',
				body: undefined,
				subheader: true,
			});
		}

		if (coordinateData.epochPos !== null) {
			contentList.push({
				title: 'Coordinate epoch:',
				body: momentPrettyFormat(coordinateData.epochPos.toString()),
			});
		} else {
			contentList.push({
				title: 'Coordinate epoch:',
				body: undefined
			});
		}

		if (coordinateData.posX !== null) {
			if (coordinateData.sigmaPosX !== null) {
				contentList.push({
					title: 'X:',
					body: coordinateData.posX + ' +/- ' + coordinateData.sigmaPosX,
				});
			} else {
				contentList.push({
					title: 'X:',
					body: coordinateData.posX,
				});
			}
		} else {
			contentList.push({
				title: 'X:',
				body: undefined,
			});
		}

		if (coordinateData.posY !== null) {
			if (coordinateData.sigmaPosY !== null) {
				contentList.push({
					title: 'Y:',
					body: coordinateData.posY + ' +/- ' + coordinateData.sigmaPosY,
				});
			} else {
				contentList.push({
					title: 'Y:',
					body: coordinateData.posY,
				});
			}
		} else {
			contentList.push({
				title: 'Y:',
				body: undefined,
			});
		}

		if (coordinateData.posZ !== null) {
			if (coordinateData.sigmaPosZ !== null) {
				contentList.push({
					title: 'Z:',
					body: coordinateData.posZ + ' +/- ' + coordinateData.sigmaPosZ,
				});
			} else {
				contentList.push({
					title: 'Z:',
					body: coordinateData.posZ,
				});
			}
		} else {
			contentList.push({
				title: 'Z:',
				body: undefined,
			});
		}

		contentList.push({
			title: '',
			body: undefined,
		});

		if (coordinateData.velocityModel !== null) {
			contentList.push({
				title: 'Velocity model:',
				body: coordinateData.velocityModel.velocityModelName,
				subheader: true,
			});
		} else {
			contentList.push({
				title: 'Velocity model:',
				body: undefined,
				subheader: true,
			});
		}

		if (coordinateData.velX !== null) {
			if (coordinateData.sigmaVelX !== null) {
				contentList.push({
					title: 'Vx:',
					body: coordinateData.velX + ' +/- ' + coordinateData.sigmaVelX,
				});
			} else {
				contentList.push({
					title: 'Vx:',
					body: coordinateData.velX,
				});
			}
		} else {
			contentList.push({
				title: 'Vx:',
				body: undefined,
			});
		}

		if (coordinateData.velY !== null) {
			if (coordinateData.sigmaVelY !== null) {
				contentList.push({
					title: 'Vy:',
					body: coordinateData.velY + ' +/- ' + coordinateData.sigmaVelY,
				});
			} else {
				contentList.push({
					title: 'Vy:',
					body: coordinateData.velY,
				});
			}
		} else {
			contentList.push({
				title: 'Vy:',
				body: undefined,
			});
		}

		if (coordinateData.velZ !== null) {
			if (coordinateData.sigmaVelZ !== null) {
				contentList.push({
					title: 'Vz:',
					body: coordinateData.velZ + ' +/- ' + coordinateData.sigmaVelZ,
				});
			} else {
				contentList.push({
					title: 'Vz:',
					body: coordinateData.velZ,
				});
			}
		} else {
			contentList.push({
				title: 'Vz:',
				body: undefined,
			});
		}

		contentList.push({
			title: '',
			body: undefined,
		});

		if (coordinateData.localTies.length < 1) {
			contentList.push({
				title: 'Local tie',
				body: 'No records found',
			});
		} else {
			coordinateData.localTies.forEach((localTieElement: LocalTie) => {
				contentList.push({
					title: 'Local tie type',
					body: '',
				});

				contentList.push({
					title: 'Tied marker name:',
					body: localTieElement.tiedMarkerName,
				});

				if (localTieElement.timestampRegistered !== null) {
					contentList.push({
						title: 'Timestamp registered:',
						body: momentPrettyFormat(localTieElement.timestampRegistered),
					});
				} else {
					contentList.push({
						title: 'Timestamp registered:',
						body: undefined,
					});
				}

				contentList.push({
					title: 'Tied marker usage:',
					body: localTieElement.tiedMarkerUsage,
				});

				contentList.push({
					title: 'Survey method:',
					body: localTieElement.surveyMethod,
				});

				contentList.push({
					title: 'Accuracy mm:',
					body: localTieElement.accuracy,
				});

				contentList.push({
					title: 'dN:',
					body: localTieElement.dN,
				});

				contentList.push({
					title: 'dE:',
					body: localTieElement.dE,
				});

				contentList.push({
					title: 'dH:',
					body: localTieElement.dH,
				});

				contentList.push({
					title: 'Additional information:',
					body: localTieElement.additionalInformation,
				});
			});
		}

		return contentList;
	};

	return (
		<MuiPaper title={'Coordinate'}>
			<Grid container>
				<Grid item xs={12}>
					<HorizontalTable content={getContent(props)}/>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default CoordinateInfoCard;
