import * as yup from 'yup';

import { FormConfiguration, TestComponents } from '../../../components/Form/Form.types';
import React, { useEffect } from 'react';
import { formatFakeLocalTime, formatFakeUtcTime, momentPrettyFormat } from '../../../service/moment';
import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import { Form } from '../../../components/Form/Form';
import Loader from '../../../components/Loader/Loader';
import { ReceiverType } from '../../../redux/ReceiverType/receiverType.types';
import { ReceiverUpdateType } from '../../../redux/Receiver/receiver.types';
import moment from 'moment';
import { selectReceiverState } from '../../../redux/Receiver/receiver.slice';
import { store } from '../../../redux/store';
import { updateReceiver } from '../../../redux/Receiver/receiver.actions';
import { useAppSelector } from '../../../redux/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Declare tests for validating timestamps
 */
const testTimestampIsValid: TestComponents = [
	'isDate',
	'${path} is not a valid datetime',
	(val: any) => {
		if (val === '') {
			return true;
		}

		return moment.utc(val, moment.ISO_8601).isValid();
	}
];

/**
 * Testing that a not required number is positive
 */
const testNotRequiredNumberIsPositive: TestComponents = [
	'isPositive',
	'${path} is not a positive number',
	(val: any) => {
		if (isNaN(val)) {
			return true;
		}

		return val >= 0;
	}
];

/**
 * Declare the form schema for validation purposes.
 */
const schema = yup.object({
	serialNumber: yup.string()
		.required(),
	elevationCutoffAngle: yup.number()
		.defined()
		.nullable()
		.test(...testNotRequiredNumberIsPositive),
	equipmentLabel: yup.string()
		.defined()
		.nullable(),
	ipAddress: yup.string()
		.defined()
		.nullable(),
	receiverType: yup.object({
		id: yup.number()
			.defined(),
		receiverTypeName: yup.string()
			.defined(),
	})
		.defined()
		.nullable(),
	timestampGuarantee: yup.string()
		.defined()
		.nullable()
		.transform(formatFakeUtcTime)
		.test(...testTimestampIsValid),
	firmwareVersion: yup.string()
		.required(),
	timestampSet: yup.string()
		.required()
		.transform(formatFakeUtcTime)
		.test(...testTimestampIsValid),
	satSystems: yup.array().of(
		yup.object({
			id: yup.number()
				.defined(),
			satSystemName: yup.string()
				.defined()
		})
	),
	discarded: yup.number()
		.transform((val, originalVal) => {
			// The form control is a CheckboxInput, which works with boolean values.
			// Because exist is a number, we must access the original value because
			// Yup will have failed to parse it as a boolean.
			return originalVal ? 1 : 0;
		})
		.defined(),
});

// Declare the default values
const defaultValues: ReceiverUpdateType & { discarded: number } = {
	id: 0,
	serialNumber: '',
	elevationCutoffAngle: 0,
	equipmentLabel: '',
	ipAddress: '',
	receiverType: { id: 0, receiverTypeName: '' },
	timestampGuarantee: '',
	timestampSet: '',
	firmwareVersion: '',
	satSystems: [],
	discarded: 0,
	exist: 1,
	additionalInformation: '',
};

/**
 * Declare the form configuration used for constructing the form components.
 */
const config: Omit<FormConfiguration, 'control' | 'onSubmit'> = {
	fields: [
		{
			type: 'text',
			name: 'serialNumber',
			label: 'Serial number',
			id: 'receiver-serial-number',
			helperText: 'Remember to update headers in old rinex files!',
			required: true,
		},
		{
			type: 'number',
			name: 'elevationCutoffAngle',
			label: 'Elevation cutoff angle',
			id: 'receiver-elevation-cutoff-angle',
		},
		{
			type: 'text',
			name: 'equipmentLabel',
			label: 'Equipment label',
			id: 'receiver-equipment-label',
		},
		{
			type: 'text',
			name: 'ipAddress',
			label: 'IP address',
			id: 'receiver-ip-address',
		},
		{
			type: 'autocomplete',
			name: 'receiverType',
			label: 'Receiver type',
			id: 'receiver-receiver-type',
			required: true,
			helperText: 'Remember to update headers in old rinex files!',
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option?.receiverTypeName || String(option?.id || ''),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_RECEIVERTYPE);
				const receiverTypes: ReceiverType[] = await res.json();

				return receiverTypes;
			},
		},
		{
			type: 'datetime',
			name: 'timestampGuarantee',
			label: 'Guarantee',
			id: 'receiver-timestamp-guarantee',
		},
		{
			type: 'datetime',
			name: 'timestampSet',
			label: 'Valid from',
			id: 'receiver-timestamp-set',
			required: true,
		},
		{
			type: 'text',
			name: 'firmwareVersion',
			label: 'Firmware version',
			id: 'receiver-firmware-version',
			required: true,
		},
		{
			type: 'autocomplete',
			name: 'satSystems',
			label: 'Sat systems',
			id: 'receiver-sat-system',
			multiple: true,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option.satSystemName || String(option.id),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_SATSYSTEMS);
				const satSystems: any[] = await res.json();

				return satSystems;
			},
		},
		{
			type: 'checkbox',
			name: 'discarded',
			label: 'Discarded/defective?',
			id: 'receiver-discarded-defective',
		},
		{
			type: 'multilineText',
			name: 'additionalInformation',
			label: 'Additional information',
			id: 'receiver-additional-information',
		}
		// TO_DO: Implement located at site
	],
};

/**
 * Handler for submitting the receiver edit form.
 */
const submitReceiver = async (data: typeof defaultValues) => {
	await store.dispatch(updateReceiver({ receiver: {
		id: data.id,
		serialNumber: data.serialNumber,
		elevationCutoffAngle: data.elevationCutoffAngle,
		equipmentLabel: data.equipmentLabel,
		ipAddress: data.ipAddress,
		receiverType: data.receiverType,
		timestampGuarantee: data.timestampGuarantee,
		timestampSet: data.timestampSet,
		firmwareVersion: data.firmwareVersion,
		satSystems: data.satSystems,
		exist: data.discarded === 1 ? 0 : 1,
		additionalInformation: data.additionalInformation,
	} }));
};

/**
 * A receiver edit form component.
 *
 * Use this component as an example of how to wire up a form using Yup validation and react hook forms.
 */
export const ReceiverUpdate: React.FC<{}> = () => {
	const receiver = useAppSelector(selectReceiverState);

	const { control, formState, handleSubmit, reset, trigger } = useForm<typeof defaultValues>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		values: {
			...defaultValues,
			...receiver,
			// We need to fake timezones in order to force showing UTC times.
			timestampGuarantee: formatFakeLocalTime(receiver?.timestampGuarantee),
			// We'll use a temporary discarded value which is the opposite of exist
			// this will then be reversed in the submitReceiver handler above
			discarded: receiver?.exist === 1 ? 0 : 1,
		},
		defaultValues: {
			...defaultValues,
		},
	});

	const {
		isDirty,
		isSubmitting,
		isSubmitted,
		isSubmitSuccessful,
		isValidating,
		isValid,
	} = formState;

	const shouldValidateOnce = receiver !== null;

	useEffect(() => {
		if (shouldValidateOnce) {
			trigger();
		}
	}, [shouldValidateOnce, trigger]);

	useEffect(() => {
		reset();
	}, [isSubmitSuccessful, reset]);

	if (receiver === null) {
		return <Loader />;
	}

	let timestampLastHistoryElement = '';

	if (receiver.receiverHists !== undefined) {
		const [{ timestampValidFrom }] = receiver.receiverHists;

		timestampLastHistoryElement = momentPrettyFormat(timestampValidFrom);
	}

	return (
		<Form
			control={control}
			onSubmit={handleSubmit(submitReceiver)}
			isDirty={isDirty}
			isSubmitting={isSubmitting}
			isSubmitted={isSubmitted}
			isValidating={isValidating}
			isValid={isValid}
			readOnlyValues={[
				{ label: 'Timestamp registered (UTC Time)', value: momentPrettyFormat(receiver?.timestampRegistered) },
				{ label: 'Timestamp of last history element (UTC Time)', value: timestampLastHistoryElement },
				{ label: 'Siteconfig(s)', value: (receiver?.fourCharIds || []).join(', ') },
			]}
			{...config}
		/>
	);
};
