import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { JsonFileInputProps } from './Form.types';
import OutlinedInput from '@mui/material/OutlinedInput';

/**
 * File input form field.
 */
const JsonFileInput = forwardRef<HTMLInputElement, JsonFileInputProps>((props, ref) => {
	const {
		control,
		name,
		label,
		id,
		helperText,
		disabled = false,
		required = false,
	} = props;

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, onChange }, fieldState: { error } }) => {
				const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
					// We extract the File objects from the change event
					const { files } = event.target;

					onChange([...files || []]);
				};

				return (
					<FormControl error={Boolean(error)} variant='outlined' hiddenLabel={false} fullWidth={true}>
						<OutlinedInput
							type='file'
							id={id + '-input'}
							aria-label={label}
							required={required}
							disabled={disabled}
							inputProps={{ onBlur, accept: '.json', multiple: false }}
							onChange={changeHandler}
							name={'JsonFileInput'}
							inputRef={ref}
						/>
						<FormHelperText id={id + '-helpertext'}>{error?.message ? error.message : helperText}</FormHelperText>
					</FormControl>
				);
			}}
		/>
	);
});

JsonFileInput.displayName = 'test';

export default JsonFileInput;
