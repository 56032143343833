import {
	AppBar,
	Tab,
	Tabs,
} from '@mui/material';
import React, { useState } from 'react';
import { TabsListType, TabsPropType } from './Tabs.type';

import { tabStyles } from './Tabs.styles';

const MuiTabs = (props: TabsPropType): JSX.Element => {
	const classes = tabStyles;
	const [tabValue, setTabValue] = useState<number>(0);
	const TabComponent = props.tabsList[tabValue].component;

	return (
		<React.Fragment>
			<AppBar position={'static'} sx={classes.root}>
				<Tabs
					id={props.name}
					value={tabValue}
					scrollButtons={'auto'}
					variant={'scrollable'}
					TabIndicatorProps={{ sx: classes.tabIndicator }}
					textColor={'inherit'}
					onChange={(event: React.ChangeEvent<{}>, newValue: number) => setTabValue(newValue)}
					aria-label={props.name}
				>
					{
						props.tabsList.map((component: TabsListType, index: number) =>
							<Tab
								key={index}
								label={component.label}
								sx={
									component.disabled === true
										? { ...classes.root, ...classes.disabled }
										: classes.root
								}
							/>)
					}
				</Tabs>
			</AppBar>
			{TabComponent}
		</React.Fragment>
	);
};

export default MuiTabs;
