import { Variant } from '@mui/material/styles/createTypography';
import { createTheme } from '@mui/material';

const defaultTheme = createTheme();

export const globalTitle: Variant = 'h4';
export const globalSubTitle: Variant = 'h6';
export const globalSmallTitle: Variant = 'subtitle2';

export const globalStyles = {
	borderRadius: '8px',
	margin: {
		root: '0px 15px'
	},
	colors: {
		primary: {
			light: '#4fa96b',
			main: '#249446',
			dark: '#196731',
		},
		secondary: {
			light: '#E4F1F8',
			main: '#1A589F',
			dark: '#175092',
		},
		error: {
			light: '#bf3c62',
			main: '#B00C3B',
			dark: '#7b0829',
		},
		grey: {
			light: defaultTheme.palette.grey[100],
			main: defaultTheme.palette.grey[200],
			dark: defaultTheme.palette.grey[400],
			contrastText: defaultTheme.palette.common.white,
		},
	},
	typography: {
		title: globalTitle,
		subTitle: globalSubTitle,
		smallTitle: globalSmallTitle,
	},
};

export const theme = createTheme({
	palette: {
		primary: {
			light: globalStyles.colors.primary.light,
			main: globalStyles.colors.primary.main,
			dark: globalStyles.colors.primary.dark,
			contrastText: defaultTheme.palette.common.white,
		},
		secondary: {
			light: globalStyles.colors.secondary.light,
			main: globalStyles.colors.secondary.main,
			dark: globalStyles.colors.secondary.dark,
			contrastText: defaultTheme.palette.common.white,
		},
		error: {
			light: globalStyles.colors.error.light,
			main: globalStyles.colors.error.main,
			dark: globalStyles.colors.error.dark,
			contrastText: defaultTheme.palette.common.white,
		},
	},
});
