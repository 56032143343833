import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ApplicationStateType } from '../store';
import type { AuthType } from './auth.types';

const initialState = {
	authenticated: true,
	role: null,
	username: '',
} as AuthType;

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthState: (state, action: PayloadAction<typeof initialState>) => ({
			...state,
			...action.payload,
		}),
	},
});

export const selectAuthState = (state: ApplicationStateType) => state.authState;

export default authSlice.reducer;
