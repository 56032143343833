import React from 'react';

const Sinex_Sitelog: React.FC = () => {
	return (
		<>
			<h1>Sinex_Sitelog</h1>
		</>
	);
};

export default Sinex_Sitelog;

