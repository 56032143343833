import { globalStyles, theme } from '../../service/styles/global.styles';

export const accordionStyles = {
	rootClosed: {
		borderRadius: globalStyles.borderRadius,
		'&:hover': {
			backgroundColor: globalStyles.colors.grey.light,
		},
	},
	rootOpened: {
		borderRadius: globalStyles.borderRadius,
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.common.white,
	},
	accordionTitle: {
		flexBasis: '30%',
		display: 'grid',
		textAlign: 'center',
		alignItems: 'center',
		justifyItems: 'center',
	},
	accordionBadge: {
		flexBasis: '10%',
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.common.white,
		fontWeight: 'bold',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	accordionLabel: {
		fontWeight: 'bold',
	},
	editableTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	editModeTextField: {
		textAlign: 'center',
	},
};
