import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApplicationStateType } from '../store';
import { MarkRefPoint } from './markRefPoint.types';

const initialState = null as MarkRefPoint | null;

export const markRefPointSlice = createSlice({
	name: 'markRefPoint',
	initialState,
	reducers: {
		setMarkRefPointState: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return { ...state, ...action.payload };
		},
		resetMarkRefPointState: () => null,
	},
});

export const selectmarkRefPointState = (state: ApplicationStateType) => state.markRefPointState;

export default markRefPointSlice.reducer;
