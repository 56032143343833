import React from 'react';

const Collocated_Instruments: React.FC = () => {
	return (
		<>
			<h1>Collocated_Instruments</h1>
		</>
	);
};

export default Collocated_Instruments;

