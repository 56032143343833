import './App.css';

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks';

import { API_ENDPOINTS } from './service/routes/ApiEndpoints';
import { RouterProvider } from 'react-router-dom';
import { appRouter } from './service/routes/AppRouter';
import { fetchAuthState } from './redux/Auth/auth.actions';
import { selectAuthState } from './redux/Auth/auth.slice';

const App = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const auth = useAppSelector(selectAuthState);

	useEffect(() => {
		const fetchData = async () => {
			await dispatch(fetchAuthState());
		};

		fetchData();
	}, [dispatch]);

	useEffect(() => {
		if (auth.authenticated === false) {
			document.location.href = API_ENDPOINTS.LOGIN;
		}
	}, [auth]);

	return <RouterProvider router={appRouter} />;
};

export default App;
