import React from 'react';

const Interferences: React.FC = () => {
	return (
		<>
			<h1>Interferences</h1>
		</>
	);
};

export default Interferences;

