import { Button, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatetimeFormat, momentFormat, momentPrettyFormat } from '../../../../service/moment';
import React, { useEffect, useState } from 'react';

import { API_ENDPOINTS } from '../../../../service/routes/ApiEndpoints';
import Box from '@mui/material/Box';
import CoordinateInfoCard from './SiteConfigTab/CoordinateInfoCard';
import { MarkRefPointSiteConfigHist } from '../../../../redux/SiteConfigHist/siteConfigHist.types';
import MuiAccordion from '../../../../components/Accordion/Accordion';
import MuiPaper from '../../../../components/Paper/Paper';
import { getRequest } from '../../../../service/requests';
import { globalStyles } from '../../../../service/styles/global.styles';
import { selectSiteData } from '../../../../redux/Site/siteData.slice';
import { useAppSelector } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';

const columns: GridColDef<MarkRefPointSiteConfigHist>[] = [
	{
		field: 'timeFrom',
		headerName: 'Time from',
		minWidth: 135,
		renderCell: params =>
			params.value
				? momentFormat({
					date: params.value,
					format: DatetimeFormat.DATETIME
				})
				: params.value,
	},
	{
		field: 'timeTo',
		headerName: 'Time to',
		minWidth: 135,
		renderCell: params =>
			params.value
				? momentFormat({
					date: params.value,
					format: DatetimeFormat.DATETIME
				})
				: params.value,
	},
	{
		field: 'preparedBy',
		headerName: 'Established by',
		minWidth: 120,
	},
	{
		field: 'markRefPoint.id',
		headerName: 'Markrefpoint ID',
		minWidth: 125,
		renderCell: params =>
			params.row.markRefPoint.id
				? params.row.markRefPoint.id
				: '',
	},
	{
		field: 'markRefPoint.timestampRegistered',
		headerName: 'Marker registered',
		minWidth: 135,
		renderCell: params =>
			params.row.markRefPoint.timestampRegistered
				? momentFormat({
					date: params.row.markRefPoint.timestampRegistered,
					format: DatetimeFormat.DATETIME
				})
				: '',
	},
	{
		field: 'markRefPoint.markerDescription',
		headerName: 'Marker description',
		minWidth: 150,
		renderCell: params =>
			params.row.markRefPoint.markerDescription
				? params.row.markRefPoint.markerDescription
				: '',
	},
	{
		field: 'markRefPoint.solutionFlag.solutionFlagName',
		headerName: 'Solution flag',
		minWidth: 110,
		renderCell: params =>
			params.row.markRefPoint.solutionFlag
				? params.row.markRefPoint.solutionFlag.solutionFlagName
				: '',
	},
	{
		field: 'markRefPoint.solutionCounter',
		headerName: 'Solution counter',
		minWidth: 135,
		renderCell: params =>
			params.row.markRefPoint
				? params.row.markRefPoint.solutionCounter
				: '',
	},
	{
		field: 'markRefPoint.solutionDescription',
		headerName: 'Solution description',
		minWidth: 160,
		renderCell: params =>
			params.row.markRefPoint
				? params.row.markRefPoint.solutionDescription
				: '',
	},
	// PosRef
	{
		field: 'markRefPoint.referenceSystemPosRef.referenceSystemName',
		headerName: 'Coordinate reference system',
		cellClassName: 'highlight-cell',
		minWidth: 220,
		renderCell: params =>
			params.row.markRefPoint.referenceSystemPosRef
				? params.row.markRefPoint.referenceSystemPosRef.referenceSystemName
				: '',
	},
	{
		field: 'markRefPoint.epochPosRef',
		headerName: 'Coordinate epoch',
		cellClassName: 'highlight-cell',
		minWidth: 140,
		renderCell: params =>
			params.row.markRefPoint.epochPosRef
				? momentFormat({
					date: params.row.markRefPoint.epochPosRef,
					format: DatetimeFormat.DATETIME
				})
				: '',
	},
	{
		field: 'markRefPoint.posXRef',
		headerName: 'X',
		cellClassName: 'highlight-cell',
		minWidth: 200,
		renderCell: params =>
			params.row.markRefPoint.posXRef
				? params.row.markRefPoint.sigmaPosXRef
					? params.row.markRefPoint.posXRef + ' +/- ' + params.row.markRefPoint.sigmaPosXRef
					: params.row.markRefPoint.posXRef
				: '',
	},
	{
		field: 'markRefPoint.posYRef',
		headerName: 'Y',
		cellClassName: 'highlight-cell',
		minWidth: 200,
		renderCell: params =>
			params.row.markRefPoint.posYRef
				? params.row.markRefPoint.sigmaPosYRef
					? params.row.markRefPoint.posYRef + ' +/- ' + params.row.markRefPoint.sigmaPosYRef
					: params.row.markRefPoint.posYRef
				: '',
	},
	{
		field: 'markRefPoint.posZRef',
		headerName: 'Z',
		cellClassName: 'highlight-cell',
		minWidth: 200,
		renderCell: params =>
			params.row.markRefPoint.posZRef
				? params.row.markRefPoint.sigmaPosZRef
					? params.row.markRefPoint.posZRef + ' +/- ' + params.row.markRefPoint.sigmaPosZRef
					: params.row.markRefPoint.posZRef
				: '',
	},
	// Pos
	{
		field: 'markRefPoint.referenceSystemPos.referenceSystemName',
		headerName: 'Coordinate reference system',
		minWidth: 220,
		renderCell: params =>
			params.row.markRefPoint.referenceSystemPos
				? params.row.markRefPoint.referenceSystemPos.referenceSystemName
				: '',
	},
	{
		field: 'markRefPoint.epochPos',
		headerName: 'Coordinate epoch',
		minWidth: 140,
		renderCell: params =>
			params.row.markRefPoint.epochPos
				? momentFormat({
					date: params.row.markRefPoint.epochPos,
					format: DatetimeFormat.DATETIME
				})
				: '',
	},
	{
		field: 'markRefPoint.posX',
		headerName: 'X',
		minWidth: 200,
		renderCell: params =>
			params.row.markRefPoint.posX
				? params.row.markRefPoint.sigmaPosX
					? params.row.markRefPoint.posX + ' +/- ' + params.row.markRefPoint.sigmaPosX
					: params.row.markRefPoint.posX
				: '',
	},
	{
		field: 'markRefPoint.posY',
		headerName: 'Y',
		minWidth: 200,
		renderCell: params =>
			params.row.markRefPoint.posY
				? params.row.markRefPoint.sigmaPosY
					? params.row.markRefPoint.posY + ' +/- ' + params.row.markRefPoint.sigmaPosY
					: params.row.markRefPoint.posY
				: '',
	},
	{
		field: 'markRefPoint.posZ',
		headerName: 'Z',
		minWidth: 200,
		renderCell: params =>
			params.row.markRefPoint.posZ
				? params.row.markRefPoint.sigmaPosZ
					? params.row.markRefPoint.posZ + ' +/- ' + params.row.markRefPoint.sigmaPosZ
					: params.row.markRefPoint.posZ
				: '',
	},
	// Velocitymodel
	{
		field: 'markRefPoint.velocityModel.velocityModelName',
		headerName: 'Velocity model',
		cellClassName: 'highlight-cell',
		minWidth: 125,
		renderCell: params =>
			params.row.markRefPoint.velocityModel
				? params.row.markRefPoint.velocityModel.velocityModelName
				: '',
	},
	{
		field: 'markRefPoint.velX',
		headerName: 'Vx',
		cellClassName: 'highlight-cell',
		minWidth: 200,
		renderCell: params =>
			params.row.markRefPoint.velX
				? params.row.markRefPoint.sigmaVelX
					? params.row.markRefPoint.velX + ' +/- ' + params.row.markRefPoint.sigmaVelX
					: params.row.markRefPoint.velX
				: '',
	},
	{
		field: 'markRefPoint.velY',
		headerName: 'Vy',
		cellClassName: 'highlight-cell',
		minWidth: 200,
		renderCell: params =>
			params.row.markRefPoint.velY
				? params.row.markRefPoint.sigmaVelY
					? params.row.markRefPoint.velY + ' +/- ' + params.row.markRefPoint.sigmaVelY
					: params.row.markRefPoint.velY
				: '',
	},
	{
		field: 'markRefPoint.velZ',
		headerName: 'Vz',
		cellClassName: 'highlight-cell',
		minWidth: 200,
		renderCell: params =>
			params.row.markRefPoint.velZ
				? params.row.markRefPoint.sigmaVelZ
					? params.row.markRefPoint.velZ + ' +/- ' + params.row.markRefPoint.sigmaVelZ
					: params.row.markRefPoint.velZ
				: '',
	},];

const MarkRefPointHist: React.FC = () => {
	const [showAccordion, toggleShowAccordion] = useState(false);
	const { siteConfigId } = useParams<{ siteConfigId: string }>();
	// Get fourCharId from siteconfigId
	const siteData = useAppSelector(selectSiteData);

	let fourCharId = '';

	if (siteData !== null && siteConfigId !== undefined) {
		const siteConfigs = siteData.siteConfigs;

		for (const siteConfig of siteConfigs) {
			if (siteConfig.id.toString() === siteConfigId) {
				fourCharId = siteConfig.fourCharId;
			}
		}
	}
	const [markRefPointHists, setMarkRefPointHists] = useState<MarkRefPointSiteConfigHist[] | null>(null);

	useEffect(() => {
		const effect = async () => {
			const response = await getRequest<MarkRefPointSiteConfigHist[]>(
				API_ENDPOINTS.GET_SITECONFIGHIST_MARKREFPOINT
					.replace('{fourCharId}', encodeURIComponent(fourCharId))
			);

			setMarkRefPointHists(response);
		};

		effect();
	}, [fourCharId]);
	if (markRefPointHists === null || markRefPointHists.length === 0) {
		return <></>;
	}
	// Put the latest records first
	const reversedMarkRefPointHists = [...markRefPointHists];

	reversedMarkRefPointHists.sort((a, b) => b.timeFrom.localeCompare(a.timeFrom));

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid item xs={12}>
						<Box height={750}>
							<DataGrid
								rows={reversedMarkRefPointHists}
								columns={columns}
								getRowId={(row: any) => row.markRefPoint.id}
								scrollbarSize={12}
								sx={{
									'& .MuiDataGrid-columnHeader': {
										color: 'white',
										backgroundColor: 'primary.dark',
										'& .MuiDataGrid-columnHeaderTitle': {
											fontWeight: 'bold',
										}
									},
									'& .MuiIconButton-root': {
										color: 'white',
									},
									'& .MuiDataGrid-cell': {
										padding: '10px',
									},
									'& .MuiDataGrid-cell:focus': {
										outline: 'initial',
									},
									'& .highlight-cell': {
										backgroundColor: globalStyles.colors.grey.main,
									},
								}}
								getRowHeight={() => 'auto'}
								disableSelectionOnClick={true}/>
						</Box>
						<br/>
						<Button variant={'outlined'} onClick={() =>
							toggleShowAccordion(!showAccordion)}>
							{showAccordion ? 'Hide Coordinate Infocard' : 'Show Coordinate Infocard'}
						</Button>
						{showAccordion &&
							reversedMarkRefPointHists.map((markRefPointHist: MarkRefPointSiteConfigHist, index: number) =>
								<MuiAccordion
									key={markRefPointHist.timeFrom + markRefPointHist.timeTo}
									id={index}
									name={markRefPointHist.timeFrom + markRefPointHist.timeTo}
									title={[
										{
											label: 'Time from:',
											value: markRefPointHist.timeFrom ? momentPrettyFormat(markRefPointHist.timeFrom) : '',
										},
										{
											label: 'Time to:',
											value: markRefPointHist.timeTo ? momentPrettyFormat(markRefPointHist.timeTo) : '',
										},
										{
											label: 'Established by:',
											value: markRefPointHist.preparedBy ? markRefPointHist.preparedBy : '',
										}
									]}
									body={
										<CoordinateInfoCard {...markRefPointHist.markRefPoint} />
									}
								/>
							)}
					</Grid>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default MarkRefPointHist;
