import { AddSiteConfigParamType, AddSiteConfigType, SiteConfig } from '../SiteConfig/siteConfig.types';
import { AntennaHistCreateParamType, AntennaHistCreateType, AntennaHistUpdateType } from '../AntennaHist/antennaHist.types';
import { ApplicationDispatch, ApplicationGetState } from '../store';
import { ReceiverHistCreateParamType, ReceiverHistCreateType, ReceiverHistUpdateType } from '../ReceiverHist/receiverHist.types';
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from '../../service/requests';
import { selectSiteData, selectSiteDataId, siteDataSlice } from './siteData.slice';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { Site } from './site.types';
import { SiteConfigHist } from '../SiteConfigHist/siteConfigHist.types';
import { getEnvStateData } from '../../service/envStateData';
import { momentApiFormat } from '../../service/moment';
import { siteDataMock } from './site.mock';

export const { setSiteData, resetSiteData } = siteDataSlice.actions;

export const fetchSiteDataById = (id: number) => {
	return async (dispatch: ApplicationDispatch) => {
		const siteData = await getEnvStateData(
			getRequest<Site>(API_ENDPOINTS.GET_SITE_SITECONFIGS_RECEIVERS_ANTENNAS_HISTS + '/' + id),
			siteDataMock,
		);

		return dispatch(setSiteData(siteData));
	};
};

export const putSiteConfigHistTimestampUpdated = (newDate: string, param: SiteConfigHist) => {
	return async (dispatch: ApplicationDispatch, getState: ApplicationGetState) => {
		const currentSiteData = selectSiteData(getState());

		if (currentSiteData !== null) {
			await patchRequest<SiteConfigHist, SiteConfigHist>(API_ENDPOINTS.PATCH_SITECONFIGHIST, {
				...param,
				timestampValidFrom: momentApiFormat(newDate),
			});

			await dispatch(fetchSiteDataById(currentSiteData.id));
		}
	};
};

export const addSiteConfigElement = (siteConfigParam: AddSiteConfigParamType, siteIdParam: number) => {
	return async (dispatch: ApplicationDispatch, getState: ApplicationGetState) => {
		const currentSiteData = selectSiteData(getState());

		const newSiteConfig: AddSiteConfigType = {
			...siteConfigParam,
			siteId: siteIdParam,
		};

		let siteId: number | null = null;

		if (currentSiteData !== null) {
			if (currentSiteData.id === siteIdParam) {
				siteId = currentSiteData.id;
			}

			if (siteId !== null) {
				await postRequest(API_ENDPOINTS.POST_TEMP_SITECONFIG.replace('{id}', String(siteId)), newSiteConfig);
				await dispatch(fetchSiteDataById(currentSiteData.id));
			}
		}
	};
};

export const putAntennaHistElement = (antennaHistParam: any) => {
	return async (dispatch: ApplicationDispatch, getState: ApplicationGetState) => {
		const timestamp = momentApiFormat(antennaHistParam['Valid from']);

		const antennaHist: AntennaHistUpdateType = {
			id: antennaHistParam.id,
			timestampValidFrom: timestamp,
			preparedBy: antennaHistParam['Established by'],
			fromRadomeId: antennaHistParam['Fra Radome Id'],
			toRadomeId: antennaHistParam['To radome id'] === '' ? 0 : antennaHistParam['To radome id'],
		};

		await putRequest(API_ENDPOINTS.PUT_ANTENNAHIST, antennaHist);

		const currentSiteDataId = selectSiteDataId(getState());

		if (currentSiteDataId !== null) {
			await dispatch(fetchSiteDataById(currentSiteDataId));
		}
	};
};

export const deleteAntennaHistElement = (antennaHistParam: any) => {
	return async (dispatch: ApplicationDispatch, getState: ApplicationGetState) => {
		await deleteRequest(API_ENDPOINTS.DELETE_ANTENNAHIST + '/' + antennaHistParam.id);

		const currentSiteDataId = selectSiteDataId(getState());

		if (currentSiteDataId !== null) {
			await dispatch(fetchSiteDataById(currentSiteDataId));
		}
	};
};

export const addAntennaHistElement = (antennaHistParam: AntennaHistCreateParamType, serialNumberParam: string) => {
	return async (dispatch: ApplicationDispatch, getState: ApplicationGetState) => {
		const currentSiteData = selectSiteData(getState());

		const newAntennaHist: AntennaHistCreateType = {
			...antennaHistParam,
			antennaId: antennaHistParam.antennaId,
			timestampValidFrom: momentApiFormat(antennaHistParam.timestampValidFrom),
			fromRadomeId: 0,
			toRadomeId: antennaHistParam.toRadomeId,
			preparedBy: antennaHistParam.preparedBy
		};

		let antennaId: number | null = null;

		if (currentSiteData !== null) {
			currentSiteData.siteConfigs.forEach((siteConfig: SiteConfig) => {
				if (siteConfig.siteConfigHists !== null) {
					siteConfig.siteConfigHists.forEach((siteConfigHistsElement: SiteConfigHist) => {
						if (siteConfigHistsElement.toAntenna !== null) {
							if (siteConfigHistsElement.toAntenna.serialNumber === serialNumberParam) {
								antennaId = siteConfigHistsElement.toAntenna.id;
							}
						}
					});
				}
			});

			if (antennaId !== null) {
				newAntennaHist.antennaId = antennaId;
				await postRequest(API_ENDPOINTS.POST_ANTENNAHIST, newAntennaHist);
				await dispatch(fetchSiteDataById(currentSiteData.id));
			}
		}
	};
};

export const putReceiverHistElement = (receiverHistParam: any) => {
	return async (dispatch: ApplicationDispatch, getState: ApplicationGetState) => {
		const timestamp = momentApiFormat(receiverHistParam['Valid from']);

		const receiverHist: ReceiverHistUpdateType = {
			id: receiverHistParam.id,
			timestampValidFrom: timestamp,
			preparedBy: receiverHistParam['Established by'],
			toFirmwareVersion: receiverHistParam['To firmware version'] === '' ? 0 : receiverHistParam['To firmware version'],
			fromFirmwareVersion: receiverHistParam['From firmware version'],
			toElevationCutoffAngle: receiverHistParam['To elevation cutoff angle'] === '' ? 0 : receiverHistParam['To elevation cutoff angle'],
			fromElevationCutoffAngle: receiverHistParam['From elevation cutoff angle'],
		};

		await putRequest(API_ENDPOINTS.PUT_RECEIVERHIST, receiverHist);

		const currentSiteDataId = selectSiteDataId(getState());

		if (currentSiteDataId !== null) {
			await dispatch(fetchSiteDataById(currentSiteDataId));
		}
	};
};

export const deleteReceiverHistElement = (receiverHistParam: any) => {
	return async (dispatch: ApplicationDispatch, getState: ApplicationGetState) => {
		await deleteRequest(API_ENDPOINTS.DELETE_RECEIVERHIST + '/' + receiverHistParam.id);

		const currentSiteDataId = selectSiteDataId(getState());

		if (currentSiteDataId !== null) {
			await dispatch(fetchSiteDataById(currentSiteDataId));
		}
	};
};

export const addReceiverHistElement = (receiverHistParam: ReceiverHistCreateParamType, serialNumberParam?: string) => {
	return async (dispatch: ApplicationDispatch, getState: ApplicationGetState) => {
		const currentSiteData = selectSiteData(getState());

		const newReceiverHist: ReceiverHistCreateType = {
			...receiverHistParam,
			timestampValidFrom: momentApiFormat(receiverHistParam.timestampValidFrom),
			fromFirmwareVersion: '0',
			toFirmwareVersion: receiverHistParam.toFirmwareVersion === '' ? '0' : receiverHistParam.toFirmwareVersion,
			fromElevationCutoffAngle: 0,
			toElevationCutoffAngle: receiverHistParam.toElevationCutoffAngle === null ? 0 : receiverHistParam.toElevationCutoffAngle
		};

		let receiverId: number | null = null;

		if (currentSiteData !== null) {
			currentSiteData.siteConfigs.forEach((siteConfig: SiteConfig) => {
				if (siteConfig.siteConfigHists !== null) {
					siteConfig.siteConfigHists.forEach((siteConfigHistsElement: SiteConfigHist) => {
						if (siteConfigHistsElement.toReceiver !== null) {
							if (siteConfigHistsElement.toReceiver.serialNumber === serialNumberParam) {
								receiverId = siteConfigHistsElement.toReceiver.id;
							}
						}
					});
				}
			});

			if (receiverId !== null) {
				newReceiverHist.receiverId = receiverId;
				await postRequest(API_ENDPOINTS.POST_RECEIVERHIST, newReceiverHist);
				await dispatch(fetchSiteDataById(currentSiteData.id));
			}
		}
	};
};
