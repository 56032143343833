export const navBarStyles = {
	root: {
		marginBottom: '20px',
		backgroundColor: 'primary.dark',
	},
	mainNavBtn: {
		color: 'common.white',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	mainNavBtnDisabled: {
		color: 'grey.500',
	},
	childNavBtn: {
		color: 'common.black',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	childNavBtnDisabled: {
		color: 'grey.500',
	},
	link: {
		textDecoration: 'none',
	},
};
