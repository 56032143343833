// Returns the absolute URL of a page
const currentURL = window.location.href;

// Setting correct backend URL from actual frontend URL
// PROD:
const SESTASJON_BACKEND_URL =
	currentURL.includes('atkv3-prod')
		? 'https://stasjonsdatabase-web.atkv3-prod.kartverket.cloud'
		: 'https://stasjonsdatabase-web.atkv3-dev.kartverket-intern.cloud';
const baseApi = SESTASJON_BACKEND_URL;
const base = SESTASJON_BACKEND_URL + '/api';
// Local development:
// const baseApi = 'http://localhost:3000';
// const base = 'http://localhost:3000/api';

export const API_ENDPOINTS = {
	LOGIN: `${baseApi}/login`,
	LOGOUT: `${baseApi}/logout`,
	GET_USERROLE: `${base}/userrole`,
	GET_SITE_AND_SITE_CONFIGS: `${base}/sites-and-siteconfigs`,
	GET_SITE_SUMMARIES: `${base}/sites/summary`,
	GET_SITES_MINIMAL: `${base}/sites/minimal`,
	GET_SITES_EXCEL: `${base}/sites/excel`,
	GET_SITES_CSV: `${base}/sites/csv`,
	GET_NEW_SITECONFIG: `${base}/new-siteconfig`,
	PATCH_SITECONFIGHIST: `${base}/siteconfighists`,
	GET_SITECONFIGHIST_RECEIVER: `${base}/siteconfighists/receivers/{id}`,
	GET_SITECONFIGHIST_MARKREFPOINT: `${base}/markrefpointhists-siteconfigs/fourcharid/{fourCharId}`,
	POST_MARKREFPOINT: `${base}/markrefpoints`,
	GET_SITE_SITECONFIGS_RECEIVERS_ANTENNAS_HISTS: `${base}/sites`,
	POST_RECEIVERHIST: `${base}/receiverhists`,
	PUT_RECEIVERHIST: `${base}/receiverhists`,
	DELETE_RECEIVERHIST: `${base}/receiverhists`,
	GET_RECEIVERS: `${base}/receivers`,
	GET_RECEIVERS_AVAILABLE: `${base}/available-receivers/siteid/{siteId}`,
	GET_RECEIVERS_CSV: `${base}/receivers/csv`,
	GET_RECEIVERS_EXCEL: `${base}/receivers/excel`,
	GET_RECEIVER: `${base}/receivers/{id}`,
	PUT_RECEIVER: `${base}/receivers`,
	POST_ANTENNAHIST: `${base}/antennahists`,
	PUT_ANTENNAHIST: `${base}/antennahists`,
	DELETE_ANTENNAHIST: `${base}/antennahists`,
	POST_TEMP_SITECONFIG: `${base}/sites/{id}/siteconfigs`,
	GET_TAGS: `${base}/tags`,
	POST_TAG: `${base}/tags`,
	PUT_TAG: `${base}/tags`,
	DELETE_TAG: `${base}/tags`,
	GET_RECEIVERTYPE: `${base}/receivertypes`,
	POST_RECEIVERTYPE: `${base}/receivertypes`,
	PUT_RECEIVERTYPE: `${base}/receivertypes`,
	GET_ANTENNATYPES: `${base}/antennatypes`,
	PUT_ANTENNATYPE: `${base}/antennatypes`,
	POST_ANTENNATYPE: `${base}/antennatypes`,
	GET_COUNTRY: `${base}/countries`,
	PUT_COUNTRY: `${base}/countries`,
	POST_COUNTRY: `${base}/countries`,
	PUT_SITECONFIG_ADD_TAG: `${base}/siteconfigs/add-tag`,
	PUT_SITECONFIG_REMOVE_TAG: `${base}/siteconfigs/remove-tag`,
	GET_RADOMETYPES: `${base}/radometypes`,
	PUT_RADOMETYPE: `${base}/radometypes`,
	POST_RADOMETYPE: `${base}/radometypes`,
	GET_RADOME_SINGLE: `${base}/radomes/{id}`,
	GET_RADOMES: `${base}/radomes`,
	GET_AVAIlABLE_RADOMES: `${base}/available-radomes`,
	PUT_RADOME: `${base}/radomes`,
	POST_RADOME: `${base}/radomes`,
	GET_EXTCLOCKTYPES: `${base}/extclocktypes`,
	PUT_EXTCLOCKTYPE: `${base}/extclocktypes`,
	POST_EXTCLOCKTYPE: `${base}/extclocktypes`,
	GET_EXTCLOCKS_AVAILABLE: `${base}/available-extclocks/siteid/{siteId}`,
	GET_ANTENNA_SUMMARIES: `${base}/antennas/summary`,
	GET_ANTENNAS_AVAILABLE: `${base}/available-antennas/siteid/{siteId}`,
	GET_ANTENNAS_EXCEL: `${base}/antennas/excel`,
	GET_ANTENNAS_CSV: `${base}/antennas/csv`,
	GET_ANTENNA: `${base}/antennas/{id}`,
	PUT_ANTENNA: `${base}/antennas`,
	POST_ANTENNA: `${base}/antennas`,
	GET_ANTREFPOINTTYPES: `${base}/antrefpointtypes`,
	PUT_ANTREFPOINTTYPE: `${base}/antrefpointtypes`,
	POST_ANTREFPOINTTYPE: `${base}/antrefpointtypes`,
	GET_ANTCALIBTYPES: `${base}/antcalibtypes`,
	PUT_ANTCALIBTYPE: `${base}/antcalibtypes`,
	POST_ANTCALIBTYPE: `${base}/antcalibtypes`,
	GET_SATSYSTEMS: `${base}/satsystems`,
	GET_INFDOC_FILE: `${base}/infdocs/{id}/file`,
	GET_INFDOC: `${base}/infdocs/{id}`,
	PUT_INFDOC: `${base}/infdocs`,
	POST_INFDOCS: `${base}/infdocs`,
	DELETE_INFDOC: `${base}/infdocs/{id}`,
	GET_INFDOCS: `${base}/infdocs`,
	GET_INFDOCTYPES: `${base}/infdoctypes`,
	GET_SITECONFIG: `${base}/siteconfigs/{siteConfigId}`,
	PUT_SITECONFIG: `${base}/siteconfigs`,
	POST_SITECONFIG: `${base}/siteconfigs`,
	GET_SITECONFIG_SUMMARIES: `${base}/siteconfigs/summary`,
	GET_SITECONFIGS_EXCEL: `${base}/siteconfigs/excel`,
	GET_SITECONFIGS_CSV: `${base}/siteconfigs/csv`,
	GET_MONUMENTS_AVAILABLE: `${base}/available-monuments/siteid/{siteId}`,
	GET_CABLES_AVAILABLE: `${base}/available-cables/siteid/{siteId}`,
	GET_SITECONFIGSTATTYPES: `${base}/siteconfigstattypes`,
	GET_STORAGELOCATIONS: `${base}/storagelocations`,
};
