import { Radome, RadomeCreateType, RadomeUpdateType } from './radome.types';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { getEnvStateData } from '../../service/envStateData';
import { radomeMock } from './radome.mock';
import { radomeSlice } from './radome.slice';

export const { setRadomeState, resetRadomeState } = radomeSlice.actions;

/**
 * Fetches a radome based on its ID.
 */
export const fetchRadome = ({ radomeId }: { radomeId: number }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.GET_RADOME_SINGLE.replace('{id}', encodeURIComponent(radomeId));
		const radome = await getEnvStateData(getRequest<Radome>(url), radomeMock);

		if (radome === null) {
			throw new Error(`Cannot find radome with id ${radomeId}`);
		}

		return dispatch(setRadomeState(radome));
	};
};

/**
 * Updates a radome.
 *
 * WARNING: This function assumes that front end validation has already been done.
 */
export const updateRadome = ({ radome }: { radome: RadomeUpdateType }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.PUT_RADOME.replace('{id}', encodeURIComponent(radome.id));
		const updatedRadome = await getEnvStateData(putRequest<Radome, typeof radome>(url, radome), radomeMock);

		if (updatedRadome === null) {
			throw new Error(`Cannot update radome with id ${radome.id}`);
		}

		return dispatch(setRadomeState(updatedRadome));
	};
};

/**
 * Uploads a radome.
 *
 * WARNING: This function assumes that front end validation has already been done.
 */
export const uploadRadome = ({ radome }: { radome: RadomeCreateType }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.POST_RADOME;
		const uploadedRadome = await getEnvStateData(postRequest<Radome, typeof radome>(url, radome), radomeMock);

		if (uploadedRadome === null) {
			throw new Error(`Could not create radome ${radome.serialNumber} ${radome.radomeTypeName}`);
		}

		return dispatch(setRadomeState(uploadedRadome));
	};
};
