export const PopperButtonStyles = {
	root: {
		paddingLeft: '50px',
		paddingRight: '50px',
		paddingBottom: '20px',
		backgroundColor: 'white',
		zIndex: 1,
		border: '1px solid black',
		borderRadius: '7px',
		margin: 'auto',
		width: '1000px',
	},
};
