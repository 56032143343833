import {
	AddAntennaTypeType,
	AntennaType,
} from '../../../redux/AntennaType/antennaType.types';
import {
	FormListType,
	addForm,
	getAttributeList,
	validateForm,
} from '../../Dashboard/DashboardTabs/Konfigurasjon/HistService';
import {
	addAntennaTypeElement,
	fetchAntennaTypeList,
	updateAntennaTypeElement,
} from '../../../redux/AntennaType/antennaType.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useEffect, useState } from 'react';

import MuiTable from '../../../components/Table/Table/Table';
import { regexList } from '../../../service/regex';
import { selectAntennaType } from '../../../redux/AntennaType/AntennaType.slice';

interface MuiAntennaTypeElement {
	'ID': number;
	'Antenna Type Name': string;
}

/**
 * Helper function to map antenna type to what's used by MUI table
 */
const antennaTypeToMuiElement = ({ id, antennaTypeName }: AntennaType) => ({
	'ID': id,
	'Antenna Type Name': antennaTypeName,
});

/**
 * Helper function to map MUI table element to antenna type
 */
const MuiElementToAntennaType = ({ 'ID': id, 'Antenna Type Name': antennaTypeName }: MuiAntennaTypeElement): AntennaType => ({
	id,
	antennaTypeName,
});

const Antenna_Types = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const antennaTypeData = useAppSelector(selectAntennaType);

	const [formValues, setFormValues] = useState<AddAntennaTypeType>({
		antennaTypeName: ''
	});
	const [error, setError] = useState<any>(undefined);

	useEffect(() => {
		dispatch(fetchAntennaTypeList());
	}, [dispatch]);

	// Fields displayed in the antenna type table head
	const formList: FormListType[] = [
		{
			label: 'ID',
			keyName: 'id',
			regex: regexList.freepass,
			number: true,
			readOnly: true,
		},
		{
			label: 'Antenna Type Name',
			keyName: 'antennaTypeName',
			regex: regexList.freepass,
			number: false,
			readOnly: false,
		},
	];

	// Field displayed in the add antenna type popup window
	const newTagList: FormListType[] = [
		{
			label: 'Antenna Type Name',
			keyName: 'antennaTypeName',
			regex: regexList.freepass,
			number: false,
		},
	];

	const handleFormChange = (keyProp: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues,	[keyProp]: event.target.value });
	};

	return (
		<>
			<h2>Antenna Types</h2>
			<MuiTable
				name={'antennaTypeList'}
				head={getAttributeList(formList, 'label')}
				body={(antennaTypeData?.antennaTypeList || []).map(antennaTypeToMuiElement)}
				addable={{
					title: 'Add antenna type',
					form: {
						modal: addForm(formValues, newTagList, error, handleFormChange),
						values: formValues,
						validate: () => {
							const validatedState = validateForm(formValues, formList);

							setError(validatedState.tmpErrObj);

							return validatedState.errorMsgStatus;
						},
					},
					buttonText: 'Add',
					addFuncCallback: (antennaType: AntennaType) => async () => {
						// We manually let the actions do their dispatching in this strange way because we need to be sure
						// the updates are fully completed before getting the newest antenna type list from the server.
						await addAntennaTypeElement(antennaType)(dispatch);
						await fetchAntennaTypeList()(dispatch);
					},
					param: 0
				}}
				editable={{
					editFuncCallback: (antennaType: MuiAntennaTypeElement) => async () => {
						await updateAntennaTypeElement(MuiElementToAntennaType(antennaType))(dispatch);
						await fetchAntennaTypeList()(dispatch);
					},
					validation: getAttributeList(formList, 'regex'),
					readOnly: getAttributeList(formList, 'readOnly')
				}}
			/>
		</>
	);
};

export default Antenna_Types;
