import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Antenna } from './antenna.types';
import { ApplicationStateType } from '../store';

const initialState = null as Antenna | null;

export const antennaSlice = createSlice({
	name: 'antenna',
	initialState,
	reducers: {
		setAntennaState: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return { ...state, ...action.payload };
		},
		resetAntennaState: () => null,
	},
});

export const selectAntennaState = (state: ApplicationStateType) => state.antennaState;

export default antennaSlice.reducer;
