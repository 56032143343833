import * as yup from 'yup';
import React, { useEffect } from 'react';
import { createAntenna, resetAntennaState } from '../../../redux/Antenna/antenna.actions';
import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import { AntRefPointType } from '../../../redux/AntRefPointType/antRefPointType.types';
import { Antenna } from '../../../redux/Antenna/antenna.types';
import { AntennaType } from '../../../redux/AntennaType/antennaType.types';
import Container from '@mui/material/Container';
import { Form } from '../../../components/Form/Form';
import { FormConfiguration } from '../../../components/Form/Form.types';
import { Radome } from '../../../redux/Radome/radome.types';
import { SiteMinimal } from '../../../redux/Site/site.types';
import { formatFakeUtcTime } from '../../../service/moment';
import { selectAntennaState } from '../../../redux/Antenna/antenna.slice';
import { setAlertState } from '../../../redux/Alert/alert.actions';
import { store } from '../../../redux/store';
import { useAppSelector } from '../../../redux/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object({
	antennaType: yup.object({
		id: yup.number()
			.defined(),
		antennaTypeName: yup.string()
			.defined()
			.required(),
	})
		.defined()
		.required()
		.nullable(),
	antRefPointType: yup.object({
		id: yup.number()
			.defined(),
		antennaRefPointTypeName: yup.string()
			.defined()
			.required(),
	})
		.defined()
		.required()
		.nullable(),
	radome: yup.object({
		id: yup.number()
			.defined(),
		serialNumber: yup.string()
			.defined(),
		radomeType: yup.object({
			id: yup.number()
				.defined(),
			radomeTypeName: yup.string()
				.defined(),
		})
			.defined()
	})
		.nullable(),
	timestampGuarantee: yup.string()
		.nullable()
		.transform(formatFakeUtcTime),
	serialNumber: yup.string()
		.required(),
	exist: yup.number()
		.transform((val, orginalVal) => {
			return orginalVal ? 1 : 0;
		}),
	additionalInformation: yup.string()
		.nullable(),
	tagNumber: yup.string()
		.nullable(),
	site: yup.object({
		id: yup.number()
			.defined(),
		siteName: yup.string()
			.defined(),
	})
		.nullable(),
});

const defaultValues: Omit<Antenna,
	'id' |
	'fourCharIds' |
	'antCalibrationIds' |
	'timestampRegistered' |
	'antennaHists'
	> & {} = {
		antennaType: { id: 0, antennaTypeName: '' },
		antRefPointType: { id: 0, antennaRefPointTypeName: '' },
		radome: {
			id: 0,
			radomeType: { id: 0, radomeTypeName: '' },
			serialNumber: '',
			exist: 0,
			additionalInformation: '',
			timestampRegistered: ''
		},
		timestampGuarantee: '',
		serialNumber: '',
		exist: 0,
		additionalInformation: '',
		equipmentLabel: '',
		site: {
			id: 0,
			siteName: '',
			agencyName: '',
			countryName: '',
		},
	};

const config: Omit<FormConfiguration, 'header' | 'control' | 'onSubmit'> = {
	fields: [
		{
			type: 'text',
			name: 'serialNumber',
			label: 'Serial Number',
			id: 'antennna-serial-number',
			helperText: '',
			required: true,
		},
		{
			type: 'autocomplete',
			name: 'antennaType',
			label: 'Antenna Type',
			id: 'antenna-antenna-type',
			required: true,
			helperText: '',
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option?.antennaTypeName || String(option?.id || ''),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_ANTENNATYPES);
				const antennaTypes: AntennaType[] = await res.json();

				return antennaTypes;
			},
		},

		{
			type: 'autocomplete',
			name: 'antRefPointType',
			label: 'Antenna Reference Point Type',
			id: 'antenna-ant-ref-point-type',
			required: true,
			helperText: '',
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option?.antennaRefPointTypeName || String(option?.id || ''),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_ANTREFPOINTTYPES);
				const antRefPointTypes: AntRefPointType[] = await res.json();

				return antRefPointTypes;
			},
		},

		{
			type: 'autocomplete',
			name: 'radome',
			label: 'Radome',
			id: 'antenna-radome',
			helperText: 'Serial number (Type name)',
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option.serialNumber !== '' ? option.serialNumber + ' (' + option.radomeType.radomeTypeName + ')' : '',
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_AVAIlABLE_RADOMES);
				const radomes: Radome[] = await res.json();

				return radomes;
			},
		},

		{
			type: 'datetime',
			name: 'timestampGuarantee',
			label: 'Guarantee',
			id: 'antenna-timestamp-guarantee',
		},

		{
			type: 'checkbox',
			name: 'exist',
			label: 'Discarded / Defective',
			id: 'antenna-exist',
		},

		{
			type: 'multilineText',
			name: 'additionalInformation',
			label: 'Additional Information',
			id: 'antenna-additional-information',
		},

		{
			type: 'autocomplete',
			name: 'site',
			label: 'Site',
			id: 'antenna-site',
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option?.siteName || String(option?.id || ''),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_SITES_MINIMAL);
				const sites: SiteMinimal[] = await res.json();

				return sites;
			},
		},

		{
			type: 'text',
			name: 'equipmentLabel',
			label: 'Equipment label',
			id: 'antenna-equipment-label',
		},
	]
};

const SubmitAntenna = async (data: typeof defaultValues) => {
	const dispatch = store.dispatch;

	await dispatch(createAntenna({ antenna: {
		serialNumber: data.serialNumber,
		antennaTypeName: data.antennaType.antennaTypeName,
		antRefPointTypeName: data.antRefPointType.antennaRefPointTypeName,
		radomeId: data?.radome?.id || null,
		timestampGuarantee: data?.timestampGuarantee || null,
		exist: data.exist === 0 ? 1 : 0,
		additionalInformation: data?.additionalInformation || null,
		equipmentLabel: data?.equipmentLabel || null,
		siteId: data?.site?.id || null
	} }));

	dispatch(resetAntennaState());

	dispatch(setAlertState({
		severity: 'success',
		open: true,
		title: 'Success',
		message: 'Successfully created antenna with serial number: ' + data.serialNumber
	}));
};

export const AntennaCreate: React.FC<{}> = () => {

	const antenna = useAppSelector(selectAntennaState);

	const { control, formState, handleSubmit, reset, trigger } = useForm<typeof defaultValues>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		values: {
			...defaultValues,
			...antenna,
			exist: antenna?.exist === 0 ? 1 : 0,
		},
		defaultValues: {
			...defaultValues,
		},
	});

	const {
		isDirty,
		isSubmitting,
		isSubmitted,
		isSubmitSuccessful,
		isValidating,
		isValid,
	} = formState;

	const shouldValidateOnce = antenna !== null;

	useEffect(() => {
		if (shouldValidateOnce) {
			trigger();
		}
	}, [shouldValidateOnce, trigger]);

	useEffect(() => {
		reset();
	}, [isSubmitSuccessful, reset]);

	return (
		<Container maxWidth={'xl'}>
			<h1>Create a new antenna</h1>
			<Form
				control={control}
				onSubmit={handleSubmit(SubmitAntenna)}
				isDirty={isDirty}
				isSubmitting={isSubmitting}
				isSubmitted={isSubmitted}
				isValidating={isValidating}
				isValid={isValid}
				readOnlyValues={[]}
				{...config}
			/>
		</Container>
	);
};
