export interface MarkAntRefPoint {
	id: number;
	deltaNorth: number;
	deltaEast: number;
	deltaHeight: number;
	timestampRegistered: string;
	additionalInformation: string | null;
}

export const markAntRefPointTemplate: MarkAntRefPoint = {
	id: 0,
	deltaNorth: 0,
	deltaEast: 0,
	deltaHeight: 0,
	timestampRegistered: '',
	additionalInformation: '',
};
