import { AddReceiverTypeType, ReceiverType } from '../../../redux/ReceiverType/receiverType.types';
import { FormListType, addForm, getAttributeList, validateForm } from '../../Dashboard/DashboardTabs/Konfigurasjon/HistService';
import React, { useEffect, useState } from 'react';
import {
	addReceiverTypeElement,
	fetchReceiverTypeList,
	updateReceiverTypeElement,
} from '../../../redux/ReceiverType/receiverType.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import MuiTable from '../../../components/Table/Table/Table';
import { regexList } from '../../../service/regex';
import { selectReceiverType } from '../../../redux/ReceiverType/receiverType.slice';

interface MuiReceiverTypeElement {
	'ID': number;
	'Receiver Type': string;
}

/**
 * Helper function to map receiver type to what's used by MUI table
 */
const receiverTypeToMuiElement = ({ id, receiverTypeName }: ReceiverType) => ({
	'ID': id,
	'Receiver Type': receiverTypeName,
});

/**
 * Helper function to map MUI table element to receiver type
 */
const MuiElementToReceiverType = ({ 'ID': id, 'Receiver Type': receiverTypeName }: MuiReceiverTypeElement): ReceiverType => ({
	id,
	receiverTypeName,
});

const Receiver_Types = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const receiverTypeData = useAppSelector(selectReceiverType);

	const [formValues, setFormValues] = useState<AddReceiverTypeType>({
		receiverTypeName: ''
	});
	const [error, setError] = useState<any>(undefined);

	useEffect(() => {
		dispatch(fetchReceiverTypeList());
	}, [dispatch]);

	// Fields displayed in the receiver type table head
	const formList: FormListType[] = [
		{
			label: 'ID',
			keyName: 'id',
			regex: regexList.freepass,
			number: true,
			readOnly: true,
		},
		{
			label: 'Receiver Type',
			keyName: 'receiverTypeName',
			regex: regexList.freepass,
			number: false,
			readOnly: false,
		},
	];

	// Field displayed in the add receiver type popup window
	const newTagList: FormListType[] = [
		{
			label: 'Receiver Type',
			keyName: 'receiverTypeName',
			regex: regexList.freepass,
			number: false,
		},
	];

	const handleFormChange = (keyProp: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues,	[keyProp]: event.target.value });
	};

	return (
		<>
			<h2>Receiver Types</h2>
			<MuiTable
				name={'receiverTypeList'}
				head={getAttributeList(formList, 'label')}
				body={(receiverTypeData?.receiverTypeList || []).map(receiverTypeToMuiElement)}
				addable={{
					title: 'Add receiver type',
					form: {
						modal: addForm(formValues, newTagList, error, handleFormChange),
						values: formValues,
						validate: () => {
							const validatedState = validateForm(formValues, formList);

							setError(validatedState.tmpErrObj);

							return validatedState.errorMsgStatus;
						},
					},
					buttonText: 'Add',
					addFuncCallback: (receiverType: ReceiverType) => async () => {
						// We manually let the actions do their dispatching in this strange way because we need to be sure
						// the updates are fully completed before getting the newest receiver type list from the server.
						await addReceiverTypeElement(receiverType)(dispatch);
						await fetchReceiverTypeList()(dispatch);
					},
					param: 0
				}}
				editable={{
					editFuncCallback: (receiverType: MuiReceiverTypeElement) => async () => {
						await updateReceiverTypeElement(MuiElementToReceiverType(receiverType))(dispatch);
						await fetchReceiverTypeList()(dispatch);
					},
					validation: getAttributeList(formList, 'regex'),
					readOnly: getAttributeList(formList, 'readOnly')
				}}
			/>
		</>
	);
};

export default Receiver_Types;
