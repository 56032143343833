import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import { InputProps } from './Form.types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import moment from 'moment';

const handleChange = (value: any, callback: (...event: any[]) => void) => {
	const m = moment(value);

	callback(m.isValid() ? m.toISOString() : value);
};

/**
 * Datetime input form field.
 *
 * The value managed by the control should be a moment object.
 */
export const DatetimeInput: React.FC<InputProps> = ({ control, name, label, id, required = false }) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, onChange, value }, fieldState: { error, invalid } }) => {
				const textFieldProps = {
					onBlur,
					required,
					error: Boolean(error),
					variant: 'outlined' as 'outlined',
					fullWidth: true,
				};

				const errorMessage = invalid ? error?.message || 'Invalid input' : '';

				return (
					<LocalizationProvider
						dateAdapter={AdapterMoment}
						adapterLocale='nb-NO'>
						<Grid container direction='row' spacing={2}>
							<Grid item xs={6}>
								<DatePicker
									label={`${label} (Date)`}
									inputFormat='DD.MM.YYYY'
									value={value}
									onChange={(val) => handleChange(val, onChange)}
									renderInput={(params) =>
										<TextField
											{...params}
											{...textFieldProps}
											type='date'
											id={`${id}-date`}
											helperText={errorMessage}
										/>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<TimePicker
									label={`${label} (UTC Time)`}
									inputFormat='HH:mm'
									disableOpenPicker={true}
									value={value}
									onChange={(val) => handleChange(val, onChange)}
									renderInput={(params) =>
										<TextField
											{...params}
											{...textFieldProps}
											type='time'
											id={`${id}-time`}
											helperText={errorMessage}
										/>
									}
								/>
							</Grid>
						</Grid>
					</LocalizationProvider>
				);
			}}
		/>
	);
};
