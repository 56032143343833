import alertStateReducer from './Alert/alert.slice';
import antCalibTypeReducer from './AntCalibType/antCalibType.slice';
import antRefPointTypeReducer from './AntRefPointType/antRefPointType.slice';
import antennaReducer from './Antenna/antenna.slice';
import antennaTypeReducer from './AntennaType/AntennaType.slice';
import authStateReducer from './Auth/auth.slice';
import { configureStore } from '@reduxjs/toolkit';
import countryReducer from './Country/country.slice';
import extClockTypeReducer from './ExtClockType/extClockType.slice';
import infDocReducer from './InfDoc/infdoc.slice';
import loadingStateReducer from './Loading/loading.slice';
import markRefPointReducer from './MarkRefPoint/markRefPoint.slice';
import radomeStateReducer from './Radome/radome.slice';
import radomeTypeReducer from './RadomeType/radomeType.slice';
import receiverStateReducer from './Receiver/receiver.slice';
import receiverTypeReducer from './ReceiverType/receiverType.slice';
import siteConfigDataReducer from './SiteConfig/siteConfig.slice';
import siteDataReducer from './Site/siteData.slice';
import siteListReducer from './Site/siteList.slice';
import tagListReducer from './Tag/tag.slice';

const preloadedState = {};

export const store = configureStore({
	reducer: {
		alertState: alertStateReducer,
		antennaState: antennaReducer,
		antCalibTypeState: antCalibTypeReducer,
		antRefPointTypeState: antRefPointTypeReducer,
		antennaTypeState: antennaTypeReducer,
		authState: authStateReducer,
		countryState: countryReducer,
		extClockTypeState: extClockTypeReducer,
		infDocState: infDocReducer,
		loadingState: loadingStateReducer,
		radomeState: radomeStateReducer,
		radomeTypeState: radomeTypeReducer,
		receiverState: receiverStateReducer,
		receiverTypeState: receiverTypeReducer,
		siteConfigDataState: siteConfigDataReducer,
		siteDataState: siteDataReducer,
		siteListState: siteListReducer,
		tagListState: tagListReducer,
		markRefPointState: markRefPointReducer,
	},
	preloadedState,
});

// Declare various useful types
export type ApplicationGetState = typeof store.getState;
export type ApplicationStateType = ReturnType<ApplicationGetState>;
export type ApplicationDispatch = typeof store.dispatch;
