import { AppBar, Grid, MenuItem, Toolbar } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import NestedMenu from './NestedMenu';
import React from 'react';
import { navBarElements } from './NavBarItems';
import { navBarStyles } from './NavBar.styles';
import { navbarElementType } from './NavBar.types';
import { selectAuthState } from '../../redux/Auth/auth.slice';
import { setAuthState } from '../../redux/Auth/auth.actions';

const NavBar = (): JSX.Element => {
	const classes = navBarStyles;
	const dispatch = useAppDispatch();

	const auth = useAppSelector(selectAuthState);

	const handleLogout = () => {
		dispatch(setAuthState({
			authenticated: false,
			role: null,
			username: '',
		}));

		window.location.href = API_ENDPOINTS.LOGOUT;
	};

	return (
		<AppBar position={'static'} sx={classes.root}>
			<Toolbar>
				<Grid container>
					{navBarElements.map((item: navbarElementType) =>
						// <Grid item xs={1} key={item.title} style={{ border: '2px solid red' }}>
						<NestedMenu
							key={item.title}
							title={item.title}
							link={item.link}
							icon={item.icon}
							depth={0}
							disabled={item.disabled}
						>
							{item.children}
						</NestedMenu>
						// </Grid>
					)}
					<MenuItem role={'button'} onClick={handleLogout}>
						{'Logout, ' + auth.username}
					</MenuItem>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};

export default NavBar;
