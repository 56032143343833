import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { ApplicationStateType } from '../store';

import { SiteConfig } from './siteConfig.types';

const initialState = null as SiteConfig | null;

export const siteConfigSlice = createSlice({
	name: 'siteConfigData',
	initialState,
	reducers: {
		setSiteConfigState: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return { ...state, ...action.payload };
		},
		resetSiteConfigState: () => null,
	},
});

export const selectSiteConfigState = (state: ApplicationStateType) => state.siteConfigDataState;

export default siteConfigSlice.reducer;
