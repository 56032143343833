import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatetimeFormat, momentFormat } from '../../service/moment';
import React, { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { CustomPagination } from '../../components/Pagination/CustomPagination';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import MuiPaper from '../../components/Paper/Paper';
import { Site } from '../../redux/Site/site.types';
import Stack from '@mui/material/Stack';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { fetchAsDataUri } from '../../service/fetchAsDataUri';

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID',
		renderCell: params =>
			// Link to SITE_SINGLE here in the future
			<Link to={`/dashboard/${encodeURIComponent(params.value)}/siteinfo`}>{params.value}</Link>,
	},
	{ field: 'siteName', headerName: 'Name', flex: 1 },
	{ field: 'operStatTypeName', headerName: 'Status', flex: 1 },
	{ field: 'priority', headerName: 'Priority', flex: 1 },
	{
		field: 'timestampRegistered',
		headerName: 'Registered',
		flex: 1,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{ field: 'countryName',	headerName: 'Country', flex: 1 },
	{ field: 'countyName', headerName: 'County', flex: 1 },
	{ field: 'municipalityName', headerName: 'Municipality', flex: 1 },
	{ field: 'zipCode', headerName: 'Zipcode', flex: 1 },
	{ field: 'city', headerName: 'City', flex: 1 },
	{ field: 'visitAddress', headerName: 'Address', flex: 2 },
	{ field: 'landRegistryNumber', headerName: 'LandRegistryNumber', flex: 1 },
	{ 	field: 'active',
		headerName: 'Active',
		flex: 1,
		renderCell: params =>
			<span>{params.value === 1 ? 'Active' : 'Not active'}</span>	}
];

const Sites: React.FC = () => {
	const loading =
		<Alert severity='warning'>
			{'Loading all sites... please wait'}
		</Alert>;

	const [sites, setSites] = useState<Site[]>([]);

	useEffect(() => {
		const effect = async () => {
			const response = await fetch(API_ENDPOINTS.GET_SITE_SUMMARIES);
			const data = await response.json();

			setSites(data);
		};

		effect();
	}, []);

	if (sites.length === 0) {
		return loading;
	}

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1>Sites</h1>
					<Stack spacing={2}>
						<div>
							<ButtonGroup variant='outlined'>
								<Button
									startIcon={<FolderZipIcon />}
									onClick={async () => {
										window.location.href = await fetchAsDataUri(API_ENDPOINTS.GET_SITES_EXCEL);
									}}>
									Export to Excel
								</Button>
								<Button
									startIcon={<TextSnippetIcon />}
									onClick={async () => {
										window.location.href = await fetchAsDataUri(API_ENDPOINTS.GET_SITES_CSV);
									}}>
									Export to CSV
								</Button>
							</ButtonGroup>
						</div>
						<Box height={800}>
							<DataGrid
								rows={sites}
								columns={columns}
								sx={{
									'& .MuiDataGrid-columnHeader': {
										color: 'white',
										backgroundColor: 'primary.dark',
										'& .MuiDataGrid-columnHeaderTitle': {
											fontWeight: 'bold',
										}
									},
									'& .MuiIconButton-root': {
										color: 'white',
									},
									'& .MuiDataGrid-cell': {
										padding: '10px',
									},
									'& .MuiDataGrid-cell:focus': {
										outline: 'initial',
									},
								}}
								getRowHeight={() => 'auto'}
								disableSelectionOnClick={true}
								components={{
									Pagination: CustomPagination,
								}} />
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default Sites;
