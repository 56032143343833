import { momentApiFormat } from './moment';

export const groupBy = (xs: any, key: string) => {
	return xs.reduce((rv: any, x: any) => {
		(rv[x[key]] = rv[x[key]] || []).push(x);

		return rv;
	}, {});
};

export const sortGrouped = (data: any, keyName: string, sortBy: string) => {
	const tmpToBeSortedList: any[] = [];

	Object.keys(data).forEach((dataKey: string) => tmpToBeSortedList.push({
		[keyName]: data[dataKey][0][keyName],
		[sortBy]: data[dataKey][0][sortBy]
	}));

	tmpToBeSortedList.sort((a, b) => momentApiFormat(a[sortBy]).valueOf() < momentApiFormat(b[sortBy]).valueOf() ? 1 : -1);

	const newSortedGroupedList: any[] = [];

	tmpToBeSortedList.forEach((item: any) => newSortedGroupedList.push(data[item[keyName]]));

	return newSortedGroupedList;
};
