import { GridPagination, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import React from 'react';
import { TablePaginationProps } from '@mui/material/TablePagination';

/**
 * Custom pagination adapted from here:
 * https://github.com/mui/mui-x/blob/v5.17.18/docs/data/data-grid/components/CustomPaginationGrid.tsx
 */
export const CustomPagination = (props: any) => {
	const ActionsComponent =
		({ page, onPageChange, className, }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
			const apiRef = useGridApiContext();
			const pageCount = useGridSelector(apiRef, gridPageCountSelector);

			return (

				<Pagination
					color='primary'
					className={className}
					count={pageCount}
					page={page + 1}
					onChange={(event, newPage) => {
						onPageChange(event as any, newPage - 1);
					}}
				/>
			);
		};

	return (
		<GridPagination
			ActionsComponent={ActionsComponent}
			{...props} />
	);
};
