import { Grid } from '@mui/material';
import HorizontalTable from '../../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../../components/Table/Table.types';
import { HovedinfoPropsType } from './Hovedinfo.types';
import MuiPaper from '../../../../components/Paper/Paper';
import React from 'react';
import { Site } from '../../../../redux/Site/site.types';

const Stedsinformasjon: React.FC<HovedinfoPropsType> = (props: HovedinfoPropsType) => {
	const networkRootContent = (siteConfig: Site): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [
			{
				title: 'Address:',
				body: siteConfig.visitAddress !== null ? siteConfig.visitAddress : '',
			},
			{
				title: 'LandRegistryNumber:',
				body: siteConfig.landRegistryNumber !== null ? siteConfig.landRegistryNumber : '',
			},
		];

		let zipData = '';

		if (siteConfig.zipCode !== null) {
			zipData += siteConfig.zipCode;
		}

		if (siteConfig.city !== null) {
			zipData += ' / ' + siteConfig.city;
		}

		contentList.push(
			{
				title: 'ZIP code / city:',
				body: zipData !== '' ? zipData : '',
			},
			{
				title: 'Description:',
				body: siteConfig.accessDescription !== null ? siteConfig.accessDescription : '',
			},
		);

		return contentList;
	};

	return (
		<MuiPaper title={'Location information'}>
			<Grid container>
				<Grid item xs={12}>
					<HorizontalTable content={networkRootContent(props.siteData)} />
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default Stedsinformasjon;
