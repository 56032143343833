import Alert from '@mui/material/Alert';
import Annet from './Annet';
import { Grid } from '@mui/material';
import HovedinfoSite from './HovedinfoSite';
import Konfigurasjoner from './Konfigurasjoner';
import Nettverksinformasjon from './Nettverksinformasjon';
import React from 'react';
import Stedsinformasjon from './Stedsinformasjon';
import { selectSiteData } from '../../../../redux/Site/siteData.slice';
import { useAppSelector } from '../../../../redux/hooks';

const Hovedinfo = (): JSX.Element => {
	const siteData = useAppSelector(selectSiteData);

	if (siteData === null) {
		return (
			<Alert severity='warning'>
				{'Loading site data...'}
			</Alert>
		);
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={4}>
				<HovedinfoSite siteData={siteData} />
			</Grid>
			<Grid item xs={8}>
				<Stedsinformasjon siteData={siteData} />
			</Grid>
			<Grid item xs={8}>
				<Nettverksinformasjon siteData={siteData} />
			</Grid>
			<Grid item xs={4}>
				<Annet siteData={siteData} />
			</Grid>
			<Grid item xs={12}>
				<Konfigurasjoner siteData={siteData} />
			</Grid>
		</Grid>
	);
};

export default Hovedinfo;
