import { globalStyles } from '../../service/styles/global.styles';

export const tabStyles = {
	root: {
		backgroundColor: 'primary.dark',
		borderRadius: globalStyles.borderRadius,
		marginBottom: '10px',
	},
	disabled: {
		color: 'grey.500',
	},
	tabIndicator: {
		backgroundColor: 'common.white',
		padding: '1px',
		borderRadius: globalStyles.borderRadius,
	},
};
