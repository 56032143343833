import { globalStyles } from '../../service/styles/global.styles';

export const dashboardStyles = {
	root: {
		margin: globalStyles.margin.root,
	},
	sitesDropdownRow: {
		display: 'flex',
		alignItems: 'center',
	},
	siteDropdown: {
		width: 250,
		margin: '10px',
	},
};
