import { DataGrid, GridColDef, GridPagination, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { DatetimeFormat, momentFormat } from '../../../service/moment';
import React, { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import MuiPaper from '../../../components/Paper/Paper';
import Pagination from '@mui/material/Pagination';
import { ReceiverSummary } from '../../../redux/Receiver/receiver.types';
import Stack from '@mui/material/Stack';
import { TablePaginationProps } from '@mui/material/TablePagination';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID',
		renderCell: params =>
			<Link to={`/receivers/${encodeURIComponent(params.value)}`}>{params.value}</Link>,
	},
	{ field: 'serialNumber', headerName: 'Serial number', flex: 1 },
	{ field: 'receiverTypeName', headerName: 'Type', flex: 1 },
	{ field: 'satSystemNames', headerName: 'Tracker', flex: 1 },
	{ field: 'firmwareVersion', headerName: 'Firmware' },
	{ field: 'elevationCutoffAngle', headerName: 'Elevation' },
	{
		field: 'timestampRegistrated',
		headerName: 'Registered',
		flex: 1,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{
		field: 'timestampGuarantee',
		headerName: 'Guarantee',
		flex: 1,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{ field: 'equipmentLabel', headerName: 'Equipment label' },
	{ field: 'siteNames', headerName: 'Site' },
	{ field: 'siteConfigFourCharIds', headerName: 'Siteconfig' },
	{ field: 'ipAddress', headerName: 'IP address', flex: 1 },
	{
		field: 'exist',
		headerName: 'Discarded/defective?',
		renderCell: params =>
			<span>{params.value === 1 ? 'NO' : 'YES'}</span>
	},
	{ field: 'additionalInformation', headerName: 'Additional information', sortable: false, flex: 2 },
];

/**
 * Custom pagination adapted from here:
 * https://github.com/mui/mui-x/blob/v5.17.18/docs/data/data-grid/components/CustomPaginationGrid.tsx
 */
const CustomPagination = (props: any) => {
	const ActionsComponent = ({
		page,
		onPageChange,
		className,
	}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
		const apiRef = useGridApiContext();
		const pageCount = useGridSelector(apiRef, gridPageCountSelector);

		return (
			<Pagination
				color='primary'
				className={className}
				count={pageCount}
				page={page + 1}
				onChange={(event, newPage) => {
					onPageChange(event as any, newPage - 1);
				}}
			/>
		);
	};

	return (
		<GridPagination
			ActionsComponent={ActionsComponent}
			{...props} />
	);
};

/**
 * Creates a data uri from the result of fetching the provided url.
 *
 * Note: This is done because the proxy used in the dev server doesn't play nice when accessing
 * api links directly (try in browser e.g. 'http://localhost:3000/receivers' and see an error page)
 */
const fetchAsDataUri = async (uri: string) => {
	const response = await fetch(uri);
	const data = await response.blob();
	const reader = new FileReader();

	const b64: string | ArrayBuffer | null = await new Promise(resolve => {
		reader.readAsDataURL(data);
		reader.onloadend = () => resolve(reader.result);
	});

	return `${b64}`;
};

const Receivers: React.FC = () => {
	const [receivers, setReceivers] = useState<ReceiverSummary[]>([]);

	useEffect(() => {
		const effect = async () => {
			const response = await fetch(API_ENDPOINTS.GET_RECEIVERS);
			const data = await response.json();

			setReceivers(data);
		};

		effect();
	}, []);

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1>Receivers</h1>
					<Stack spacing={2}>
						<div>
							<ButtonGroup variant='outlined'>
								<Button
									startIcon={<FolderZipIcon />}
									onClick={async () => {
										window.location.href = await fetchAsDataUri(API_ENDPOINTS.GET_RECEIVERS_EXCEL);
									}}>
									Export to Excel
								</Button>
								<Button
									startIcon={<TextSnippetIcon />}
									onClick={async () => {
										window.location.href = await fetchAsDataUri(API_ENDPOINTS.GET_RECEIVERS_CSV);
									}}>
									Export to CSV
								</Button>
							</ButtonGroup>
						</div>
						<Box height={800}>
							<DataGrid
								rows={receivers}
								columns={columns}
								sx={{
									'& .MuiDataGrid-columnHeader': {
										color: 'white',
										backgroundColor: 'primary.dark',
										'& .MuiDataGrid-columnHeaderTitle': {
											fontWeight: 'bold',
										}
									},
									'& .MuiIconButton-root': {
										color: 'white',
									},
									'& .MuiDataGrid-cell': {
										padding: '10px',
									},
									'& .MuiDataGrid-cell:focus': {
										outline: 'initial',
									},
								}}
								getRowHeight={() => 'auto'}
								disableSelectionOnClick={true}
								components={{
									Pagination: CustomPagination,
								}} />
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default Receivers;
