import { FoundationType, foundationTypeTemplate } from '../FoundationType/foundationType.types';
import { MonumentType, monumentTypeTemplate } from '../MonumentType/monumentType.types';
import { SiteMinimal } from '../Site/site.types';

export interface Monument {
	id: number;
	site: SiteMinimal | null;
	foundationDepth: number | null;
	height: number | null;
	timestampRegistered: string | null;
	iersDomesNumber: string | null;
	inscription: string | null;
	description: string | null;
	monumentType: MonumentType;
	foundationType: FoundationType;
}

export interface MonumentSummary {
	id: number;
	site: SiteMinimal | null;
	foundationTypeName: string;
	monumentTypeName: string;
	foundationDepth: number;
	heightM: number;
	timestampRegistered: string;
	iersDomesNumber: string;
	inscription: string;
	description: string;
}

export const monumentTemplate: Monument = {
	id: 0,
	site: null,
	foundationDepth: 0,
	height: 0,
	timestampRegistered: '',
	iersDomesNumber: '',
	inscription: '',
	description: '',
	monumentType: monumentTypeTemplate,
	foundationType: foundationTypeTemplate,
};
