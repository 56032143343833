export interface SiteConfigStatType {
	id: number;
	siteConfigStatTypeName: 'Closed' | 'Temporary' | 'Operational';
}

export const siteConfigStatTypeTemplate: SiteConfigStatType = {
	id: 0,
	siteConfigStatTypeName: 'Closed',
};

