/**
 * Represents a single radome type
 */
export interface RadomeType {
	id : number;
	radomeTypeName: string;
}

/**
 * Represents a list of radome types
 */
export interface RadomeTypeListType {
	radomeTypeList: RadomeType[];
}

/**
 * Represents an id-less radome type, suitable for adding new radome type with the api
 */
export type AddRadomeTypeType = Omit<RadomeType, 'id'>;

export const radomeTypeTemplate: RadomeType = {
	id: 0,
	radomeTypeName: '',
};
