import React from 'react';

const Kontaktpersoner = (): JSX.Element => {
	return (
		<>
			<h1>Contact persons</h1>
		</>
	);
};

export default Kontaktpersoner;
