import React from 'react';

const Contactpersons: React.FC = () => {
	return (
		<>
			<h1>Contactpersons</h1>
		</>
	);
};

export default Contactpersons;

