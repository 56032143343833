/**
 * Represents a single SiteConfigHist element
 */
import { Antenna, antennaTemplate } from '../Antenna/antenna.types';
import { Receiver, receiverTemplate } from '../Receiver/receiver.types';
import { MarkRefPoint } from '../MarkRefPoint/markRefPoint.types';

export interface SiteConfigHist {
	id: number,
	siteConfigId: number,
	timestampRegistered: string,
	timestampValidFrom: string,
	preparedBy: string,
	fromReceiverId: number,
	toReceiverId: number,
	fromAntennaId: number,
	toAntennaId: number,
	fromMonumentId: number,
	toMonumentId: number,
	fromMarkAntRefPointId: number,
	toMarkAntRefPointId: number,
	fromMarkRefPointId: number,
	toMarkRefPointId: number,
	fromExtClockId: number,
	toExtClockId: number,
	fromCableId: number,
	toCableId: number,
	fromStorageLocationId: number,
	toStorageLocationId: number,
	toReceiver: Receiver | null,
	toAntenna: Antenna | null
}

/**
 * Represents the history of a receiver within a site config
 */
export type ReceiverSiteConfigHist = Pick<SiteConfigHist,
	'timestampValidFrom' |
	'toReceiverId'
	> & {
	fourCharId: string
};

/**
 * Represents the history of an antenna within a site config
 */
export type AntennaSiteConfigHist = Pick<SiteConfigHist,
	'id' |
	'timestampRegistered' |
	'timestampValidFrom' |
	'preparedBy' |
	'fromAntennaId' |
	'toAntennaId'
	> & {
	fourCharId: string
};

export interface MarkRefPointSiteConfigHist {
	fourCharId: string;
	timeFrom: string;
	timeTo: string;
	preparedBy: string;
	markRefPoint: MarkRefPoint;
}

export const siteConfigHistTemplate: SiteConfigHist = {
	id: 0,
	siteConfigId: 0,
	timestampRegistered: '',
	timestampValidFrom: '',
	preparedBy: '',
	fromReceiverId: 0,
	toReceiverId: 0,
	fromAntennaId: 0,
	toAntennaId: 0,
	fromMonumentId: 0,
	toMonumentId: 0,
	fromMarkAntRefPointId: 0,
	toMarkAntRefPointId: 0,
	fromMarkRefPointId: 0,
	toMarkRefPointId: 0,
	fromExtClockId: 0,
	toExtClockId: 0,
	fromCableId: 0,
	toCableId: 0,
	fromStorageLocationId: 0,
	toStorageLocationId: 0,
	toReceiver: receiverTemplate,
	toAntenna: antennaTemplate
};
