import { API_ENDPOINTS } from './routes/ApiEndpoints';
import { setAlertState } from '../redux/Alert/alert.actions';
import { setLoadingStatus } from '../redux/Loading/loading.actions';
import { store } from '../redux/store';

export const getRequest = async <T>(url: string): Promise<T | null> => {
	const dispatch = store.dispatch;

	dispatch(setLoadingStatus(true));
	const options: RequestInit = {
		method: 'GET',
		body: null,
	};

	const data: T | null = await request(url, options);

	return data;
};

export const postRequest = async <T, K>(url: string, bodyData: K): Promise<T | null> => {
	const dispatch = store.dispatch;

	dispatch(setLoadingStatus(true));
	const options: RequestInit = {
		method: 'POST',
		body: JSON.stringify(bodyData),
	};
	const data: T | null = await request(url, options);

	return data;
};

export const putRequest = async <T, K>(url: string, bodyData: K): Promise<T | null> => {
	const dispatch = store.dispatch;

	dispatch(setLoadingStatus(true));
	const options: RequestInit = {
		method: 'PUT',
		body: JSON.stringify(bodyData),
	};

	// console.log('PUT bodyData: ' + JSON.stringify(bodyData));
	// console.log('PUT url: ' + url);
	const data: T | null = await request(url, options);

	return data;
};

export const patchRequest = async <T, K>(url: string, bodyData: K): Promise<T | null> => {
	const dispatch = store.dispatch;

	dispatch(setLoadingStatus(true));
	const options: RequestInit = {
		method: 'PATCH',
		body: JSON.stringify(bodyData),
	};

	// console.log('PATCH bodyData: ' + JSON.stringify(bodyData));
	// console.log('PATCH url: ' + url);
	const data: T | null = await request(url, options);

	return data;
};

export const deleteRequest = async <T>(url: string): Promise<T | null> => {
	const dispatch = store.dispatch;

	dispatch(setLoadingStatus(true));
	const options: RequestInit = {
		method: 'DELETE',
	};
	const data: T | null = await request(url, options);

	return data;
};

export const request = <T>(url: string, options: RequestInit): Promise<T | null> => {
	const response = fetch(url,
		{
			...options,
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
		}
	);

	return handleResponse<T>(response);
};

export const handleResponse = async <T>(responsePromise: Promise<Response>): Promise<T | null> => {
	const dispatch = store.dispatch;
	const data: T | null = await responsePromise
		.then(async (response: Response) => {
			if (!response.ok) {
				dispatch(setLoadingStatus(false));
				const jsonResponse = await response.json();

				// console.log('FEILMELDING2: ' + response.status);
				// console.log('FEILMELDING4: ' + response.statusText);
				// console.log('FEILMELDING5: ' + jsonResponse.message);
				// console.log('FEILMELDING5: ' + jsonResponse.details);

				if (jsonResponse.details !== null) {
					throw new Error(response.status.toString() + ' - ' + response.statusText +
						' - ' + jsonResponse.message + ' - ' + jsonResponse.details);
				} else {
					throw new Error(response.status.toString() + ' - ' + response.statusText + ' - ' + jsonResponse.message);
				}
			} else {
				return response.json();
			}
		})
		.then((responseData: T) => responseData)
		.catch((error: Error) => {
			const currentError = error.toString();

			// console.log('currentError: ' + currentError);
			const title = currentError;

			let message;

			if (currentError.includes('400')) {
				// title = 'Request error - Code: [400] - Bad Request';
				message = 'The server cannot or will not process the request due to an apparent client error (e.g., malformed request syntax, size ' +
					'too large, invalid request message framing, or deceptive request routing).';
			} else if (currentError.includes('403')) {
				// title = 'Request error - Code: [403] - Forbidden';
				message = 'The user does not have the authority for this operation.';
			} else if (currentError.includes('500')) {
				// title = 'Request error - Code: [500] - Internal Server Error';
				message = 'Validation failed on save - wrong input data.';
			} else if (currentError.includes('Failed to fetch')) {
				// title = 'Request error - Failed to fetch';
				message = 'Session expired - please log in.';
			} else {
				// title = 'Request error - Code: [Unknown]';
				message = 'Contact system administrator - unhandled exception.';
			}

			dispatch(setLoadingStatus(false));
			dispatch(setAlertState({
				open: true,
				severity: 'error',
				title: title,
				message: message,
			}));

			// If session expired, display message for 5 seconds before redirecting user to the login page.
			if (currentError.includes('Failed to fetch')) {
				setTimeout(() => {
					document.location.href = API_ENDPOINTS.LOGIN;
				}, 5000);
			}

			return null;
		});

	dispatch(setLoadingStatus(false));

	return data;
};
