import MuiChip from './Chip';
import { PriorityChipPropsType } from './Chip.types';
import React from 'react';

const PriorityChip: React.FC<PriorityChipPropsType> = (props: PriorityChipPropsType) => {
	return (
		<MuiChip
			{...props}
			label={`Priority (${props.priority})`}
			type={
				props.priority === 0 ? 'locked'
					: props.priority === 1 ? 'error'
						: props.priority === 2 ? 'warning'
							: props.priority === 3 ? 'primary'
								: 'locked'
			}
		/>
	);
};

export default PriorityChip;
