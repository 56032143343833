import React from 'react';

const Error404: React.FC = () => {
	return (
		<>
			<h1>Error 404</h1>
		</>
	);
};

export default Error404;
