import HorizontalTable from '../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../components/Table/Table.types';
import { Monument } from '../../redux/Monument/monument.types';
import MuiPaper from '../../components/Paper/Paper';
import React from 'react';
import { momentPrettyFormat } from '../../service/moment';
import { paperStyles } from '../../components/Paper/Paper.styles';

const MonumentDetails = (monument: Monument): JSX.Element => {
	const getContent = (monumentData: Monument): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = monumentData.id !== 0;

		contentList.push(
			{
				title: 'ID:',
				body: isPresent ? monumentData.id : '',
			},
			{
				title: 'Foundation:',
				body: isPresent ? monumentData.foundationType.foundationTypeName : '',
			},
			{
				title: 'Monument type:',
				body: isPresent ? monumentData.monumentType.monumentTypeName : '',
			},
			{
				title: 'Site:',
				body: isPresent ? monumentData.site?.siteName : '',
			},
			{
				title: 'Depth:',
				body: isPresent ? monumentData.foundationDepth : '',
			},
			{
				title: 'Height:',
				body: isPresent ? monumentData.height : '',
			},
			{
				title: 'Registered:',
				body: isPresent && monumentData.timestampRegistered !== null ? momentPrettyFormat(monumentData.timestampRegistered) : '',
			},
			{
				title: 'IERS domes number:',
				body: isPresent ? monumentData.iersDomesNumber : '',
			},
			{
				title: 'Inscription:',
				body: isPresent ? monumentData.inscription : '',
			},
			{
				title: 'Description:',
				body: isPresent ? monumentData.description : '',
			},
		);

		return contentList;

	};

	return (
		<MuiPaper style={monument.id === 0 ? paperStyles.error : {}} title={'Monument'}>
			<HorizontalTable content={getContent(monument)} />
		</MuiPaper >
	);
};

export default MonumentDetails;
