import { AddAntCalibTypeType, AntCalibTypeType } from '../../../redux/AntCalibType/antCalibType.types';
import { FormListType, addForm, getAttributeList, validateForm } from '../../Dashboard/DashboardTabs/Konfigurasjon/HistService';
import React, { useEffect, useState } from 'react';
import {
	addAntCalibTypeElement,
	fetchAntCalibTypeList,
	updateAntCalibTypeElement
} from '../../../redux/AntCalibType/antCalibType.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import MuiTable from '../../../components/Table/Table/Table';
import { regexList } from '../../../service/regex';
import { selectAntCalibType } from '../../../redux/AntCalibType/antCalibType.slice';

interface MuiAntCalibTypeElement {
	'ID': number;
	'Antenna Calibration Type': string;
}

/**
 * Helper function to map antenna calibration type to what's used by MUI table
 */
const antCalibTypeToMuiElement = ({ id, antennaCalibrationTypeName }: AntCalibTypeType) => ({
	'ID': id,
	'Antenna Calibration Type': antennaCalibrationTypeName,
});

/**
 * Helper function to map MUI table element to antenna calibration type
 */
const MuiElementToAntCalibType =
	({ 'ID': id, 'Antenna Calibration Type': antennaCalibrationTypeName }: MuiAntCalibTypeElement): AntCalibTypeType => ({
		id,
		antennaCalibrationTypeName,
	});

const Ant_Calib_Types = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const antCalibTypeData = useAppSelector(selectAntCalibType);

	const [formValues, setFormValues] = useState<AddAntCalibTypeType>({
		antennaCalibrationTypeName: ''
	});
	const [error, setError] = useState<any>(undefined);

	useEffect(() => {
		dispatch(fetchAntCalibTypeList());
	}, [dispatch]);

	// Fields displayed in the antenna calibration type table head
	const formList: FormListType[] = [
		{
			label: 'ID',
			keyName: 'id',
			regex: regexList.freepass,
			number: true,
			readOnly: true,
		},
		{
			label: 'Antenna Calibration Type',
			keyName: 'antennaCalibrationTypeName',
			regex: regexList.freepass,
			number: false,
			readOnly: false,
		},
	];

	// Field displayed in the add antenna calibration type popup window
	const newTagList: FormListType[] = [
		{
			label: 'Antenna Calibration Type',
			keyName: 'antennaCalibrationTypeName',
			regex: regexList.freepass,
			number: false,
		},
	];

	const handleFormChange = (keyProp: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues,	[keyProp]: event.target.value });
	};

	return (
		<>
			<h2>AntCalib Types</h2>
			<MuiTable
				name={'antCalibTypeList'}
				head={getAttributeList(formList, 'label')}
				body={(antCalibTypeData?.antCalibTypeList || []).map(antCalibTypeToMuiElement)}
				addable={{
					title: 'Add antenna calibration type',
					form: {
						modal: addForm(formValues, newTagList, error, handleFormChange),
						values: formValues,
						validate: () => {
							const validatedState = validateForm(formValues, formList);

							setError(validatedState.tmpErrObj);

							return validatedState.errorMsgStatus;
						},
					},
					buttonText: 'Add',
					addFuncCallback: (antCalibType: AntCalibTypeType) => async () => {
						// We manually let the actions do their dispatching in this strange way because we need to be sure
						// the updates are fully completed before getting the newest antenna calibration type list
						// from the server.
						await addAntCalibTypeElement(antCalibType)(dispatch);
						await fetchAntCalibTypeList()(dispatch);
					},
					param: 0
				}}
				editable={{
					editFuncCallback: (antCalibType: MuiAntCalibTypeElement) => async () => {
						await updateAntCalibTypeElement(MuiElementToAntCalibType(antCalibType))(dispatch);
						await fetchAntCalibTypeList()(dispatch);
					},
					validation: getAttributeList(formList, 'regex'),
					readOnly: getAttributeList(formList, 'readOnly')
				}}
			/>
		</>
	);
};

export default Ant_Calib_Types;
