import { APP_ROUTES, EXTERNAL_ROUTES } from './AppRouter.constants';
import { Navigate, Outlet, createBrowserRouter, redirect } from 'react-router-dom';
import { AntennaCreate } from '../../pages/Utilities/Antennas/AntennaCreate';
import AntennaSingle from '../../pages/Utilities/Antennas/AntennaSingle';
import Antennas from '../../pages/Utilities/Antennas/Antennas';
import Bilder from '../../pages/Dashboard/DashboardTabs/Bilder/Bilder';
import Collocated_Instruments from '../../pages/Utilities/Collocated_Instruments/Collocated_Instruments';
import Connected_Site_Config from '../../pages/Other/Ip_Addresses/Connected_Site_Config/Connected_Site_Config';
import Connected_Site_Networkcomponents from '../../pages/Other/Ip_Addresses/Connected_Site_Networkcomponents/Connected_Site_Networkcomponents';
import Contactpersons from '../../pages/Other/Contactpersons/Contactpersons';
import Coordinate_Report from '../../pages/Other/Reports/Coordinate_Report/Coordinate_Report';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Documents from '../../pages/Other/Documents/Documents';
import Ekstrautstyr from '../../pages/Dashboard/DashboardTabs/Ekstrautstyr/Ekstrautstyr';
import Error404 from '../../pages/Error/Error404';
import External_Clocks from '../../pages/Utilities/External_Clocks/External_Clocks';
import Hovedinfo from '../../pages/Dashboard/DashboardTabs/Hovedinfo/Hovedinfo';
import Igs_Sitelog from '../../pages/Other/Reports/Igs_Sitelog/Igs_Sitelog';
import ImageSingle from '../../pages/Other/Images/ImageSingle';
import { ImageUploadForm } from '../../pages/Other/Images/ImageUploadForm';
import ImagesShow from '../../pages/Other/Images/ImagesShow';
import Import_Time_Series from '../../pages/Other/Time_Series/Import_Time_Series/Import_Time_Series';
import Interferences from '../../pages/Other/Interferences/Interferences';
import Interferens from '../../pages/Dashboard/DashboardTabs/Interferens/Interferens';
import Konfigurasjon from '../../pages/Dashboard/DashboardTabs/Konfigurasjon/Konfigurasjon';
import Kontaktpersoner from '../../pages/Dashboard/DashboardTabs/Kontaktpersoner/Kontaktpersoner';
import Loader from '../../components/Loader/Loader';
import Loggmessages from '../../pages/Loggmessages/Loggmessages';
import MarkRefPointHist from '../../pages/Dashboard/DashboardTabs/Konfigurasjon/MarkRefPointHist';
import Monuments from '../../pages/Utilities/Monuments/Monuments';
import MuiAlert from '../../components/Alert/Alert';
import NavBar from '../../components/NavBar/NavBar';
import Nettverkskomponenter from '../../pages/Dashboard/DashboardTabs/Nettverkskomponenter/Nettverkskomponenter';
import Networkcomponents from '../../pages/Utilities/Networkcomponents/Networkcomponents';
import { RadomeCreate } from '../../pages/Utilities/Radomes/RadomeCreate';
import RadomeSingle from '../../pages/Utilities/Radomes/RadomeSingle';
import Radomes from '../../pages/Utilities/Radomes/Radomes';
import React from 'react';
import ReceiverSingle from '../../pages/Utilities/Receivers/ReceiverSingle';
import Receivers from '../../pages/Utilities/Receivers/Receivers';
import Root from '../../pages/Root/Root';
import Show_Time_Series from '../../pages/Other/Time_Series/Show_Time_Series/Show_Time_Series';
import Sinex_Sitelog from '../../pages/Other/Reports/Sinex_Sitelog/Sinex_Sitelog';
import SiteConfigAntennaHist from '../../pages/Dashboard/DashboardTabs/Konfigurasjon/SiteConfigAntennaHist';
import { SiteConfigCreate } from '../../pages/SiteConfig/SiteConfigCreate';
import SiteConfigExtendedDetails from '../../pages/SiteConfig/SiteConfigExtendedDetails';
import { SiteConfigInfo } from '../../pages/Dashboard/DashboardTabs/Konfigurasjon/SiteConfigInfo';
import SiteConfigReceiverHist from '../../pages/Dashboard/DashboardTabs/Konfigurasjon/SiteConfigReceiverHist';
import SiteConfigSingle from '../../pages/SiteConfig/SiteConfigSingle';
import SiteConfigUpdate from '../../pages/SiteConfig/SiteConfigUpdate';
import Siteconfigs from '../../pages/SiteConfig/Siteconfigs';
import Siteinfo from '../../pages/Other/Reports/Siteinfo/Siteinfo';
import Sites from '../../pages/Site/Sites';
import Types_Register_Change from '../../pages/Other/Types_Register_Change/Types_Register_Change';
import Users from '../../pages/Other/Users/Users';
import Utstyr from '../../pages/Dashboard/DashboardTabs/Utstyr/Utstyr';
import { siteConfigTemplate } from '../../redux/SiteConfig/siteConfig.types';

const RootRoute = () =>
	<React.Fragment>
		<Loader/>
		<NavBar/>
		<MuiAlert/>
		<Outlet/>
	</React.Fragment>;

export const appRouter = createBrowserRouter([
	{
		// Route without a path allows to set shared elements like Loader, NavBar, MuiAlert etc.
		element: <RootRoute/>,
		children: [
			{
				path: APP_ROUTES.ROOT,
				element: <Root/>,
			},
			{
				path: APP_ROUTES.DASHBOARD.INDEX,
				element: <Dashboard/>,
				children: [
					{
						id: APP_ROUTES.DASHBOARD.SITEINFO,
						path: APP_ROUTES.DASHBOARD.SITEINFO,
						element: <Hovedinfo/>,
					},
					{
						id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.INDEX,
						path: APP_ROUTES.DASHBOARD.SITECONFIGINFO.INDEX,
						element: <Konfigurasjon/>,
						children: [
							{
								id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.SINGLE,
								path: APP_ROUTES.DASHBOARD.SITECONFIGINFO.SINGLE,
								element: <SiteConfigInfo/>,
								children: [
									{
										id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.DETAILS,
										path: APP_ROUTES.DASHBOARD.SITECONFIGINFO.DETAILS,
										element: <SiteConfigExtendedDetails/>,
									},
									{
										id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.RECEIVER_HISTORY,
										path: APP_ROUTES.DASHBOARD.SITECONFIGINFO.RECEIVER_HISTORY,
										element: <SiteConfigReceiverHist/>,
									},
									{
										id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.ANTENNA_HISTORY,
										path: APP_ROUTES.DASHBOARD.SITECONFIGINFO.ANTENNA_HISTORY,
										element: <SiteConfigAntennaHist/>,
									},
									{
										id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.MARKREFPOINT_HISTORY,
										path: APP_ROUTES.DASHBOARD.SITECONFIGINFO.MARKREFPOINT_HISTORY,
										element: <MarkRefPointHist/>,
									},
								],
							},
						],
					},
					{
						id: APP_ROUTES.DASHBOARD.SITECONTACTS,
						path: APP_ROUTES.DASHBOARD.SITECONTACTS,
						element: <Kontaktpersoner/>,
					},
					{
						id: APP_ROUTES.DASHBOARD.SITEIMAGES,
						path: APP_ROUTES.DASHBOARD.SITEIMAGES,
						element: <Bilder/>,
					},
					{
						id: APP_ROUTES.DASHBOARD.SITENETWORKCOMPONENTS,
						path: APP_ROUTES.DASHBOARD.SITENETWORKCOMPONENTS,
						element: <Nettverkskomponenter/>,
					},
					{
						id: APP_ROUTES.DASHBOARD.SITEEQUIPMENT,
						path: APP_ROUTES.DASHBOARD.SITEEQUIPMENT,
						element: <Utstyr/>,
					},
					{
						id: APP_ROUTES.DASHBOARD.SITEOPTIONALEQUIPMENT,
						path: APP_ROUTES.DASHBOARD.SITEOPTIONALEQUIPMENT,
						element: <Ekstrautstyr/>,
					},
					{
						id: APP_ROUTES.DASHBOARD.SITEINTERFERENCE,
						path: APP_ROUTES.DASHBOARD.SITEINTERFERENCE,
						element: <Interferens/>,
					},
				],
			},
			{
				path: APP_ROUTES.SITE.SITES,
				element: <Sites/>,
			},
			{
				path: APP_ROUTES.SITECONFIG.CREATE,
				element: <SiteConfigCreate/>
			},
			{
				path: APP_ROUTES.SITECONFIG.LIST,
				element: <Siteconfigs/>,
			},
			{
				path: APP_ROUTES.SITECONFIG.INDEX,
				element: <SiteConfigSingle/>,
				children: [
					{
						id: APP_ROUTES.SITECONFIG.DETAILS,
						path: APP_ROUTES.SITECONFIG.DETAILS,
						element: <SiteConfigExtendedDetails/>,
					},
					{
						id: APP_ROUTES.SITECONFIG.EDIT,
						path: APP_ROUTES.SITECONFIG.EDIT,
						element: <SiteConfigUpdate/>
					}
				]
			},
			{
				path: APP_ROUTES.UTILITIES.ANTENNAS,
				element: <Antennas/>,
			},
			{
				path: APP_ROUTES.UTILITIES.ANTENNA_SINGLE,
				element: <AntennaSingle/>,
			},
			{
				path: APP_ROUTES.UTILITIES.ANTENNA_CREATE,
				element: <AntennaCreate/>,
			},
			{
				path: APP_ROUTES.UTILITIES.RADOMES,
				element: <Radomes/>,
			},
			{
				path: APP_ROUTES.UTILITIES.RADOME_SINGLE,
				element: <RadomeSingle/>,
			},
			{
				path: APP_ROUTES.UTILITIES.CREATE_RADOME,
				element: <RadomeCreate/>,
			},
			{
				path: APP_ROUTES.UTILITIES.RECEIVERS,
				element: <Receivers/>,
			},
			{
				path: APP_ROUTES.UTILITIES.RECEIVER_SINGLE,
				element: <ReceiverSingle/>,
			},
			{
				path: APP_ROUTES.UTILITIES.EXTERNAL_CLOCKS,
				element: <External_Clocks/>,
			},
			{
				path: APP_ROUTES.UTILITIES.MONUMENTS,
				element: <Monuments/>,
			},
			{
				path: APP_ROUTES.UTILITIES.NETWORKCOMPONENTS,
				element: <Networkcomponents/>,
			},
			{
				path: APP_ROUTES.UTILITIES.COLLOCATED_INSTRUMENTS,
				element: <Collocated_Instruments/>,
			},
			{
				path: APP_ROUTES.LOGGMESSAGES,
				element: <Loggmessages/>,
			},
			{
				path: APP_ROUTES.OTHER.IMAGES.SHOW_IMAGES,
				element: <ImagesShow/>,
			},
			{
				path: APP_ROUTES.OTHER.IMAGES.IMAGE_SINGLE,
				element: <ImageSingle />,
			},
			{
				path: APP_ROUTES.OTHER.IMAGES.CREATE_IMAGE,
				element: <ImageUploadForm />,
			},
			{
				path: APP_ROUTES.OTHER.USERS,
				element: <Users/>,
			},
			{
				path: APP_ROUTES.OTHER.DOCUMENTS,
				element: <Documents/>,
			},
			{
				path: APP_ROUTES.OTHER.INTERFERENCES,
				element: <Interferences/>,
			},
			{
				path: APP_ROUTES.OTHER.CONTACTPERSONS,
				element: <Contactpersons/>,
			},
			{
				path: APP_ROUTES.OTHER.REPORTS.SINEX_SITELOG,
				element: <Sinex_Sitelog/>,
			},
			{
				path: APP_ROUTES.OTHER.REPORTS.IGS_SITELOG,
				element: <Igs_Sitelog/>,
			},
			{
				path: APP_ROUTES.OTHER.REPORTS.SITEINFO,
				element: <Siteinfo/>,
			},
			{
				path: APP_ROUTES.OTHER.REPORTS.COORDINATE_REPORT,
				element: <Coordinate_Report/>,
			},
			{
				path: APP_ROUTES.OTHER.IP_ADDRESSES.SHOW_ALL_CONNECTED_SITE_CONFIG,
				element: <Connected_Site_Config/>,
			},
			{
				path: APP_ROUTES.OTHER.IP_ADDRESSES.SHOW_ALL_CONNECTED_SITE_NETWORKCOMPONENTS,
				element: <Connected_Site_Networkcomponents/>,
			},
			{
				path: APP_ROUTES.OTHER.TIME_SERIES.SHOW_TIME_SERIES,
				element: <Show_Time_Series/>,
			},
			{
				path: APP_ROUTES.OTHER.TIME_SERIES.IMPORT_TIME_SERIES,
				element: <Import_Time_Series/>,
			},
			{
				path: APP_ROUTES.OTHER.TYPES_REGISTER_CHANGE,
				element: <Types_Register_Change/>,
			},
			{
				path: EXTERNAL_ROUTES.HELP.inApp,
				loader: () => redirect(EXTERNAL_ROUTES.HELP.external),
			},
			{
				path: EXTERNAL_ROUTES.LOGIN.inApp,
				loader: () => redirect(EXTERNAL_ROUTES.LOGIN.external),
			},
			{
				path: EXTERNAL_ROUTES.LOGOUT.inApp,
				loader: () => redirect(EXTERNAL_ROUTES.LOGOUT.external),
			},
			{
				path: APP_ROUTES.ERROR_404,
				element: <Error404/>,
			},
			{
				path: '*',
				element: <Navigate to={APP_ROUTES.ERROR_404}/>,
			}
		]
	},
]);
