import { ExtClock } from '../../../redux/ExtClock/extClock.types';
import HorizontalTable from '../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../components/Table/Table.types';
import MuiPaper from '../../../components/Paper/Paper';
import React from 'react';
import { momentPrettyFormat } from '../../../service/moment';
import { paperStyles } from '../../../components/Paper/Paper.styles';

const ExtClockDetails = (extClock: ExtClock): JSX.Element => {
	const getContent = (extClockData: ExtClock): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = extClockData.id !== 0;

		contentList.push(
			{
				title: 'ID:',
				body: isPresent ? extClockData.id : '',
			},
			{
				title: 'External clock type:',
				body: isPresent ? extClockData.extClockType.extClockTypeName : '',
			},
			{
				title: 'Site:',
				/**
				 * TODO: Does not have a relation to Site yet. Use SiteMinimal? - Display Site.siteName
				 */
				body: isPresent ? extClockData.site?.siteName : '',
			},
			{
				title: 'Registered:',
				body: isPresent ? momentPrettyFormat(extClockData.timestampRegistered) : '',
			},
			{
				title: 'Serial number:',
				body: isPresent ? extClockData.serialNumber : '',
			},
			{
				title: 'Frequency MHZ:',
				body: isPresent ? extClockData.frequencyMHZ : '',
			},
			{
				title: 'Ip address:',
				body: isPresent ? extClockData.ipAddress : '',
			},
			{
				title: 'Exist:',
				body: isPresent ? extClockData.exist === 1 ? 'Yes' : 'No' : '',
			},
			{
				title: 'Equipment label:',
				body: isPresent ? extClockData.equipmentLabel : '',
			},
		);

		return contentList;
	};

	return (
		<MuiPaper style={extClock.id === 0 ? paperStyles.error : {}} title={'External clock'}>
			<HorizontalTable content={getContent(extClock)} />
		</MuiPaper >
	);
};

export default ExtClockDetails;
