import MuiChip from './Chip';
import { OperStatChipPropsType } from './Chip.types';
import React from 'react';

const OperStatChip: React.FC<OperStatChipPropsType> = (props: OperStatChipPropsType) => {
	return (
		<MuiChip
			{...props}
			label={props.type}
			type={
				props.type === 'Closed' ? 'locked'
					: props.type === 'Temporary out of order' ? 'error'
						: props.type === ('Planned' || 'Explored') ? 'warning'
							: props.type === ('Operational' || 'International') ? 'primary'
								: 'locked'
			}
		/>
	);
};

export default OperStatChip;
