import { AddExtClockTypeType, ExtClockType } from './extClockType.types';
import { extClockTypeListMock, extClockTypeMock } from './extClockType.mock';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { extClockTypeSlice } from './extClockType.slice';
import { getEnvStateData } from '../../service/envStateData';

export const { setExtClockTypeList, addExtClockType, updateExtClockType } = extClockTypeSlice.actions;

/**
 * Updates the store with all extClockTypes obtained from the api
 */
export const fetchExtClockTypeList = () => {
	return async (dispatch: ApplicationDispatch) => {
		const extClockTypeList: ExtClockType[] | null =
			await getEnvStateData(getRequest<ExtClockType[]>(API_ENDPOINTS.GET_EXTCLOCKTYPES), extClockTypeListMock);

		const result = extClockTypeList && { extClockTypeList };

		return dispatch(setExtClockTypeList(result));
	};
};

/**
 * Adds an ext clock type with the api and updates the store with the newly obtained ext clock type
 */
export const addExtClockTypeElement = ({ extClockTypeName }: AddExtClockTypeType) => {
	return async (dispatch: ApplicationDispatch) => {
		const extClockType: ExtClockType | null =
			await getEnvStateData(
				postRequest<ExtClockType, AddExtClockTypeType>(
					API_ENDPOINTS.POST_EXTCLOCKTYPE,
					{ extClockTypeName }
				),
				extClockTypeMock,
			);

		return dispatch(addExtClockType(extClockType));
	};
};

/**
 * Updates an existing ext clock type with the api and updates the store with the updated ext clock type
 */
export const updateExtClockTypeElement = (oldExtClockType: ExtClockType) => {
	return async (dispatch: ApplicationDispatch) => {
		const newExtClockType: ExtClockType | null =
			await getEnvStateData(
				putRequest<ExtClockType, ExtClockType>(
					API_ENDPOINTS.PUT_EXTCLOCKTYPE,
					oldExtClockType,
				),
				extClockTypeMock,
			);

		return dispatch(updateExtClockType(newExtClockType));
	};
};
