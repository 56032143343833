import CustomTypography from './CustomTypography';
import React from 'react';
import { TypographyPropsType } from './Typography.types';
import { globalStyles } from '../../service/styles/global.styles';

const SmallTitle: React.FC<TypographyPropsType> = (props: TypographyPropsType) => {
	return (
		<CustomTypography
			variant={globalStyles.typography.smallTitle}
			className={[props.className].join(' ')}
			style={{
				fontWeight: 'bold',
				...props.style,
			}}
			{...props}
			sx={[
				...Array.isArray(props.sx) ? props.sx : [props.sx]
			]}
		/>
	);
};

export default SmallTitle;
