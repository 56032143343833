import { AddAntRefPointTypeType, AntRefPointType } from './antRefPointType.types';
import { antRefPointTypeListMock, antRefPointTypeMock } from './antRefPointType.mock';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { antRefPointTypeSlice } from './antRefPointType.slice';
import { getEnvStateData } from '../../service/envStateData';

export const { setAntRefPointTypeList, addAntRefPointType, updateAntRefPointType } = antRefPointTypeSlice.actions;

/**
 * Updates the store with all antRefPointTypes obtained from the api
 */
export const fetchAntRefPointTypeList = () => {
	return async (dispatch: ApplicationDispatch) => {
		const antRefPointTypeList: AntRefPointType[] | null =
			await getEnvStateData(getRequest<AntRefPointType[]>(API_ENDPOINTS.GET_ANTREFPOINTTYPES), antRefPointTypeListMock);

		const result = antRefPointTypeList && { antRefPointTypeList };

		return dispatch(setAntRefPointTypeList(result));
	};
};

/**
 * Adds an antRefPointType with the api and updates the store with the newly obtained antRefPointType
 */
export const addAntRefPointTypeElement = ({ antennaRefPointTypeName }: AddAntRefPointTypeType) => {
	return async (dispatch: ApplicationDispatch) => {
		const antRefPointType: AntRefPointType | null =
			await getEnvStateData(
				postRequest<AntRefPointType, AddAntRefPointTypeType>(
					API_ENDPOINTS.POST_ANTREFPOINTTYPE,
					{ antennaRefPointTypeName }
				),
				antRefPointTypeMock,
			);

		return dispatch(addAntRefPointType(antRefPointType));
	};
};

/**
 * Updates an existing antRefPointType with the api and updates the store with the updated antRefPointType
 */
export const updateAntRefPointTypeElement = (oldAntRefPointType: AntRefPointType) => {
	return async (dispatch: ApplicationDispatch) => {
		const newAntRefPointType: AntRefPointType | null =
			await getEnvStateData(
				putRequest<AntRefPointType, AntRefPointType>(
					API_ENDPOINTS.PUT_ANTREFPOINTTYPE,
					oldAntRefPointType,
				),
				antRefPointTypeMock,
			);

		return dispatch(updateAntRefPointType(newAntRefPointType));
	};
};
