import Grid from '@mui/material/Grid';
import HorizontalTable from '../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../components/Table/Table.types';
import { MarkRefPoint } from '../../redux/MarkRefPoint/markRefPoint.types';
import MuiPaper from '../../components/Paper/Paper';
import React from 'react';
import { momentPrettyFormat } from '../../service/moment';
import { paperStyles } from '../../components/Paper/Paper.styles';

const MarkRefPointDetails = (markRefPoint: MarkRefPoint): JSX.Element => {
	const getKeyInfo = (markRefPointData: MarkRefPoint): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = markRefPoint.id !== 0;

		contentList.push(
			{
				title: 'ID:',
				body: isPresent ? markRefPointData.id : '',
			},
			{
				title: 'Registered:',
				body: isPresent ? momentPrettyFormat(markRefPointData.timestampRegistered) : '',
			},
			{
				title: 'Description:',
				body: isPresent ? markRefPointData.markerDescription : '',
			},
			{
				title: 'Solution counter:',
				body: isPresent ? markRefPointData.solutionCounter : '',
			},
			{
				title: 'Solution desc.:',
				body: isPresent ? markRefPointData.solutionDescription : '',
			},
			{
				title: 'Solution flag:',
				body: isPresent ? markRefPointData.solutionFlag?.solutionFlagName : '',
			},
		);

		return contentList;
	};

	const getPosRefCoordinates = (markRefPointData: MarkRefPoint): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = markRefPointData.referenceSystemPosRef !== null;

		contentList.push(
			{
				title: 'Ref. system:',
				body: isPresent ? markRefPointData.referenceSystemPosRef?.referenceSystemName : ''
			},
			{
				title: 'X:',
				body: isPresent
					? markRefPointData.posXRef + ' ( +/- ' + (markRefPointData.sigmaPosXRef
						? markRefPointData.sigmaPosXRef + ')' : '0 )')
					: ''
			},
			{
				title: 'Y:',
				body: isPresent
					? markRefPointData.posYRef + ' ( +/- ' + (markRefPointData.sigmaPosYRef
						? markRefPointData.sigmaPosYRef + ')' : '0 )')
					: ''
			},
			{
				title: 'Z:',
				body: isPresent
					? markRefPointData.posZRef + ' ( +/- ' + (markRefPointData.sigmaPosZRef
						? markRefPointData.sigmaPosZRef + ')' : '0 )')
					: ''
			},
			{
				title: 'Epoch:',
				body: isPresent ? momentPrettyFormat(markRefPointData.epochPosRef) : '',
			},
		);

		return contentList;
	};

	const getPosCoordinates = (markRefPointData: MarkRefPoint): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = markRefPointData.referenceSystemPos !== null;

		contentList.push(
			{
				title: 'Ref. system:',
				body: isPresent ? markRefPointData.referenceSystemPos?.referenceSystemName : '',
			},
			{
				title: 'X:',
				body: isPresent
					? markRefPointData.posX + ' ( +/- ' + (markRefPointData.sigmaPosXRef
						? markRefPointData.sigmaPosXRef + ')' : '0 )')
					: ''
			},
			{
				title: 'Y:',
				body: isPresent
					? markRefPointData.posY + ' ( +/- ' + (markRefPointData.sigmaPosY
						? markRefPointData.sigmaPosY + ')' : '0 )')
					: ''
			},
			{
				title: 'Z:',
				body: isPresent
					? markRefPointData.posZ + ' ( +/- ' + (markRefPointData.sigmaPosZ
						? markRefPointData.sigmaPosZ + ')' : '0 )')
					: ''
			},
			{
				title: 'Epoch:',
				body: isPresent ? momentPrettyFormat(markRefPointData.epochPos) : '',
			},
		);

		return contentList;
	};

	const getVelCoordinates = (markRefPointData: MarkRefPoint): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = markRefPointData.velocityModel !== null;

		contentList.push(
			{
				title: 'Velocity model:',
				body: isPresent ? markRefPointData.velocityModel?.velocityModelName : '',
			},
			{
				title: 'X:',
				body: isPresent
					? markRefPointData.velX + ' ( +/- ' + (markRefPointData.sigmaVelX
						? markRefPointData.sigmaVelX + ')' : '0 )')
					: ''
			},
			{
				title: 'Y:',
				body: isPresent
					? markRefPointData.velY + ' ( +/- ' + (markRefPointData.sigmaVelY
						? markRefPointData.sigmaVelY + ')' : '0 )')
					: ''
			},
			{
				title: 'Z:',
				body: isPresent
					? markRefPointData.velZ + ' ( +/- ' + (markRefPointData.sigmaVelZ
						? markRefPointData.sigmaVelZ + ')' : '0 )')
					: ''
			},
		);

		return contentList;
	};

	const missingData = markRefPoint.id === 0;

	return (
		<MuiPaper style={missingData ? paperStyles.error : {}} title={'Coordinates'} elevation={3}>
			<Grid container spacing={3} display={'flex'} justifyContent={'center'}>

				<Grid item xs={11.5}>
					<MuiPaper style={missingData ? paperStyles.error : {}} elevation={0}>
						<HorizontalTable content={getKeyInfo(markRefPoint)} />
					</MuiPaper >
				</Grid>

				<Grid item xs={12} xl={4}>
					<MuiPaper style={missingData ? paperStyles.error : {}} title={'Coordinate 1'} elevation={0}>
						<HorizontalTable content={getPosRefCoordinates(markRefPoint)} />
					</MuiPaper >
				</Grid>

				<Grid item xs={12} xl={4}>
					<MuiPaper style={missingData ? paperStyles.error : {}} title={'Coordinate 2'} elevation={0}>
						<HorizontalTable content={getPosCoordinates(markRefPoint)} />
					</MuiPaper >
				</Grid>

				<Grid item xs={12} xl={4}>
					<MuiPaper style={missingData ? paperStyles.error : {}} title={'Velocity'} elevation={0}>
						<HorizontalTable content={getVelCoordinates(markRefPoint)} />
					</MuiPaper >
				</Grid>

			</Grid>
		</MuiPaper>
	);
};

export default MarkRefPointDetails;
