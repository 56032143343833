import { Antenna } from '../../../redux/Antenna/antenna.types';
import HorizontalTable from '../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../components/Table/Table.types';
import { Link } from 'react-router-dom';
import MuiPaper from '../../../components/Paper/Paper';
import React from 'react';
import { momentPrettyFormat } from '../../../service/moment';
import { paperStyles } from '../../../components/Paper/Paper.styles';

const AntennaDetails = (antenna: Antenna): JSX.Element => {
	const getContent = (antennaData: Antenna): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = antennaData.id !== 0;

		contentList.push(
			{
				title: 'ID:',
				body: isPresent
					? <Link to={`/antennas/${encodeURIComponent(`${antennaData.id}`)}`}>{antennaData.id}</Link>
					: '',
			},
			{
				title: 'Serial number:',
				body: isPresent ? antennaData.serialNumber : '',
			},
			{
				title: 'Site:',
				body: isPresent ? antennaData.site?.siteName : '',
			},
			{
				title: 'Site config fourchar id(s):',
				body: isPresent ? antennaData.fourCharIds.join(', ') : '',
			},
			{
				title: 'Antenna calibration id(s):',
				body: isPresent ? antennaData.antCalibrationIds.join(', ') : '',
			},
			{
				title: 'Antenna type:',
				body: isPresent ? antennaData.antennaType.antennaTypeName : '',
			},
			{
				title: 'Antenna reference point type:',
				body: isPresent ? antennaData.antRefPointType.antennaRefPointTypeName : '',
			},
			{
				title: 'Radome:',
				body: isPresent
					? <Link to={`/radomes/${encodeURIComponent(`${antennaData.radome?.id}`)}`}>{antennaData.radome?.radomeType.radomeTypeName}</Link>
					: '',
			},
			{
				title: 'Registered:',
				body: isPresent ? momentPrettyFormat(antennaData.timestampRegistered) : '',
			},
			{
				title: 'Guarantee:',
				body: isPresent && antennaData.timestampGuarantee !== null ? momentPrettyFormat(antennaData.timestampGuarantee) : '',
			},
			{
				title: 'Discarded / Defective:',
				body: isPresent
					? antennaData.exist === 0 ? 'Yes' : 'No'
					: ''
			},
			{
				title: 'Additional information:',
				body: isPresent ? antennaData.additionalInformation : '',
			},
			{
				title: 'Equipment label:',
				body: isPresent ? antennaData.equipmentLabel : '',
			},
		);

		return contentList;

	};

	return (
		<MuiPaper style={antenna.id === 0 ? paperStyles.error : {}} title={'Antenna'}>
			<HorizontalTable content={getContent(antenna)} />
		</MuiPaper >
	);
};

export default AntennaDetails;
