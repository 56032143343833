import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DatetimeFormat, momentFormat } from '../../service/moment';
import React, { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { CustomPagination } from '../../components/Pagination/CustomPagination';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import MuiPaper from '../../components/Paper/Paper';
import { SiteConfigSummaryType } from '../../redux/SiteConfig/siteConfig.types';
import Stack from '@mui/material/Stack';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { fetchAsDataUri } from '../../service/fetchAsDataUri';

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID',
		flex: 1,
		hide: true,
	},
	{
		field: 'fourCharId',
		headerName: 'Fourcharid',
		flex: 1,
		// valueGetter: (params) => params.row.id,
		renderCell: params =>
			<Link to={`/siteconfigs/${encodeURIComponent(params?.row?.id)}/details`}>{params?.row?.fourCharId}</Link>,
	},
	{
		field: 'sourceId',
		headerName: 'Source id',
		flex: 1,
	},
	{
		field: 'site',
		headerName: 'Site',
		flex: 2,
		renderCell: params =>
			<Link to={`/dashboard/${encodeURIComponent(params?.value?.id)}/siteinfo`}>{params?.value?.siteName}</Link>,
		sortComparator: (v1, v2) => v1?.siteName?.localeCompare(v2?.siteName),
	},
	{
		field: 'siteConfigStatTypeName',
		headerName: 'Status',
		flex: 2,
	},
	{
		field: 'timestampRegistered',
		headerName: 'Registered',
		flex: 2,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{
		field: 'timestampEstablished',
		headerName: 'Established',
		flex: 2,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{
		field: 'timestampOperational',
		headerName: 'Operational',
		flex: 2,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{
		field: 'countryName',
		headerName: 'Country',
		flex: 2,
		valueGetter: (params) => params.row.site.countryName,
	},
	{
		field: 'agencyName',
		headerName: 'Owned by',
		flex: 2,
		valueGetter: (params) => params.row.site.agencyName,
	},
	{
		field: 'receiver',
		headerName: 'Receiver',
		flex: 3,
		renderCell: params =>
			<Link to={`/receivers/${encodeURIComponent(params?.value?.id)}`}>{params?.value?.receiverTypeName}</Link>,
		sortComparator: (v1, v2) => v1?.receiverTypeName?.localeCompare(v2?.receiverTypeName),
	},
	{
		field: 'antenna',
		headerName: 'Antenna',
		flex: 3,
		renderCell: params =>
			<Link to={`/antennas/${encodeURIComponent(params?.value?.id)}`}>{params?.value?.antennaTypeName}</Link>,
		sortComparator: (v1, v2) => v1?.antennaTypeName?.localeCompare(v2?.antennaTypeName),
	},
	{
		field: 'cpos',
		headerName: 'In CPOS',
		flex: 1,
		valueGetter: (params) => params.row.dataFlow & 0x0001 ? 'YES' : 'NO',
	},
	{
		field: 'rinex2',
		headerName: 'Rinex-2',
		flex: 1,
		valueGetter: (params) => params.row.dataFlow & 0x0002 ? 'YES' : 'NO',
	},
	{
		field: 'rinex3',
		headerName: 'Rinex-3',
		flex: 1,
		valueGetter: (params) => params.row.dataFlow & 0x0004 ? 'YES' : 'NO',
	},
	{
		field: 'storageLocationPath',
		headerName: 'File storage',
		flex: 2,
	},
];

const SiteConfigs: React.FC = () => {
	const [siteConfigs, setSiteConfigs] = useState<SiteConfigSummaryType[]>([]);

	useEffect(() => {
		const effect = async () => {
			const response = await fetch(API_ENDPOINTS.GET_SITECONFIG_SUMMARIES);
			const data = await response.json();

			setSiteConfigs(data);
		};

		effect();
	}, []);

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1>SiteConfigs</h1>
					<Stack spacing={2}>
						<div>
							<ButtonGroup variant='outlined'>
								<Button
									startIcon={<FolderZipIcon />}
									onClick={async () => {
										window.location.href = await fetchAsDataUri(API_ENDPOINTS.GET_SITECONFIGS_EXCEL);
									}}>
									Export to Excel
								</Button>
								<Button
									startIcon={<TextSnippetIcon />}
									onClick={async () => {
										window.location.href = await fetchAsDataUri(API_ENDPOINTS.GET_SITECONFIGS_CSV);
									}}>
									Export to CSV
								</Button>

							</ButtonGroup>
						</div>
						<Box height={800}>
							<DataGrid
								rows={siteConfigs}
								columns={columns}
								sx={{
									'& .MuiDataGrid-columnHeader': {
										color: 'white',
										backgroundColor: 'primary.dark',
										'& .MuiDataGrid-columnHeaderTitle': {
											fontWeight: 'bold',
										}
									},
									'& .MuiIconButton-root': {
										color: 'white',
									},
									'& .MuiDataGrid-cell': {
										padding: '10px',
									},
									'& .MuiDataGrid-cell:focus': {
										outline: 'initial',
									},
								}}
								getRowHeight={() => 'auto'}
								disableSelectionOnClick={true}
								components={{
									Pagination: CustomPagination,
								}} />
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default SiteConfigs;
