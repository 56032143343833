import React, { useEffect } from 'react';

import { APP_ROUTES } from '../../service/routes/AppRouter.constants';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { dashboardStyles } from '../Dashboard/Dashboard.styles';
import { useNavigate } from 'react-router-dom';

const Root: React.FC = () => {
	const classes = dashboardStyles;

	const navigate = useNavigate();

	useEffect(() => {
		navigate(APP_ROUTES.DASHBOARD.INDEX);
	});

	return (
		<Box sx={ classes.root }>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1>SeStasjon</h1>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Root;
