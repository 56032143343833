import React from 'react';

const Interferens = (): JSX.Element => {
	return (
		<>
			<h1>Interference</h1>
		</>
	);
};

export default Interferens;
