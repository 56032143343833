import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ApplicationStateType } from '../store';
import { Site } from './site.types';

const initialState = null as Site | null;

export const siteDataSlice = createSlice({
	name: 'siteData',
	initialState,
	reducers: {
		setSiteData: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return { ...state, ...action.payload };
		},
		resetSiteData: () => null,
	},
});

export const selectSiteData = (state: ApplicationStateType) => state.siteDataState;

export const selectSiteDataId = (state: ApplicationStateType) => state.siteDataState?.id || null;

export default siteDataSlice.reducer;
