import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { ApplicationStateType } from '../store';

import { LoadingType } from './loading.types';

const initialState = {
	status: false,
} as LoadingType;

export const loadingSlice = createSlice({
	name: 'loading',
	initialState,
	reducers: {
		setLoadingStatus: (state, action: PayloadAction<boolean>) => ({
			status: action.payload,
		}),
	},
});

export const selectLoadingStatus = (state: ApplicationStateType) => state.loadingState.status;

export default loadingSlice.reducer;
