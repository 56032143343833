import { LocalTie, localTieTemplate } from '../LocalTie/localTie.types';
import { ReferenceSystem, referenceSystemTemplate } from '../ReferenceSystem/referenceSystem.types';
import { SolutionFlag, solutionFlagTemplate } from '../SolutionFlag/solutionFlag.types';
import { VelocityModel, velocityModelTemplate } from '../VelocityModel/velocityModel.types';

export interface MarkRefPoint {
	id: number;
	markerDescription: string | null;
	timestampRegistered: string;
	timestampRemoved: string | null;
	solutionFlag: SolutionFlag;
	solutionCounter: number | null;
	solutionDescription: string | null;
	referenceSystemPos: ReferenceSystem;
	epochPos: string;
	posX: number;
	posY: number;
	posZ: number;
	sigmaPosX: number;
	sigmaPosY: number;
	sigmaPosZ: number;
	referenceSystemPosRef: ReferenceSystem;
	epochPosRef: string;
	posXRef: number;
	posYRef: number;
	posZRef: number;
	sigmaPosXRef: number;
	sigmaPosYRef: number;
	sigmaPosZRef: number;
	velocityModel: VelocityModel;
	velX: number;
	velY: number;
	velZ: number;
	sigmaVelX: number;
	sigmaVelY: number;
	sigmaVelZ: number;
	localTies: LocalTie[];
}

export interface MarkRefPointCreateType {
	posXRef: number;
	posYRef: number;
	posZRef: number;
	sigmaPosXRef: number;
	sigmaPosYRef: number;
	sigmaPosZRef: number;
	posX: number;
	posY: number;
	posZ: number;
	sigmaPosX: number;
	sigmaPosY: number;
	sigmaPosZ: number;
	velX: number;
	velY: number;
	velZ: number;
	sigmaVelX: number;
	sigmaVelY: number;
	sigmaVelZ: number;
	solutionFlagName: string;
	solutionDescription: string;
	markerDescription: string;
	referenceSystemPosName: string;
	referenceSystemPosRefName: string;
	velocityModelName: string;
	solutionCounter: number;
	epochPos: string;
	epochPosRef: string;
}

export const markRefPointTemplate: MarkRefPoint = {
	id: 0,
	markerDescription: '',
	timestampRegistered: '',
	timestampRemoved: '',
	solutionFlag: solutionFlagTemplate,
	solutionCounter: 0,
	solutionDescription: '',
	referenceSystemPos: referenceSystemTemplate,
	epochPos: '',
	posX: 0,
	posY: 0,
	posZ: 0,
	sigmaPosX: 0,
	sigmaPosY: 0,
	sigmaPosZ: 0,
	referenceSystemPosRef: referenceSystemTemplate,
	epochPosRef: '',
	posXRef: 0,
	posYRef: 0,
	posZRef: 0,
	sigmaPosXRef: 0,
	sigmaPosYRef: 0,
	sigmaPosZRef: 0,
	velocityModel: velocityModelTemplate,
	velX: 0,
	velY: 0,
	velZ: 0,
	sigmaVelX: 0,
	sigmaVelY: 0,
	sigmaVelZ: 0,
	localTies: [localTieTemplate],
};
