import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatetimeFormat, momentFormat } from '../../../service/moment';
import React, { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import Box from '@mui/material/Box';
import { CustomPagination } from '../../../components/Pagination/CustomPagination';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import MuiPaper from '../../../components/Paper/Paper';
import { Radome } from '../../../redux/Radome/radome.types';
import Stack from '@mui/material/Stack';

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID',
		renderCell: params =>
			<Link to={`/radomes/${encodeURIComponent(params.value)}`}>{params.value}</Link>,
	},

	{
		field: 'radomeType',
		headerName: 'Type',
		flex: 1,
		valueGetter: (params) => params.row.radomeType.radomeTypeName,
	},

	{
		field: 'timestampRegistered',
		headerName: 'Registered',
		flex: 1,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{ field: 'serialNumber', headerName: 'Serial number', flex: 1 },
	{ field: 'exist', headerName: 'Exist' },
	{ field: 'additionalInformation', headerName: 'Additional information', flex: 4 },
];

const Radomes: React.FC = () => {
	const [radomes, setRadomes] = useState<Radome[]>([]);

	useEffect(() => {
		const effect = async () => {
			const response = await fetch(API_ENDPOINTS.GET_RADOMES);
			const data = await response.json();

			setRadomes(data);
		};

		effect();
	}, []);

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1>Radomes</h1>
					<Stack spacing={2}>
						<Box height={800}>
							<DataGrid
								rows={radomes}
								columns={columns}
								sx={{
									'& .MuiDataGrid-columnHeader': {
										color: 'white',
										backgroundColor: 'primary.dark',
										'& .MuiDataGrid-columnHeaderTitle': {
											fontWeight: 'bold',
										}
									},
									'& .MuiIconButton-root': {
										color: 'white',
									},
									'& .MuiDataGrid-cell': {
										padding: '10px',
									},
									'& .MuiDataGrid-cell:focus': {
										outline: 'initial',
									},
								}}
								getRowHeight={() => 'auto'}
								disableSelectionOnClick={true}
								components={{
									Pagination: CustomPagination,
								}} />
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default Radomes;
