import { globalStyles, theme } from '../../service/styles/global.styles';

export const tableStyles = {
	root: {
		borderRadius: globalStyles.borderRadius,
	},
	headCell: {
		backgroundColor: theme.palette.primary.dark,
		color: 'white',
		fontWeight: 'bold',
	},
	editModeRow: {
		backgroundColor: globalStyles.colors.grey.main,
	},
	editModeTextField: {
		textAlign: 'center',
	},
	horizontalHeadCell: {
		fontWeight: 'bold',
		width: '60%'
	},
	horizontalBodyCell: {
		textAlign: 'left',
		width: '50%'
	},
	deleteIcon: {
		marginLeft: '50px',
		color: theme.palette.error.light,
	},
	addIcon: {
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
		}
	},
	dialog: {
		padding: '20px',
	},
};
