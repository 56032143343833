import React, { useEffect } from 'react';
import { fetchSiteConfig, resetSiteConfigState } from '../../../../redux/SiteConfig/siteConfig.actions';
import { generatePath, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { APP_ROUTES } from '../../../../service/routes/AppRouter.constants';
import Loader from '../../../../components/Loader/Loader';
import RouteTabs from '../../../../components/RouteTabs/RouteTabs';

// Menu level 4 with detailed information for selected siteconfig and coordinate history
export const SiteConfigInfo = (): JSX.Element => {
	const {
		siteId = '0',
		siteConfigId = '0'
	} = useParams<{ siteId: string, siteConfigId: string }>();

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchSiteConfig({ siteConfigId: Number.parseInt(siteConfigId) }));

		return () => {
			dispatch(resetSiteConfigState());
		};
	}, [dispatch, siteConfigId]);

	if (siteId === undefined || siteConfigId === undefined) {
		return <Loader/>;
	}

	return <RouteTabs routes={[
		{
			url: generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.DETAILS, {
				siteId,
				siteConfigId
			}),
			id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.DETAILS,
			label: 'Details',
		},
		{
			url: generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.RECEIVER_HISTORY, {
				siteId,
				siteConfigId
			}),
			id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.RECEIVER_HISTORY,
			label: 'Receiver History',
		},
		{
			url: generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.ANTENNA_HISTORY, {
				siteId,
				siteConfigId
			}),
			id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.ANTENNA_HISTORY,
			label: 'Antenna history',
		},
		{
			url: generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.MARKREFPOINT_HISTORY, {
				siteId,
				siteConfigId
			}),
			id: APP_ROUTES.DASHBOARD.SITECONFIGINFO.MARKREFPOINT_HISTORY,
			label: 'Coordinate History',
		},
	]}/>;
};
