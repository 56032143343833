import { AddTagParamType, AddTagType, TagListType } from './tag.types';
import { ApplicationDispatch, ApplicationGetState } from '../store';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { fetchSiteDataById } from '../Site/siteData.actions';
import { getEnvStateData } from '../../service/envStateData';
import { selectSiteDataId } from '../Site/siteData.slice';
import { tagListMock } from './tag.mock';
import { tagListSlice } from './tag.slice';

export const { setTagList, resetTagList } = tagListSlice.actions;

export const fetchTagList = () => {
	return async (dispatch: ApplicationDispatch) => {
		const tagList = await getEnvStateData(getRequest<TagListType>(API_ENDPOINTS.GET_TAGS), tagListMock);

		return dispatch(setTagList(tagList));
	};
};

export const putTagElement = (tagParam: any) => {
	return async (dispatch: ApplicationDispatch) => {
		await putRequest(API_ENDPOINTS.PUT_TAG, { id: tagParam.ID, tagText: tagParam.Tag });

		return dispatch(fetchTagList());
	};
};

export const deleteTagElement = (tagParam: any) => {
	return async (dispatch: ApplicationDispatch) => {
		await deleteRequest(API_ENDPOINTS.DELETE_TAG + '/' + tagParam.ID);

		return dispatch(fetchTagList());
	};
};

export const addTagElement = (tagParam: AddTagParamType) => {
	return async (dispatch: ApplicationDispatch) => {
		const newTag: AddTagType = {
			...tagParam,
		};

		await postRequest(API_ENDPOINTS.POST_TAG, newTag);

		return dispatch(fetchTagList());
	};
};

// This is an update(PUT) to add a linked tag on a siteconfig
export const putNewTagElementOnSiteconfig = (tagParam: any, fourCharIdParam: string) => {
	return async (dispatch: ApplicationDispatch, getState: ApplicationGetState) => {
		const urlString = fourCharIdParam;

		// console.log('putNewTagElementOnSiteconfig. urlString: ' + urlString);

		// PUT_SITECONFIG_ADD_TAG: `${base}/siteconfig-add-tag/{fourCharId}/{tagId}`,
		await putRequest(API_ENDPOINTS.PUT_SITECONFIG_ADD_TAG + '/' + urlString, null);

		// Reload sitedata after adding new tag:
		const currentSiteDataId = selectSiteDataId(getState());

		if (currentSiteDataId !== null) {
			await dispatch(fetchSiteDataById(currentSiteDataId));
		}
	};
};

// Just a dummy function to display the delete-button in TagInfoCard
export const putNothing = () => async () => {
	// Can not be empty so here is a comment
};
