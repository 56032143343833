/**
 * Creates a data uri from the result of fetching the provided url.
 *
 * Note: This is done because the proxy used in the dev server doesn't play nice when accessing
 * api links directly (try in browser e.g. 'http://localhost:3000/precision/receivers' and see an error page)
 */
export const fetchAsDataUri = async (uri: string) => {
	const response = await fetch(uri);
	const data = await response.blob();
	const reader = new FileReader();

	const b64: string | ArrayBuffer | null = await new Promise(resolve => {
		reader.readAsDataURL(data);
		reader.onloadend = () => resolve(reader.result);
	});

	return `${b64}`;
};
