import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ApplicationStateType } from '../store';
import { InfDocType } from './infdoc.types';

const initialState = null as InfDocType | null;

export const infDocSlice = createSlice({
	name: 'infDoc',
	initialState,
	reducers: {
		setInfDoc: (state, action: PayloadAction<InfDocType>) =>
			state === null
				? action.payload
				: { ...state, ...action.payload },
		resetInfDoc: () => null,
	},
});

export const selectInfDoc = (state: ApplicationStateType) => state.infDocState;

export default infDocSlice.reducer;
