import React from 'react';

const Ekstrautstyr = (): JSX.Element => {
	return (
		<>
			<h1>Optional equipment</h1>
		</>
	);
};

export default Ekstrautstyr;
