import { Grid } from '@mui/material';
import HorizontalTable from '../../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../../components/Table/Table.types';
import { HovedinfoPropsType } from './Hovedinfo.types';
import MuiPaper from '../../../../components/Paper/Paper';
import React from 'react';
import { Site } from '../../../../redux/Site/site.types';

const Annet: React.FC<HovedinfoPropsType> = (props: HovedinfoPropsType) => {
	const networkRootContent = (siteData: Site): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [
			{
				title: 'Temperature:',
				body: siteData.tempStabilization !== null ? siteData.tempStabilization : '',
			},
			{
				title: 'Additional information:',
				body: siteData.additionalInformation !== null ? siteData.additionalInformation : '',
			},
		];

		return contentList;
	};

	return (
		<MuiPaper title={'Other'}>
			<Grid container>
				<Grid item xs={12}>
					<HorizontalTable content={networkRootContent(props.siteData)} />
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default Annet;
