import { Autocomplete, Grid, TextField } from '@mui/material';
import { FormListType, getAttributeList, validateForm } from '../HistService';
import React, { useEffect, useState } from 'react';
import {
	addTagToSiteConfig,
	fetchSiteConfig,
	removeTagFromSiteConfig
} from '../../../../../redux/SiteConfig/siteConfig.actions';
import {
	fetchTagList,
	putNothing
} from '../../../../../redux/Tag/tag.actions';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import HorizontalTable from '../../../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../../../components/Table/Table.types';
import Icon from '../../../../../components/Icon/Icon';
import MuiPaper from '../../../../../components/Paper/Paper';
import MuiTable from '../../../../../components/Table/Table/Table';
import { SiteConfig } from '../../../../../redux/SiteConfig/siteConfig.types';
import { Tag } from '../../../../../redux/Tag/tag.types';
import { regexList } from '../../../../../service/regex';
import { selectSiteData } from '../../../../../redux/Site/siteData.slice';
import { selectTagList } from '../../../../../redux/Tag/tag.slice';

const TagInfoCard = (props: SiteConfig): JSX.Element => {
	const dispatch = useAppDispatch();
	// selected Tag in Autocomplete dropdown
	const [tag, setTag] = useState();
	// The complete input parameter to the API endpoint containing fourCharId and Tag id
	const [addFuncCallbackTagId, setAddFuncCallbackTagId] = useState(0);
	const [addFuncCallbackTagText, setAddFuncCallbackTagText] = useState('');
	// Defines the mode the user is in: Edit or view tags
	const [editMode, setEditMode] = useState<boolean>(false);
	// Array with tags connected to the siteconfig
	const [tagsOnSiteconfigDataList, setTagsOnSiteconfigDataList] = useState<any[]>([]);
	// Array with all selectable tags (all tags minus tagsOnSiteconfigDataList)
	const [selectableTags, setSelectableTags] = useState<any[]>([]);

	// When not in editMode - returns data for static table view of the tags on the siteconfig
	const getContent = (): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		if (tagsOnSiteconfigDataList !== null) {
			tagsOnSiteconfigDataList.forEach((element) => {
				contentList.push({
					title: 'Tag ID, text:',
					body: element.ID + ', ' + element.Tag,
				});
			});
		}

		return contentList;
	};

	// Fetching all site data from DB using API
	const siteData = useAppSelector(selectSiteData);

	// Fetching all tags from DB using the API
	const tagData = useAppSelector(selectTagList);

	// Fields displayed in the Tag table head
	const formList: FormListType[] = [
		{
			label: 'ID',
			keyName: 'id',
			regex: regexList.freepass,
			number: true,
		},
		{
			label: 'Tag',
			keyName: 'tagText',
			regex: regexList.freepass,
			number: false,
		},
	];

	// Fetching all tags
	useEffect(() => {
		const fetchData = async () => {
			await dispatch(fetchTagList());
		};

		fetchData();

	}, [dispatch]);

	// Refresh the list of tags on the siteconfig
	useEffect(() => {
		// console.log('TagInfoCard useEffect setTagsOnSiteconfigDataList');
		const tmpList: any[] = [];

		if (siteData !== null) {
			siteData.siteConfigs.forEach((siteConfig: SiteConfig) => {
				if (siteConfig.tags !== null) {
					if (siteConfig.tags !== undefined) {
						if (siteConfig.fourCharId === props.fourCharId) {
							siteConfig.tags.forEach((siteConfigTag: Tag) => {
								const tmp = {
									'ID': siteConfigTag.id,
									'Tag': siteConfigTag.tagText,
								};

								if (tmpList.find(x => x.id === tmp.ID) === undefined) {
									tmpList.push(tmp);
								}
							});
						}
					}
				}
			});

			// Sort the array on the tag text
			tmpList.sort((a, b) => {
				if (a.Tag.toLowerCase() < b.Tag.toLowerCase()) {
					return -1;
				}
				if (a.Tag.toLowerCase() > b.Tag.toLowerCase()) {
					return 1;
				}

				return 0;
			});
		}
		setTagsOnSiteconfigDataList(tmpList);

	}, [siteData, props.fourCharId]);

	// Refresh of the list of selectable tags (all tags except tags already on siteconfig)
	useEffect(() => {
		// console.log('TagInfoCard useEffect setSelectableTags');
		const tmpList: any[] = [];

		if (tagData !== null) {
			const tagArray = Object.values(tagData);

			if (tagArray !== undefined) {
				tagArray.forEach((tagElement: Tag) => {
					if (tagElement.id !== null) {
						const tmp = {
							'ID': tagElement.id,
							'Tag': tagElement.tagText,
						};

						if (tmpList.find(x => x.id === tmp.ID) === undefined) {
							tmpList.push(tmp);
						}
					}
				});

				// Sort the array on the tag text
				tmpList.sort((a, b) => {
					if (a.Tag.toLowerCase() < b.Tag.toLowerCase()) {
						return -1;
					}
					if (a.Tag.toLowerCase() > b.Tag.toLowerCase()) {
						return 1;
					}

					return 0;

				});
			}
		}
		// Removes tags alredy registered on the siteconfig from selectableTags
		const tmpList2: any[] = tmpList.filter(x => !tagsOnSiteconfigDataList.filter(y => y.ID === x.ID).length);

		setSelectableTags(tmpList2);

	}, [tagData, tagsOnSiteconfigDataList]);

	return (
		<MuiPaper title={'Tags'}>
			{/* editMode is shown when the user wants to edit tags on a siteconfig */}
			{editMode
				? <Grid container>
					<Icon IconComponent={DoneIcon} onClick={() => setEditMode(!editMode)} hoverText={'Back'}/>
					<Grid item xs={12}>
						{
							tagsOnSiteconfigDataList.length !== undefined &&
							<>
								<MuiTable
									name={'Taglist'}
									head={getAttributeList(formList, 'label')}
									// Tags shown in table already linked with the siteconfig
									body={tagsOnSiteconfigDataList}
									addable={{
										title: 'Add tag',
										form: {
											// Not in use in this case but it's required so just have to put something
											values: tagsOnSiteconfigDataList,
											modal:
												<Grid item xs={12}>
													<Autocomplete
														id={'tag-name'}
														// options(required): Array of selectable tags in the dropdown
														options={selectableTags}
														// renderInput(required): Defines the label and variant of the dropdown
														renderInput={(params) => <TextField {...params} label={'Tag'} variant={'outlined'}/>}
														// getOptionLabel: The part of the option-object to show in the dropdown
														getOptionLabel={(tagOption) => tagOption.Tag}
														// style: Make the dropdown wide enough to display the taglabels
														style={{ width: 300 }}
														// value: The value of the autocomplete selected by the user. Has to be defaulted to null to
														// avoid errormessage: "A component is changing an uncontrolled Autocomplete to be controlled"
														value={tag || null}
														// onChange: Callback fired when the value changes. This function takes an event and an
														// option value as the arguments. We will set the selectedTag variable to be the option value.
														onChange={(_event, newTag) => {
															setTag(newTag);
															// This complete string is a parameter to addFuncCallback: putNewTagElementOnSiteconfig
															setAddFuncCallbackTagId(newTag.ID);
															setAddFuncCallbackTagText(newTag.Tag);
														}}
													/>
												</Grid>,
											validate: () => {
												const validatedState = validateForm(tag, formList);

												return validatedState.errorMsgStatus;
											},
										},
										buttonText: 'Add',
										// The parameters are sent to Table.tsx
										addFuncCallback: () => async () => {

											await addTagToSiteConfig({ fourCharId: props.fourCharId, tagId: addFuncCallbackTagId })(dispatch);
											await fetchSiteConfig({ siteConfigId: props.id })(dispatch);
											setTagsOnSiteconfigDataList([
												...tagsOnSiteconfigDataList,
												{ ID: addFuncCallbackTagId, Tag: addFuncCallbackTagText }
											]);
										},
										param: 0,
									}}
									// deleteFuncCallback={putDeleteTagElementOnSiteconfig(props.fourCharId)}
									deletable={{
										deleteFuncCallback: (selectedTag: any) => async () => {
											await removeTagFromSiteConfig({ fourCharId: props.fourCharId, tagId: selectedTag.ID })(dispatch);
											await fetchSiteConfig({ siteConfigId: props.id })(dispatch);
											setTagsOnSiteconfigDataList([
												...tagsOnSiteconfigDataList.filter(
													t => t.ID !== selectedTag.ID || t.Tag !== selectedTag.Tag
												),
											]);
										},
										param: 0,
									}}
									// editable is not required but has to be defined in order to show the delete-button
									editable={{
										editFuncCallback: putNothing,
										validation: getAttributeList(formList, 'regex'),
									}}
								/>
							</>
						}
					</Grid>
				</Grid>
				// When not in editMode, a table of tags on the siteconfig is displayed
				: <Grid container>
					<Icon IconComponent={EditIcon} onClick={() => setEditMode(!editMode)} hoverText={'Edit'}/>
					<Grid item xs={12}>
						<HorizontalTable content={getContent()}/>
					</Grid>
				</Grid>
			}
		</MuiPaper>
	);
};

export default TagInfoCard;
