import { Menu, MenuItem } from '@mui/material';
import { NestedMenuPropType, navbarElementType } from './NavBar.types';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Icon from '../Icon/Icon';
import { NavLink } from 'react-router-dom';
import { NestedMenuItem } from 'mui-nested-menu';
import React from 'react';
import { navBarStyles } from './NavBar.styles';

const NestedMenu = (props: NestedMenuPropType): JSX.Element => {
	const classes = navBarStyles;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClicky = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		if (props.setAnchor !== undefined) {
			props.setAnchor(null);
		}
	};

	// Menuitem without child menuitem
	if (props.children.length === 0) {
		return (
			<NavLink to={props.link} style={classes.link}>
				<MenuItem
					role={'button'}
					sx={
						props.depth === 0 && props.disabled === true ? classes.mainNavBtnDisabled
							: props.depth === 0 && props.disabled === undefined ? classes.mainNavBtn
								: props.depth > 0 && props.disabled === true ? classes.childNavBtnDisabled
									: classes.childNavBtn}
					onClick={handleClose}>
					{props.icon && <Icon IconComponent={props.icon} primary/>}
					{props.title}
				</MenuItem>
			</NavLink>
		);
		// Menuitem with child menuitem
	} else {
		//  2nd level menuitem with submenuitem
		if (props.depth === 0) {
			return (
				<div>
					<MenuItem
						sx={
							props.depth === 0 && props.disabled === true ? classes.mainNavBtnDisabled
								: props.depth === 0 && props.disabled === undefined ? classes.mainNavBtn
									: props.depth > 0 && props.disabled === true ? classes.childNavBtnDisabled
										: classes.childNavBtn}
						onClick={handleClicky}>
						{props.title}
						<ArrowDropDownIcon />
					</MenuItem>
					<Menu
						open={open}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						onClose={() => {
							setAnchorEl(null);
						}}
					>
						{props.children.map((submenu: navbarElementType) =>
							<NestedMenu
								key={submenu.title}
								title={submenu.title}
								link={submenu.link}
								setAnchor={() => {handleClose();}}
								depth={props.depth + 1}
								disabled={submenu.disabled}
							>
								{submenu.children}
							</NestedMenu>
						)}
					</Menu>
				</div>
			);
			// Lower level submenuitems
		} else {
			return (
				<NestedMenuItem
					label={props.title}
					parentMenuOpen={open}
					sx={
						props.depth === 0 && props.disabled === true ? classes.mainNavBtnDisabled
							: props.depth === 0 && props.disabled === undefined ? classes.mainNavBtn
								: props.depth > 0 && props.disabled === true ? classes.childNavBtnDisabled
									: classes.childNavBtn}
					onMouseEnter={handleClicky}
					onClick={handleClose}
				>
					{props.children.map((submenu: navbarElementType) =>
						<NestedMenu
							key={submenu.title}
							title={submenu.title}
							link={submenu.link}
							setAnchor={() => {handleClose();}}
							depth={props.depth + 1}
							disabled={submenu.disabled}
						>
							{submenu.children}
						</NestedMenu>
					)}
				</NestedMenuItem>
			);
		}
	}
};

export default NestedMenu;
