import { MarkAntRefPoint, markAntRefPointTemplate } from '../../redux/MarkAntRefPoint/markAntRefPoint.types';
import React, { useEffect, useRef } from 'react';
import { createSiteConfig, resetSiteConfigState } from '../../redux/SiteConfig/siteConfig.actions';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { AntennaCreate } from '../Utilities/Antennas/AntennaCreate';
import { AntennaSummaryType } from '../../redux/Antenna/antenna.types';
import { Cable } from '../../redux/Cable/cable.types';
import Container from '@mui/material/Container';
import { ExtClock } from '../../redux/ExtClock/extClock.types';
import { Form } from '../../components/Form/Form';
import { FormConfiguration } from '../../components/Form/Form.types';
import { MonumentSummary } from '../../redux/Monument/monument.types';
import MuiTabs from '../../components/Tabs/Tabs';
import PopperButton from '../../components/Popper/PopperButton';
import { ReceiverSummary } from '../../redux/Receiver/receiver.types';
import { SiteMinimal } from '../../redux/Site/site.types';
import { StorageLocation } from '../../redux/StorageLocation/storageLocation.types';
import { selectSiteConfigState } from '../../redux/SiteConfig/siteConfig.slice';
import { setAlertState } from '../../redux/Alert/alert.actions';
import { siteConfigTemplate } from '../../redux/SiteConfig/siteConfig.types';
import { store } from '../../redux/store';
import { useAppSelector } from '../../redux/hooks';
import { useForm } from 'react-hook-form';

let selectedSiteId = 0;
const dispatch = store.dispatch;

dispatch(resetSiteConfigState());

const config: Omit<FormConfiguration, 'header' | 'control' | 'onSubmit'> = {
	fields: [
		{
			type: 'text',
			name: 'fourCharId',
			label: 'Fourcharid',
			id: 'sc-fourcharid',
			helperText: '',
			required: true,
		},
		{
			type: 'autocomplete',
			name: 'site',
			label: 'Site',
			id: 'sc-site',
			helperText: '',
			required: true,
			isOptionEqualToValue: (option, value) => option.id === value.id ? selectedSiteId = value.id : 0,
			getOptionLabel: option => option?.siteName || '',
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_SITE_SUMMARIES);
				const sites: SiteMinimal[] = await res.json();

				return sites;
			},
		},
		// SITECONFIG STATUS TYPE
		// Out-commented in case of re-implementation

		// {
		// 	type: 'autocomplete',
		// 	name: 'siteConfigStatType',
		// 	label: 'Status',
		// 	id: 'sc-siteConfigStatType',
		// 	helperText: '',
		// 	required: true,
		// 	isOptionEqualToValue: (option, value) => option.id === value.id,
		// 	getOptionLabel: option =>
		// 		option?.siteConfigStatTypeName || '',
		// 	getOptions: async () => {
		// 		const res = await fetch(API_ENDPOINTS.GET_SITECONFIGSTATTYPES);
		// 		const statTypes: SiteConfigStatType[] = await res.json();
		//
		// 		return statTypes;
		// 	}
		// },
		{
			type: 'autocomplete',
			name: 'antenna',
			label: 'Antenna',
			id: 'sc-antenna',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option =>
				(`${option.antennaTypeName || ''}${option.id
					? ' - '
					: ''}${option.serialNumber}` || '') +
				(option?.site?.siteName !== ''
					? ` [ ${option?.site?.siteName || ''} ] `
					: ''),
			getOptions: async () => {
				const url = API_ENDPOINTS.GET_ANTENNAS_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const antennas: AntennaSummaryType[] = await res.json();

				return antennas;
			}
		},
		{
			type: 'autocomplete',
			name: 'receiver',
			label: 'Receiver',
			id: 'sc-receiver',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option =>
				(`${option.receiverTypeName || ''}${option.id
					? ' - '
					: ''}${option.serialNumber}` || '') +
				(option?.site?.siteName !== ''
					? ` [ ${option?.site?.siteName || ''} ] `
					: ''),
			getOptions: async () => {
				const url = API_ENDPOINTS.GET_RECEIVERS_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const receivers: ReceiverSummary[] = await res.json();

				return receivers;
			}
		},
		{
			type: 'autocomplete',
			name: 'cable',
			label: 'Cable',
			id: 'sc-cable',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option?.id === value?.id,
			getOptionLabel: option =>
				option.id !== 0
					? `${option?.cableType?.cableTypeName} - ${option?.length}m [${option?.fourCharIds}]`
					: '',
			getOptions: async () => {
				const url = API_ENDPOINTS.GET_CABLES_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const cables: Cable[] = await res.json();

				return cables;
			}
		},
		{
			type: 'autocomplete',
			name: 'monument',
			label: 'Monument',
			id: 'sc-monument',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option?.id === value?.id,
			getOptionLabel: option =>
				`${option?.monumentTypeName || ''}` +
				`${option?.id ? ' - ' : ''}` +
				`${option?.foundationTypeName || ''}` +
				`${option?.site !== null ? ' [' + option?.site?.siteName + ']' : ''}` || '',
			getOptions: async () => {

				const url = API_ENDPOINTS.GET_MONUMENTS_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const monuments: MonumentSummary[] = await res.json();

				return monuments;
			}
		},
		{
			type: 'autocomplete',
			name: 'markAntRefPoint',
			label: 'Eccentricity',
			id: 'sc-markAntRefPoint',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option?.id === value?.id,
			getOptionLabel: option =>
				option?.id !== 0
					? `(DN:${option?.deltaNorth}, DE:${option?.deltaEast}, DH:${option?.deltaHeight}) [TEMPLATE]`
					: '',
			getOptions: async () => {
				const template = markAntRefPointTemplate;

				template.id = 1;

				const markAntRefPoints: MarkAntRefPoint[] = await [template];

				return markAntRefPoints;
			}
		},
		{
			type: 'autocomplete',
			name: 'extClock',
			label: 'External clock',
			id: 'sc-extClock',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option =>
				(`${option.extClockType?.extClockTypeName || ''}${option.id
					? ' - '
					: ''}${option.serialNumber}` || '') +
				(option?.site?.siteName !== ''
					? ` [ ${option?.site?.siteName || ''} ] `
					: ''),
			getOptions: async () => {
				const url = API_ENDPOINTS.GET_EXTCLOCKS_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const extclocks: ExtClock[] = await res.json();

				return extclocks;
			}
		},
		{
			type: 'autocomplete',
			name: 'storageLocation',
			label: 'Storage location',
			id: 'sc-storageLocation',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option =>
				option?.path,
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_STORAGELOCATIONS);
				const storageLocations: StorageLocation[] = await res.json();

				return storageLocations;
			}
		},
		{
			type: 'checkbox',
			name: 'cpos',
			label: 'CPOS (to be replaced with tags)',
			id: 'sc-cpos',
		},
		{
			type: 'datetime',
			name: 'timestampEstablished',
			label: 'Established',
			id: 'sc-timestampEstablished',
			helperText: '',
			required: false,
		},
		{
			type: 'checkbox',
			name: 'rinex2',
			label: 'Rinex2 (to be repalced with tags)',
			id: 'sc-rinex2',
		},
		{
			type: 'text',
			name: 'additionalInformation',
			label: 'Additional information',
			id: 'sc-additionalInformation',
			helperText: '',
			required: false,
		},
		{
			type: 'checkbox',
			name: 'rinex3',
			label: 'Rinex3 (to be replaced with tags)',
			id: 'sc-rinex3',
		},
		{
			type: 'autocomplete',
			name: 'tags',
			label: 'Tags',
			id: 'sc-tags',
			multiple: true,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option.tagText || String(option.id),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_TAGS);
				const tags: any[] = await res.json();

				return tags;
			},
		},
	]
};

const SubmitSiteconfig = async (data: typeof siteConfigTemplate) => {
	// const dispatch = store.dispatch;

	const cposElement = document.getElementById('sc-cpos') as HTMLInputElement;
	const rinex3Element = document.getElementById('sc-rinex3') as HTMLInputElement;
	const rinex2Element = document.getElementById('sc-rinex2') as HTMLInputElement;

	let df: number = 0x0000;

	df = cposElement.checked ? df | 0x0001 : df & ~0x0001;
	df = rinex2Element.checked ? df | 0x0002 : df & ~0x0002;
	df = rinex3Element.checked ? df | 0x0004 : df & ~0x0004;

	await dispatch(createSiteConfig({ siteconfig: {
		fourCharId: data?.fourCharId || '',
		siteId: data.site?.id || 0,
		receiverId: data.receiver?.id || null,
		antennaId: data.antenna?.id || null,
		cableId: data.cable?.id || null,
		monumentId: data.monument?.id || null,
		markAntRefPointId: data.markAntRefPoint?.id || null,
		markRefPointId: data.markRefPoint?.id || null,
		extClockId: data.extClock?.id || null,
		additionalInformation: data.additionalInformation || null,
		timestampEstablished: data?.timestampEstablished || null,
		// 88624 is temporary status type
		siteconfigStatTypeId: 88624,
		dataFlow: df,
		storageLocationId: data.storageLocation?.id || null,
		tagIds: data.tags?.map(tag => tag.id) || null
	} }));

	dispatch(setAlertState({
		severity: 'success',
		open: true,
		title: 'Success',
		message: 'Successfully created siteconfig with fourcharid: ' + data.fourCharId
	}));
};

export const SiteConfigCreate: React.FC<{}> = () => {

	const siteconfig = useAppSelector(selectSiteConfigState);

	const jsonFileRef1 = useRef<HTMLInputElement>(null);

	const { control, formState, handleSubmit, reset, trigger } = useForm<typeof siteConfigTemplate>({
		resolver: undefined,
		mode: 'onChange',
		values: {
			...siteConfigTemplate,
			...siteconfig,
		},
	});

	const {
		isDirty,
		isSubmitting,
		isSubmitted,
		isSubmitSuccessful,
		isValidating,
		isValid,
	} = formState;

	const shouldValidateOnce = siteconfig !== null;

	useEffect(() => {
		if (shouldValidateOnce) {
			trigger();
		}
	}, [shouldValidateOnce, trigger]);

	useEffect(() => {
		reset();
	}, [isSubmitSuccessful, reset]);

	/*
	/* TODO: Implementation of MRP in siteconfig
	*/
	// const [selectedFile, setSelectedFile] = useState<File | null>(null);

	// Effect used to handle MarkRefPoint file
	// useEffect(() => {
	// 	const handleFileChange = () => {
	// 		const fileInput = jsonFileRef1.current;
	//
	// 		if (fileInput && fileInput.files && fileInput.files.length > 0) {
	// 			const file = fileInput.files[0];
	//
	// 			setSelectedFile(file);
	// 		} else {
	// 			setSelectedFile(null);
	// 		}
	// 	};
	//
	// 	const fileInput = jsonFileRef1.current;
	//
	// 	fileInput?.addEventListener('change', handleFileChange);
	//
	// 	return () => {
	// 		fileInput?.removeEventListener('change', handleFileChange);
	// 	};
	// }, []);

	// useEffect(() => {
	// 	if (selectedFile !== null && siteconfig !== null) {
	// 		fileToJson(selectedFile).then((json: any) => {
	// 			const test: MarkRefPoint = json;
	//
	// 			return siteconfig.markRefPoint = test;
	// 		}
	// 		);
	// 	}
	// });

	return (
		<Container maxWidth={'xl'} fixed={true}>
			<h1>Create a new siteconfig</h1>
			<Form
				control={control}
				onSubmit={handleSubmit(SubmitSiteconfig)}
				isDirty={isDirty}
				isSubmitting={isSubmitting}
				isSubmitted={isSubmitted}
				isValidating={isValidating}
				isValid={isValid}
				refs={
					{ jsonFile: jsonFileRef1 }
				}
				readOnlyValues={[
					{ label: 'Status', value: 'Temporary' },
					{ label: 'Coordinate / MarkRefPoint', value: 'Created by script' },
				]}
				{...config}
			/>

			{/* // TODO: Implementation of MRP in siteconfig
			<JSONViewer content={selectedFile} title={'Preview of coordinate'} />
			*/}

			<h2>Create missing components (PROTOTYPE 1)</h2>
			<MuiTabs name={'New Components'} tabsList={[
				{ label: 'Antenna', component: <AntennaCreate/> },
			]} />

			<h2>Create missing components (PROTOTYPE 2)</h2>
			<PopperButton title={'Create antenna'}>
				<AntennaCreate/>
			</PopperButton>

		</Container>
	);
};
