import Ant_Calib_Types from './Ant_Calib_Types';
import Ant_Ref_Point_Types from './Ant_Ref_point_types';
import Antenna_Types from './Antenna_Types';
import Countries from './Countries';
import Ext_Clock_Types from './Ext_Clock_Types';
import Radome_Types from './Radome_Types';
import Receiver_Types from './Receiver_Types';
import { TabsListType } from '../../../components/Tabs/Tabs.type';
import Tags from './Tags';

// Contains the menu elements on Misc -> Types register change
export const typesRegisterChangeTabsList: TabsListType[] = [
	{
		label: 'Antenna Types',
		component: <Antenna_Types />,
	},
	{
		label: 'Antenna Calibration Types',
		component: <Ant_Calib_Types />,
	},
	{
		label: 'Antenna Reference Point Types',
		component: <Ant_Ref_Point_Types />,
	},
	{
		label: 'Countries',
		component: <Countries />,
	},
	{
		label: 'External Clock Types',
		component: <Ext_Clock_Types />,
	},
	{
		label: 'Radome Types',
		component: <Radome_Types />,
	},
	{
		label: 'Receiver Types',
		component: <Receiver_Types />,
	},
	{
		label: 'Tags',
		component: <Tags />,
	},
];
