import React, { useState } from 'react';

import { AccordionTitleType } from './Accordion.types';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import Icon from '../Icon/Icon';
import MuiChip from '../Chip/Chip';
import SmallTitle from '../Typography/SmallTitle';
import { TextField } from '@mui/material';
import { accordionStyles } from './Accordion.styles';
import { useAppDispatch } from '../../redux/hooks';

interface AccordionTitlePropType extends AccordionTitleType {
	id: string;
}

const AccordionTitle = (props: AccordionTitlePropType): JSX.Element => {
	const classes = accordionStyles;
	const dispatch = useAppDispatch();

	const [state, setState] = useState<string>(props.value);
	const [tmpState, setTmpState] = useState<string>(props.value);
	const [error, setError] = useState<string | undefined>(undefined);
	const [editMode, setEditMode] = useState<boolean>(false);

	const handleEditDone = async (): Promise<void> => {
		setState(state);
		if (props.editable !== undefined) {
			if (!props.editable.validation.expression.test(state)) {
				setError(props.editable.validation.errorMsg);

				return;
			} else {
				setError(undefined);
			}
			setTmpState(state);
			await dispatch(props.editable.editFuncCallback(state, props.editable.param));
		}
		await setEditMode(false);
	};

	const handleEditCancel = (): void => {
		setState(tmpState);
		setError(undefined);
		setEditMode(false);
	};

	return (
		<>
			{
				props.badge === true
					? <Box sx={classes.accordionTitle}>
						<MuiChip
							type={'primary'}
							sx={classes.accordionBadge}
							label={props.label}
						/>
					</Box>
					: <>
						{
							props.editable !== undefined
								? <Box sx={classes.accordionTitle}>
									<SmallTitle sx={classes.accordionLabel} text={props.label} />{
										editMode
											? <Box sx={classes.editableTitle}>
												<TextField
													variant={'outlined'}
													label={props.editable.validation.formatDisplay}
													error={Boolean(error)}
													helperText={error}
													inputProps={{ sx: classes.editModeTextField }}
													onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
														setState(event.target.value)
													}
													value={state}
												/>
												<Icon IconComponent={DoneIcon} primary size='small' onClick={handleEditDone} hoverText={'Lagre'}/>
												<Icon IconComponent={CloseIcon} error size='small' onClick={handleEditCancel} hoverText={'Avbryt'}/>
											</Box>
											: <Box sx={classes.editableTitle}>
												{state}
												<Icon
													IconComponent={EditIcon}
													size='small'
													onClick={() => setEditMode(!editMode)}
													hoverText={'Edit'}/>
											</Box>
									}
								</Box>

								: <Box sx={classes.accordionTitle}>
									<SmallTitle sx={classes.accordionLabel} text={props.label} />
									{state}
								</Box>
						}
					</>
			}
		</>
	);
};

export default AccordionTitle;
