import { HorizontalTableContentType, HorizontalTablePropsType } from '../Table.types';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

import React from 'react';
import { tableStyles } from '../Table.styles';

const HorizontalTable: React.FC<HorizontalTablePropsType> = (props: HorizontalTablePropsType) => {
	const classes = tableStyles;

	return (
		<Table size={'small'} className={[props.className].join(' ')}>
			{
				props.content.map((contentElement: HorizontalTableContentType, index: number) => <TableBody key={index}>
					<TableRow>
						<TableCell variant={'head'} sx={classes.horizontalHeadCell}>{contentElement.title}</TableCell>
						{contentElement.subheader === true
							? <TableCell variant={'body'} sx={classes.horizontalHeadCell}>{contentElement.body}</TableCell>
							: <TableCell variant={'body'} sx={classes.horizontalBodyCell}>{contentElement.body}</TableCell>
						}
					</TableRow>
				</TableBody>)
			}
		</Table>
	);
};

export default HorizontalTable;
