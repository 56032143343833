import React, { useEffect, useState } from 'react';
import {
	TableCell,
	TableRow,
	TextField,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import Icon from '../../Icon/Icon';
import { TableRowPropsType } from '../Table.types';
import { tableStyles } from '../Table.styles';
import { useAppDispatch } from '../../../redux/hooks';

const MuiTableRow: React.FC<TableRowPropsType> = (props: TableRowPropsType) => {
	const classes = tableStyles;
	const dispatch = useAppDispatch();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [rowState, setRowState] = useState<any>(props.row);
	const [error, setError] = useState<any>(undefined);
	const [prevRowState, setPrevRowState] = useState<any>(props.row);

	useEffect(() => {
		setPrevRowState(props.row);
		setRowState(props.row);
	}, [props.row, props.keys]);

	const handleRowElementValueChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
		setRowState({
			...rowState,
			[event.target.name]: event.target.value
		});
	};

	const handleEditDone = async (): Promise<void> => {
		if (props.editable !== undefined) {
			let errorMsgStatus = false;
			const editabledata = props.editable;
			const tmpErrObj = {};

			props.keys.forEach((rowKeyName, index) => {
				if (!editabledata.validation[index].expression.test(rowState[rowKeyName])) {
					Object.assign(tmpErrObj, { [rowKeyName]: editabledata.validation[index].errorMsg });
					errorMsgStatus = true;
				}
			});
			setError(tmpErrObj);

			if (!errorMsgStatus) {
				await dispatch(props.editable.editFuncCallback(rowState));
				setEditMode(!editMode);
				setError(undefined);
			}

		}
	};

	const handleEditCancel = (): void => {
		setRowState(prevRowState);
		setEditMode(false);
		setError(undefined);
	};

	const handleDelete = async (): Promise<void> => {
		if (props.deleteFuncCallback !== undefined) {
			if (window.confirm('Confirm item deletion!')) {
				await dispatch(props.deleteFuncCallback(rowState));
			}
		}
		setEditMode(false);
	};

	// Extended deletemethod with extra parameter
	const handleParamDelete = async (): Promise<void> => {
		if (props.deletable !== undefined) {
			if (window.confirm('Confirm item deletion!')) {
				await dispatch(props.deletable.deleteFuncCallback(rowState, props.deletable.param));
			}
		}
		setEditMode(false);
	};

	return (
		<React.Fragment>
			{
				props.deletable !== undefined
					? <TableRow key={rowState.name} hover>
						{
							props.keys.map((rowKeyName, index) =>
								<TableCell key={index} sx={classes.editModeTextField}>
									{rowState[rowKeyName] === null ? '' : rowState[rowKeyName]}
								</TableCell>
							)
						}

						{
							props.editable && <TableCell sx={classes.editModeTextField}>
								<Icon IconComponent={DeleteIcon} onClick={() => handleParamDelete()} hoverText={'Delete'}/>
							</TableCell>
						}

					</TableRow>
					: props.editable !== undefined && editMode
						? <TableRow key={rowState.name} hover sx={classes.editModeRow}>
							{
								props.keys.map((rowKeyName, index) => {
									const readOnly = props.editable?.readOnly?.[index];

									return <TableCell key={index} sx={classes.editModeTextField}>
										<TextField
											name={rowKeyName}
											label={props.editable?.validation[index].formatDisplay || (readOnly && 'Read Only')}
											error={error !== undefined && Boolean(error[rowKeyName])}
											helperText={error !== undefined && error[rowKeyName]}
											variant={'outlined'}
											inputProps={{ sx: classes.editModeTextField, readOnly: readOnly }}
											onChange={(event): void => handleRowElementValueChange(event)}
											value={rowState[rowKeyName] === null ? '' : rowState[rowKeyName]}
										/>
									</TableCell>;
								})
							}

							<TableCell sx={classes.editModeTextField}>
								<Icon IconComponent={DoneIcon} primary onClick={handleEditDone} hoverText={'Save'}/>
								<Icon IconComponent={CloseIcon} error onClick={handleEditCancel} hoverText={'Cancel'}/>
								{
									props.deleteFuncCallback && <Icon
										IconComponent={DeleteIcon}
										onClick={handleDelete}
										style={classes.deleteIcon}
										hoverText={'Delete'}
									/>
								}
								{
									props.deletable && <Icon
										IconComponent={DeleteIcon}
										onClick={handleParamDelete}
										style={classes.deleteIcon}
										hoverText={'Delete'}
									/>

								}
							</TableCell>

						</TableRow>
						: <TableRow key={rowState.name} hover>
							{
								props.keys.map((rowKeyName, index) =>
									<TableCell key={index} sx={classes.editModeTextField}>
										{rowState[rowKeyName] === null ? '' : rowState[rowKeyName]}
									</TableCell>
								)
							}

							{
								props.editable && <TableCell sx={classes.editModeTextField}>
									<Icon IconComponent={EditIcon} onClick={() => setEditMode(!editMode)} hoverText={'Edit'}/>
								</TableCell>
							}

						</TableRow>
			}
		</React.Fragment>
	);
};

export default MuiTableRow;
