import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { fetchRadome, resetRadomeState } from '../../../redux/Radome/radome.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { APP_ROUTES } from '../../../service/routes/AppRouter.constants';
import Grid from '@mui/material/Grid';
import Loader from '../../../components/Loader/Loader';
import MuiPaper from '../../../components/Paper/Paper';
import MuiTabs from '../../../components/Tabs/Tabs';
import { Radome } from '../../../redux/Radome/radome.types';
import RadomeDetails from './RadomeDetails';
import { RadomeUpdate } from './RadomeUpdate';
import { selectRadomeState } from '../../../redux/Radome/radome.slice';

const Content = (radome: Radome): JSX.Element => {
	return (
		<div>
			<MuiTabs name={`Radome-${radome.id}-Alternatives`} tabsList={[
				{ label: 'Details', component: <RadomeDetails {...radome} /> },
				{ label: 'Edit', component: <RadomeUpdate /> }
			]} />
		</div>
	);
};

const RadomeSingle: React.FC = () => {
	const { id = '0' } = useParams<{ id: string }>();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchRadome({ radomeId: Number.parseInt(id, 10) }));

		return () => {
			dispatch(resetRadomeState());
		};
	}, [dispatch, id]);

	const radome = useAppSelector(selectRadomeState);

	if (radome === null) {
		return <Loader/>;
	}

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<p><Link to={APP_ROUTES.UTILITIES.RADOMES}>← Back to Radomes</Link></p>
					<h1> Radome {radome.radomeType.radomeTypeName} {radome.serialNumber} (ID: {radome.id})</h1>
					<Grid item xs={12}>
						<Content {...radome} />
					</Grid>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default RadomeSingle;
