import React from 'react';

const Import_Time_Series: React.FC = () => {
	return (
		<>
			<h1>Import_Time_Series</h1>
		</>
	);
};

export default Import_Time_Series;

