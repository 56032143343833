/**
 * Represents a single antRefPointType
 */
export interface AntRefPointType {
	id : number;
	antennaRefPointTypeName: string;
}

/**
 * Represents a list of antRefPointTypes
 */
export interface AntRefPointTypeListType {
	antRefPointTypeList: AntRefPointType[];
}

/**
 * Represents an id-less antRefPointType, suitable for adding new antRefPointType type with the api
 */
export type AddAntRefPointTypeType = Omit<AntRefPointType, 'id'>;

export const antRefPointTypeTemplate: AntRefPointType = {
	id: 0,
	antennaRefPointTypeName: '',
};
