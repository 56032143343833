export interface LocalTie {
	id: number;
	tiedMarkerName: string | null;
	timestampRegistered: string | null;
	tiedMarkerUsage: string | null;
	surveyMethod: string | null;
	accuracy: number;
	dN: number;
	dE: number;
	dH: number;
	additionalInformation: string | null;
}

export const localTieTemplate: LocalTie = {
	id: 0,
	tiedMarkerName: '',
	timestampRegistered: '',
	tiedMarkerUsage: '',
	surveyMethod: '',
	accuracy: 0,
	dN: 0,
	dE: 0,
	dH: 0,
	additionalInformation: '',
};
