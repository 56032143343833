import { ApplicationDispatch, ApplicationGetState } from '../store';
import { selectSiteList, siteListSlice } from './siteList.slice';

import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { SiteListType } from './site.types';
import { getEnvStateData } from '../../service/envStateData';
import { getRequest } from '../../service/requests';
import { sitesListMock } from './site.mock';

export const { setSiteList } = siteListSlice.actions;

export const fetchSiteList = () => {
	return async (dispatch: ApplicationDispatch) => {
		const siteList = await getEnvStateData(getRequest<SiteListType>(API_ENDPOINTS.GET_SITE_AND_SITE_CONFIGS), sitesListMock);

		if (siteList !== null) {
			return dispatch(setSiteList(siteList));
		}

		return null;
	};
};
