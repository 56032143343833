import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { APP_ROUTES } from '../../../service/routes/AppRouter.constants';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Loading } from './MiscComponents';
import Paper from '@mui/material/Paper';
import React from 'react';
import Stack from '@mui/material/Stack';
import { deleteInfDoc } from '../../../redux/InfDoc/infDoc.actions';
import { selectInfDoc } from '../../../redux/InfDoc/infdoc.slice';
import { useNavigate } from 'react-router-dom';

export const ImageActions: React.FC<{}> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const infDoc = useAppSelector(selectInfDoc);

	if (infDoc === null) {
		return <Loading />;
	}

	const handleDelete = async () => {
		try {
			await dispatch(deleteInfDoc({ id: infDoc.id }));
		} catch {
			return;
		}

		// Deletion successful, navigating to list of images
		navigate(APP_ROUTES.OTHER.IMAGES.SHOW_IMAGES + '?siteId=' + infDoc.site.id);
	};

	return (
		<Grid container direction='row' justifyContent='center'>
			<Container maxWidth='md'>
				<Stack spacing={0} divider={<Divider flexItem />}>
					<Paper variant='outlined' sx={{ padding: '10px' }}>
						<Grid container direction='row' justifyContent='space-between'>
							<Grid item>
								<h4 style={{ margin: '0' }}>Delete image</h4>
								<p style={{ margin: '0' }}>Remove this image from the database.</p>
							</Grid>
							<Grid item>
								<Button variant='contained' color='error' onClick={handleDelete}>Delete</Button>
							</Grid>
						</Grid>
					</Paper>
				</Stack>
			</Container>
		</Grid>
	);
};
