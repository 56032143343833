import React from 'react';

const External_Clocks: React.FC = () => {
	return (
		<>
			<h1>External_Clocks</h1>
		</>
	);
};

export default External_Clocks;

