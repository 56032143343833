import { Grid } from '@mui/material';
import HorizontalTable from '../../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../../components/Table/Table.types';
import { HovedinfoPropsType } from './Hovedinfo.types';
import MuiPaper from '../../../../components/Paper/Paper';
import React from 'react';
import { Site } from '../../../../redux/Site/site.types';
import { SiteConfig } from '../../../../redux/SiteConfig/siteConfig.types';
import SubTitle from '../../../../components/Typography/SubTitle';

const Nettverksinformasjon: React.FC<HovedinfoPropsType> = (props: HovedinfoPropsType) => {
	const networkRootContent = (siteConfig: Site): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [
			{
				title: 'Lu number:',
				body: siteConfig.luNumber !== null ? siteConfig.luNumber : '',
			},
			{
				title: 'Subnet mask:',
				body: siteConfig.subnetMask !== null ? siteConfig.subnetMask : '',
			},
			{
				title: 'Subnet (for routing):',
				body: siteConfig.subnet !== null ? siteConfig.subnet : '',
			},
			{
				title: 'WAN router address (def.gateway):',
				body: siteConfig.wanRouterAddress !== null ? siteConfig.wanRouterAddress : '',
			},
		];

		return contentList;
	};

	const siteconfisIpContent = (siteConfig: Site): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		siteConfig.siteConfigs.forEach((siteConfigElement: SiteConfig) => {
			if (siteConfigElement.receiver !== null) {
				contentList.push({
					title: siteConfigElement.fourCharId + ':',
					body: siteConfigElement.receiver.ipAddress !== null ? siteConfigElement.receiver.ipAddress : '',
				});
			}
		});

		return contentList;
	};

	return (
		<MuiPaper>
			<Grid container>
				<Grid item xs={6}>
					<SubTitle text={'Network information'} />
					<HorizontalTable content={networkRootContent(props.siteData)} />
				</Grid>
				<Grid item xs={6}>
					<SubTitle text={'Siteconfig IP addresses'} />
					<HorizontalTable content={siteconfisIpContent(props.siteData)} />
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default Nettverksinformasjon;
