export interface ReceiverHist {
	id: number;
	timestampRegistered: string;
	timestampValidFrom: string;
	preparedBy: string;
	toFirmwareVersion: string | null;
	fromFirmwareVersion: string | null;
	toElevationCutoffAngle: number;
	fromElevationCutoffAngle: number;
}

export type ReceiverHistCreateType = Omit<ReceiverHist, 'id' | 'timestampRegistered'> & { receiverId: number };
export type ReceiverHistUpdateType = Omit<ReceiverHist, 'receiverId' | 'timestampRegistered'>;
export type ReceiverHistCreateParamType = Omit<ReceiverHistCreateType, 'fromElevationCutoffAngle' | 'fromFirmwareVersion' | 'timestampRegistered'>;

export const receiverHistTemplate: ReceiverHist = {
	id: 0,
	timestampRegistered: '',
	timestampValidFrom: '',
	preparedBy: '',
	toFirmwareVersion: '',
	fromFirmwareVersion: '',
	toElevationCutoffAngle: 0,
	fromElevationCutoffAngle: 0,
};
