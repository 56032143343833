/**
 * Represents a single antenna type
 */
export interface AntennaType {
	id : number;
	antennaTypeName: string;
}

/**
 * Represents a list of antenna types
 */
export interface AntennaTypeListType {
	antennaTypeList: AntennaType[];
}

/**
 * Represents an id-less antenna type, suitable for adding new antenna type with the api
 */
export type AddAntennaTypeType = Omit<AntennaType, 'id'>;

export const antennaTypeTemplate: AntennaType = {
	id: 0,
	antennaTypeName: '',
};
