import { Grid } from '@mui/material';
import HorizontalTable from '../../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../../components/Table/Table.types';
import { HovedinfoPropsType } from './Hovedinfo.types';
import MuiChip from '../../../../components/Chip/Chip';
import MuiPaper from '../../../../components/Paper/Paper';
import React from 'react';
import { SiteConfig } from '../../../../redux/SiteConfig/siteConfig.types';
import SiteConfigStatChip from '../../../../components/Chip/SiteConfigStatChip';
import { hovedinfoStyles } from './Hovedinfo.styles';
import { momentPrettyFormat } from '../../../../service/moment';

const Konfigurasjoner: React.FC<HovedinfoPropsType> = (props: HovedinfoPropsType) => {
	const { siteData } = props;
	const classes = hovedinfoStyles;
	const title: string = 'Siteconfigs';

	const getContent = (siteConfig: SiteConfig): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [
			{
				title: 'Established by:',
				body: siteConfig.preparedBy,
			},
			{
				title: 'Timestamp registered:',
				body: momentPrettyFormat(siteConfig.timestampRegistered),
			},
			{
				title: 'Timestamp established:',
				body: siteConfig.timestampEstablished !== null ? momentPrettyFormat(siteConfig.timestampEstablished) : '',
			},
			{
				title: 'Timestamp operational:',
				body: siteConfig.timestampOperational !== null ? momentPrettyFormat(siteConfig.timestampOperational) : '',
			},
			{
				title: 'Timestamp closed:',
				body: siteConfig.timestampClosed !== null ? momentPrettyFormat(siteConfig.timestampClosed) : '',
			},
			{
				title: 'Last established by:',
				body: siteConfig.siteConfigHists.length > 1 ? siteConfig.siteConfigHists[0].preparedBy : '',
			},
			{
				title: 'Timestamp updated:',
				body: siteConfig.siteConfigHists.length > 1 ? momentPrettyFormat(siteConfig.siteConfigHists[0].timestampValidFrom) : '',
			},
			{
				title: 'Siteconfig id:',
				body: siteConfig.id,
			},
		];

		return contentList;
	};

	return (
		<MuiPaper title={title}>
			<Grid container spacing={3}>
				{
					siteData.siteConfigs.map((siteConfig: SiteConfig, index: number) => <Grid key={index} item xs={4}>
						<MuiPaper title={siteConfig.fourCharId} className={classes.configPaper}>
							<Grid container>
								<Grid item xs={12}>
									<MuiChip
										label={`SourceID (${siteConfig.sourceId})`}
										size={'small'}
										type={'locked'}
									/>
									<SiteConfigStatChip type={siteConfig.siteConfigStatType.siteConfigStatTypeName} size={'small'} />
								</Grid>
								<HorizontalTable content={getContent(siteConfig)} />
							</Grid>
						</MuiPaper>
					</Grid>
					)
				}
			</Grid>
		</MuiPaper >
	);
};

export default Konfigurasjoner;
