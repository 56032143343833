import { Controller } from 'react-hook-form';
import { FileInputProps } from './Form.types';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import React from 'react';

/**
 * File input form field.
 */
export const FileInput: React.FC<FileInputProps> = props => {
	const {
		control,
		name,
		label,
		id,
		helperText,
		disabled = false,
		required = false,
	} = props;

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange, ...fieldProps }, fieldState: { error } }) => {
				const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
					// We extract the File objects from the change event
					const { files } = event.target;

					onChange([...files || []]);
				};

				return (
					<FormControl error={Boolean(error)} variant='outlined' hiddenLabel={true} fullWidth={true}>
						<OutlinedInput
							type='file'
							id={id + '-input'}
							aria-label={label}
							required={required}
							disabled={disabled}
							inputProps={{ onBlur, accept: 'image/png', multiple: true }}
							onChange={changeHandler}
							{...fieldProps}
						/>
						<FormHelperText id={id + '-helpertext'}>{error?.message ? error.message : helperText}</FormHelperText>
					</FormControl>
				);
			}}
		/>
	);
};
