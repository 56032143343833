import React from 'react';
import { Typography } from '@mui/material';
import { TypographyPropsType } from './Typography.types';

const CustomTypography: React.FC<TypographyPropsType> = (props: TypographyPropsType) => {
	return (
		<Typography
			variant={props.variant}
			style={{
				color: props.color,
				fontWeight: props.bold ? 'bold' : undefined,
				...props.style,
			}}
			className={[props.className].join(' ')}
			onClick={props.onClick}
		>
			{props.text}
		</Typography>
	);
};

export default CustomTypography;
