import { Button, Popper } from '@mui/material';
import { PopperButtonStyles } from './PopperButton.styles';
import { PopperProps } from './PopperButton.types';
import React from 'react';

const PopperButton: React.FC<PopperProps> = (props: PopperProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};
	const open = Boolean(anchorEl);

	return (
		<div>

			<Button onClick={handleClick}>
				{props.title}
			</Button>

			<Popper
				style={PopperButtonStyles.root}
				open={open}
				anchorEl={anchorEl}
				placement={'bottom-end'}>
				{props.children}
			</Popper>
		</div>
	);
};

export default PopperButton;
