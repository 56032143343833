import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ReceiverType, ReceiverTypeListType } from './receiverType.types';

import type { ApplicationStateType } from '../store';

const initialState = null as ReceiverTypeListType | null;

export const receiverTypeSlice = createSlice({
	name: 'receiverType',
	initialState,
	reducers: {
		setReceiverTypeList: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return action.payload;
		},
		addReceiverType: (state, action: PayloadAction<ReceiverType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					receiverTypeList: [action.payload],
				};
			}

			return {
				receiverTypeList: [...state.receiverTypeList, action.payload],
			};
		},
		updateReceiverType: (state, action: PayloadAction<ReceiverType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					receiverTypeList: [action.payload],
				};
			}

			return {
				receiverTypeList: state.receiverTypeList.map(receiverType => {
					if (receiverType.id === action.payload?.id) {
						return action.payload;
					}

					return receiverType;
				}),
			};
		},
	},
});

export const selectReceiverType = (state: ApplicationStateType) => state.receiverTypeState;

export default receiverTypeSlice.reducer;
