import React from 'react';

const Users: React.FC = () => {
	return (
		<>
			<h1>Users</h1>
		</>
	);
};

export default Users;

