import React from 'react';

const Nettverkskomponenter = (): JSX.Element => {
	return (
		<>
			<h1>Network components</h1>
		</>
	);
};

export default Nettverkskomponenter;
