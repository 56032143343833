import React from 'react';

const Connected_Site_Networkcomponents: React.FC = () => {
	return (
		<>
			<h1>Connected_Site_Networkcomponents</h1>
		</>
	);
};

export default Connected_Site_Networkcomponents;
