import { Link, generatePath } from 'react-router-dom';

import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import { APP_ROUTES } from '../../../service/routes/AppRouter.constants';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import HorizontalTable from '../../../components/Table/HorizontalTable/HorizontalTable';
import type { InfDocType } from '../../../redux/InfDoc/infdoc.types';
import React from 'react';
import { momentPrettyFormat } from '../../../service/moment';

export const ImageHorizontalTable: React.FC<{ infDoc: InfDocType }> = ({ infDoc }) => {
	return (
		<HorizontalTable content={[
			{
				title: 'Id:',
				body: <Link to={generatePath(APP_ROUTES.OTHER.IMAGES.IMAGE_SINGLE, { id: infDoc.id })}>{infDoc.id}</Link>,
			},
			{
				title: 'Captured (Timestamp Written):',
				body: momentPrettyFormat(infDoc.timestampWritten),
			},
			{
				title: 'Stored (Timestamp Stored):',
				body: momentPrettyFormat(infDoc.timestampStored),
			},
			{
				title: 'Stored by',
				body: infDoc.writtenBy,
			},
			{
				title: 'Filename:',
				body: infDoc.filename,
			},
			{
				title: 'Description:',
				body: infDoc.description,
			},
			{
				title: 'Category:',
				body: infDoc.infDocType.infDocTypeName,
			},
		]} />
	);
};

export const ImageCard: React.FC<{ infDoc: InfDocType }> = ({ infDoc }) => {
	return (
		<Card key={infDoc.id} sx={{ display: 'flex', marginBottom: '20px' }}>
			<CardMedia
				component='img'
				image={API_ENDPOINTS.GET_INFDOC_FILE.replace('{id}', infDoc.id.toString(10))}
				title={infDoc.description}
				sx={{ maxWidth: 600, objectFit: 'contain' }} />
			<CardContent>
				<ImageHorizontalTable infDoc={infDoc} />
			</CardContent>
		</Card>
	);
};

export const ImageList: React.FC<{ infDocs: InfDocType[] }> = ({ infDocs }) => {
	if (infDocs.length === 0) {
		return (
			<Alert severity='warning'>{'No images have been found...'}</Alert>
		);
	}

	return (
		<>
			{
				infDocs.map(infDoc =>
					<ImageCard key={infDoc.id} infDoc={infDoc} />
				)
			}
		</>
	);
};

export const Loading = (): JSX.Element => {
	return <div>Loading data...</div>;
};
