import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { AccordionPropsType, AccordionTitleType } from './Accordion.types';

import AccordionTitle from './AccordionTitle';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icon from '../Icon/Icon';
import React from 'react';
import { accordionStyles } from './Accordion.styles';

const MuiAccordion: React.FC<AccordionPropsType> = (props: AccordionPropsType) => {
	const classes = accordionStyles;
	const [expanded, setExpanded] = React.useState<number | false>(false);

	const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Accordion
			expanded={expanded === props.id}
			onChange={handleChange(props.id)}
			className={[props.className].join(' ')}
		>
			<AccordionSummary
				expandIcon={<Icon IconComponent={ExpandMoreIcon} />}
				aria-controls={props.name}
				id={props.name}
				sx={props.primary && expanded === props.id ? classes.rootOpened : classes.rootClosed}
			>
				<>
					{
						props.title.map((title: AccordionTitleType, index: number) =>
							<Box flexGrow={1} flexBasis={0} key={index} textAlign='center'>
								<AccordionTitle
									id={props.name}
									{...title}/>
							</Box>
						)
					}
				</>
			</AccordionSummary>
			<AccordionDetails>
				{props.body}
			</AccordionDetails>
		</Accordion>
	);
};

export default MuiAccordion;
