import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ApplicationStateType } from '../store';
import { SiteListType } from './site.types';

const initialState = {
	siteList: [],
	siteConfigList: [],
} as SiteListType;

export const siteListSlice = createSlice({
	name: 'siteList',
	initialState,
	reducers: {
		setSiteList: (state, action: PayloadAction<typeof initialState>) => ({
			...state,
			...action.payload,
		}),
	},
});

export const selectSiteList = (state: ApplicationStateType) => state.siteListState;

export default siteListSlice.reducer;
