import { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import MuiTable from '../../../components/Table/Table/Table';
import { Receiver } from '../../../redux/Receiver/receiver.types';
import { ReceiverSiteConfigHist } from '../../../redux/SiteConfigHist/siteConfigHist.types';

const ReceiverInstallationHist = (receiver: Receiver): JSX.Element => {
	const [receiverSiteConfigHists, setReceiverSiteConfigHists] = useState<ReceiverSiteConfigHist[]>([]);

	useEffect(() => {
		const effect = async () => {
			const response = await fetch(API_ENDPOINTS.GET_SITECONFIGHIST_RECEIVER.replace('{id}', encodeURIComponent(receiver.id)));
			const data = await response.json();

			setReceiverSiteConfigHists(data);
		};

		effect();
	}, [receiver.id]);

	return (
		<MuiTable
			name='Installation-History'
			head={['Valid from', 'Siteconfig']}
			body={
				receiverSiteConfigHists.map(({ fourCharId, timestampValidFrom }) =>
					({ 'Valid from': timestampValidFrom, 'Siteconfig': fourCharId }))
			}/>
	);
};

export default ReceiverInstallationHist;
