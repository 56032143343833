import { AddAntennaTypeType, AntennaType } from './antennaType.types';
import { antennaTypeListMock, antennaTypeMock } from './antennaType.mock';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import type { ApplicationDispatch } from '../store';
import { antennaTypeSlice } from './AntennaType.slice';
import { getEnvStateData } from '../../service/envStateData';

export const { setAntennaTypeList, addAntennaType, updateAntennaType } = antennaTypeSlice.actions;

/**
 * Updates the store with all antenna types obtained from the api
 */
export const fetchAntennaTypeList = () => {
	return async (dispatch: ApplicationDispatch) => {
		const antennaTypeList: AntennaType[] | null =
			await getEnvStateData(getRequest<AntennaType[]>(API_ENDPOINTS.GET_ANTENNATYPES), antennaTypeListMock);

		const result = antennaTypeList && { antennaTypeList };

		return dispatch(setAntennaTypeList(result));
	};
};

/**
 * Adds an antenna type with the api and updates the store with the newly obtained antenna type
 */
export const addAntennaTypeElement = ({ antennaTypeName }: AddAntennaTypeType) => {
	return async (dispatch: ApplicationDispatch) => {
		const antennaType: AntennaType | null =
			await getEnvStateData(
				postRequest<AntennaType, AddAntennaTypeType>(
					API_ENDPOINTS.POST_ANTENNATYPE,
					{ antennaTypeName }
				),
				antennaTypeMock,
			);

		return dispatch(addAntennaType(antennaType));
	};
};

/**
 * Updates an existing antenna type with the api and updates the store with the updated antenna type
 */
export const updateAntennaTypeElement = (oldAntennaType: AntennaType) => {
	return async (dispatch: ApplicationDispatch) => {
		const updatedAntennaType: AntennaType | null =
			await getEnvStateData(
				putRequest<AntennaType, AntennaType>(
					API_ENDPOINTS.PUT_ANTENNATYPE,
					oldAntennaType,
				),
				antennaTypeMock,
			);

		return dispatch(updateAntennaType(updatedAntennaType));
	};
};
