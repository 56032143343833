import React, { useEffect } from 'react';
import { fetchSiteConfig, resetSiteConfigState } from '../../redux/SiteConfig/siteConfig.actions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import AntennaDetails from '../Utilities/Antennas/AntennaDetails';
import CableDetails from '../Utilities/Cables/CableDetails';
import { Chip } from '@mui/material';
import ExtClockDetails from '../Utilities/External_Clocks/ExtClockDetails';
import Grid from '@mui/material/Grid';
import Loader from '../../components/Loader/Loader';
import MarkAntRefPointDetails from './MarkAntRefPointDetails';
import MarkRefPointDetails from './MarkRefPointDetails';
import MonumentDetails from './MonumentDetails';
import MuiPaper from '../../components/Paper/Paper';
import ReceiverDetails from '../Utilities/Receivers/ReceiverDetails';
import SiteConfigDetails from './SiteConfigDetails';
import Stack from '@mui/material/Stack';
import TagInfoCard from '../Dashboard/DashboardTabs/Konfigurasjon/SiteConfigTab/TagInfoCard';
import { antennaTemplate } from '../../redux/Antenna/antenna.types';
import { cableTemplate } from '../../redux/Cable/cable.types';
import { extClockTemplate } from '../../redux/ExtClock/extClock.types';
import { markAntRefPointTemplate } from '../../redux/MarkAntRefPoint/markAntRefPoint.types';
import { markRefPointTemplate } from '../../redux/MarkRefPoint/markRefPoint.types';
import { monumentTemplate } from '../../redux/Monument/monument.types';
import { receiverTemplate } from '../../redux/Receiver/receiver.types';
import { selectSiteConfigState } from '../../redux/SiteConfig/siteConfig.slice';
import { siteConfigTemplate } from '../../redux/SiteConfig/siteConfig.types';

const SiteConfigExtendedDetails = (): JSX.Element => {

	const siteConfig = useAppSelector(selectSiteConfigState);

	if (siteConfig === null || siteConfig === undefined) {
		return <Loader/>;
	}

	return (

		<Grid container spacing={3}>
			<Grid item xs={6} rowSpacing={2}>
				<Stack spacing={2}>

					<SiteConfigDetails {...siteConfig || siteConfigTemplate}/>

					<MuiPaper title={'Other'}>
						{/*
							A "dashboard friendly" method of showing tags. Should be implemented when new
						 	functionality for tags is implemented
						 */}
						{/* <MuiPaper title={'Tags'} elevation={1}>*/}
						{/*	{*/}
						{/*		siteConfigData.tags !== null && siteConfigData.tags.length > 0*/}
						{/*			? siteConfigData.tags?.map(tag => {return <Chip key={tag.id} label={tag.tagText}/>; })*/}
						{/*			: 'No tags'*/}
						{/*	}*/}
						{/* </MuiPaper>*/}

						<TagInfoCard {...siteConfig}/>

						<MuiPaper title={'Additional information'} elevation={1}>
							{siteConfig.additionalInformation || 'No additional information'}
						</MuiPaper>
					</MuiPaper>

					<MarkRefPointDetails {...siteConfig.markRefPoint || markRefPointTemplate}/>

					<MarkAntRefPointDetails {...siteConfig.markAntRefPoint || markAntRefPointTemplate}/>

					<CableDetails {...siteConfig.cable || cableTemplate}/>

				</Stack>
			</Grid>

			<Grid item xs={6} rowSpacing={3}>
				<Stack spacing={2}>

					<AntennaDetails {...siteConfig.antenna || antennaTemplate}/>

					<ReceiverDetails {...siteConfig.receiver || receiverTemplate}/>

					<ExtClockDetails {...siteConfig.extClock || extClockTemplate}/>

					<MonumentDetails {...siteConfig.monument || monumentTemplate}/>

				</Stack>
			</Grid>
		</Grid>

	);

};

export default SiteConfigExtendedDetails;

