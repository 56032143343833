import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatetimeFormat, momentFormat } from '../../../service/moment';
import { Antenna } from '../../../redux/Antenna/antenna.types';
import { AntennaHist } from '../../../redux/AntennaHist/antennaHist.types';
import Box from '@mui/material/Box';
import { CustomPagination } from '../../../components/Pagination/CustomPagination';
import { Link } from 'react-router-dom';
import React from 'react';

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID',
		flex: 1,
	},
	{
		field: 'timestampRegistered',
		headerName: 'Registered',
		flex: 1,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{
		field: 'timestampValidFrom',
		headerName: 'Valid from',
		flex: 1,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{
		field: 'preparedBy',
		headerName: 'Prepared by',
		flex: 1,
	},
	{
		field: 'toRadome',
		headerName: 'Radome',
		flex: 1,
		renderCell: params =>
			params.value
				? <Link to={`/radomes/${encodeURIComponent(params.value?.id)}`}>{
					params.value?.serialNumber + ' (' + params.value?.radomeType.radomeTypeName + ')'
				}</Link>
				: 'No Radome',
	},
];

const AntennaHistory = (antenna: Antenna): JSX.Element => {

	return (
		<Box height={800}>
			<DataGrid
				rows={antenna.antennaHists}
				columns={columns}
				sx={{
					'& .MuiDataGrid-columnHeader': {
						color: 'white',
						backgroundColor: 'primary.dark',
						'& .MuiDataGrid-columnHeaderTitle': {
							fontWeight: 'bold',
						}
					},
					'& .MuiIconButton-root': {
						color: 'white',
					},
					'& .MuiDataGrid-cell': {
						padding: '10px',
					},
					'& .MuiDataGrid-cell:focus': {
						outline: 'initial',
					},
				}}
				getRowHeight={() => 'auto'}
				disableSelectionOnClick={true}
				components={{
					Pagination: CustomPagination,
				}} />
		</Box>
	);
};

export default AntennaHistory;
