import { API_ENDPOINTS } from './ApiEndpoints';

const navBarRoutes = {
	DASHBOARD: {
		INDEX: '/dashboard',
		SITEINFO: '/dashboard/:siteId/siteinfo',
		SITECONFIGINFO: {
			INDEX: '/dashboard/:siteId/siteconfiginfo',
			SINGLE: '/dashboard/:siteId/siteconfiginfo/:siteConfigId',
			DETAILS: '/dashboard/:siteId/siteconfiginfo/:siteConfigId/details',
			RECEIVER_HISTORY: '/dashboard/:siteId/siteconfiginfo/:siteConfigId/receiverhistory',
			ANTENNA_HISTORY: '/dashboard/:siteId/siteconfiginfo/:siteConfigId/antennahistory',
			MARKREFPOINT_HISTORY: '/dashboard/:siteId/siteconfiginfo/:siteConfigId/markrefpointhistory',
		},
		SITECONTACTS: '/dashboard/:siteId/contacts',
		SITEIMAGES: '/dashboard/:siteId/images',
		SITENETWORKCOMPONENTS: '/dashboard/:siteId/networkcomponents',
		SITEEQUIPMENT: '/dashboard/:siteId/equipment',
		SITEOPTIONALEQUIPMENT: '/dashboard/:siteId/optionalequipment',
		SITEINTERFERENCE: '/dashboard/:siteId/interference',
	},
	SITE: {
		SITES: '/sites',
		// SITE_SINGLE: '/sites/:id',
		// SITE_CREATE: '/sites/create',
	},
	SITECONFIG: {
		INDEX: 'siteconfigs/:siteConfigId',
		DETAILS: '/siteconfigs/:siteConfigId/details',
		EDIT: '/siteconfigs/:siteConfigId/edit',
		CREATE: '/siteconfigs/new',
		LIST: '/siteconfigs',
	},
	UTILITIES: {
		ANTENNAS: '/antennas',
		ANTENNA_SINGLE: '/antennas/:id',
		ANTENNA_CREATE: '/antennas/create',
		RADOMES: '/radomes',
		RADOME_SINGLE: '/radomes/:id',
		CREATE_RADOME: '/radomes/create',
		RECEIVERS: '/receivers',
		RECEIVER_SINGLE: '/receivers/:id',
		EXTERNAL_CLOCKS: '/external_clocks',
		MONUMENTS: '/monuments',
		NETWORKCOMPONENTS: '/networkcomponents',
		COLLOCATED_INSTRUMENTS: '/collocated_instruments',
		INSTRUMENTS: '/instruments',
	},
	LOGGMESSAGES: '/loggmessages',
	OTHER: {
		IMAGES: {
			SHOW_IMAGES: '/images',
			CREATE_IMAGE: '/images/create',
			IMAGE_SINGLE: '/image/:id',
		},
		USERS: '/users',
		DOCUMENTS: '/documents',
		INTERFERENCES: '/interferences',
		CONTACTPERSONS: '/contactpersons',
		REPORTS: {
			SINEX_SITELOG: '/sinex_sitelog',
			IGS_SITELOG: '/igs_sitelog',
			SITEINFO: '/siteinfo',
			COORDINATE_REPORT: '/coordinate_report',
		},
		IP_ADDRESSES: {
			SHOW_ALL_CONNECTED_SITE_CONFIG: '/show_all_connected_site_config',
			SHOW_ALL_CONNECTED_SITE_NETWORKCOMPONENTS: '/show_all_connected_site_networkcomponents',
		},
		TIME_SERIES: {
			SHOW_TIME_SERIES: '/show_time_series',
			IMPORT_TIME_SERIES: '/import_time_series',
		},
		TYPES_REGISTER_CHANGE: '/types_register_change',
	},
};

export const APP_ROUTES = {
	ROOT: '/',
	...navBarRoutes,
	ERROR_404: '/error/404',
};

export const EXTERNAL_ROUTES = {
	HELP: {
		inApp: '/help',
		external: 'https://confluence.statkart.no/display/STDBHIST/Stasjonsdatabase',
	},
	LOGIN: {
		inApp: '/login',
		external: API_ENDPOINTS.LOGIN,
	},
	LOGOUT: {
		inApp: '/logout',
		external: API_ENDPOINTS.LOGIN,
	},
};
