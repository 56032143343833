import React, { useRef } from 'react';
import { Status, StatusProps } from './Status';

import { AutocompleteInput } from './AutocompleteInput';
import Button from '@mui/material/Button';
import { CheckboxInput } from './CheckboxInput';
import Container from '@mui/material/Container';
import { DatetimeInput } from './DatetimeInput';
import Divider from '@mui/material/Divider';
import { FileInput } from './FileInput';
import { FormConfiguration } from './Form.types';
import Grid from '@mui/material/Grid';
import JsonFileInput from './JsonFileInput';
import { TextInput } from './TextInput';

// Implemented example of how to use refs. Only working for a single JsonFileInput field atm.
type FormProps = FormConfiguration & StatusProps & {
	readOnlyValues: { label: string; value: any }[],
	refs?: { [key: string]: React.MutableRefObject<HTMLInputElement | null> };

};

export const Form: React.FC<FormProps> = (props) => {
	const { control,
		fields,
		readOnlyValues,
		onSubmit,
		isDirty,
		isSubmitting,
		isSubmitted,
		isValidating,
		refs,
		isValid } = props;

	return (
		<form autoComplete='off' action='#' onSubmit={onSubmit}>
			<Container maxWidth='xl'>
				<Grid container spacing={2} marginTop={2} marginBottom={2}>
					<Grid item xs={12}>
						<Status
							isDirty={isDirty}
							isSubmitting={isSubmitting}
							isSubmitted={isSubmitted}
							isValidating={isValidating}
							isValid={isValid} />
					</Grid>
					{
						readOnlyValues.length > 0 ? <Grid item xs={12}><Divider /></Grid> : <></>
					}
					{
						readOnlyValues.map(readOnlyValue =>
							<Grid key={readOnlyValue.label} item xs={6}>
								<dl style={{ margin: 0 }}>
									<dt>{readOnlyValue.label}</dt>
									<dd>{readOnlyValue.value}</dd>
								</dl>
							</Grid>
						)
					}
					<Grid item xs={12}><Divider /></Grid>
					{
						fields.map(field => {
							let fieldComponent;

							if (field.type === 'autocomplete') {
								fieldComponent = <AutocompleteInput control={control} {...field} />;
							} else if (field.type === 'datetime') {
								fieldComponent = <DatetimeInput control={control} {...field} />;
							} else if (field.type === 'checkbox') {
								fieldComponent = <CheckboxInput control={control} {...field} />;
							} else if (field.type === 'multilineText') {
								fieldComponent = <TextInput control={control} multiline {...field} />;
							} else if (field.type === 'file') {
								fieldComponent = <FileInput control={control} {...field} />;
							} else if (field.type === 'jsonFile') {
								fieldComponent =
									<div>
										<label>{field.label}</label>
										<JsonFileInput control={control} ref={refs?.jsonFile} {...field}/>
									</div>;

							} else {
								fieldComponent = <TextInput control={control} {...field} />;
							}

							return (
								<Grid key={field.id} item xs={6}>
									{fieldComponent}
								</Grid>
							);
						})
					}
					<Grid item xs={12}><Divider /></Grid>
					<Grid item xs={12}>
						<Button
							type='submit'
							variant='contained'
							color='success'
							disabled={!isValid}
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</Container>
		</form>
	);
};
