import HorizontalTable from '../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../components/Table/Table.types';
import MuiPaper from '../../../components/Paper/Paper';
import { Radome } from '../../../redux/Radome/radome.types';
import { momentPrettyFormat } from '../../../service/moment';

const RadomeDetails = (radome: Radome): JSX.Element => {
	const getContent = (radomeData: Radome): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [
			{
				title: 'Id:',
				body: radomeData.id,
			},
		];

		contentList.push({
			title: 'Serial number:',
			body: radomeData.serialNumber,
		});

		contentList.push({
			title: 'Radome type:',
			body: radomeData.radomeType.radomeTypeName + '(' + radomeData.radomeType.id + ')'
		});

		contentList.push({
			title: 'Exist:',
			body: radomeData.exist
		});

		contentList.push({
			title: 'Timestamp registered:',
			body: momentPrettyFormat(radomeData.timestampRegistered),
		});

		contentList.push({
			title: 'Additional information:',
			body: radomeData.additionalInformation,
		});

		return contentList;

	};

	return (
		<MuiPaper title={'Radome'}>
			<HorizontalTable content={getContent(radome)} />
		</MuiPaper>
	);
};

export default RadomeDetails;
