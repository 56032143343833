import IconButton from '@mui/material/IconButton';
import { IconPropsType } from './Icon.types';
import React from 'react';
import { Tooltip } from '@mui/material';

const Icon: React.FC<IconPropsType> = (props: IconPropsType) => {
	const { IconComponent, hoverText, sx, size } = props;

	let color:
		| 'default'
		| 'primary'
		| 'error' = 'default';

	if (props.primary) {
		color = 'primary';
	}

	if (props.error) {
		color = 'error';
	}

	const icon = <IconComponent className={props.className} fontSize={size} />;

	return (
		<Tooltip title={hoverText ? hoverText : ''} placement={'top'} arrow>
			{
				props.onClick !== undefined
					? <IconButton
						sx={[...Array.isArray(sx) ? sx : [sx]]}
						size={size}
						color={color}
						onClick={props.onClick}>{icon}</IconButton>
					: icon
			}
		</Tooltip>
	);
};

export default Icon;
