import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { AuthType } from './auth.types';
import { authMockWithRoles } from './auth.mock';
import { authSlice } from './auth.slice';
import { getEnvStateData } from '../../service/envStateData';
import { getRequest } from '../../service/requests';

export const { setAuthState } = authSlice.actions;

export const fetchAuthState = () => {
	return async (dispatch: ApplicationDispatch) => {
		const authData = await getEnvStateData(
			getRequest<AuthType>(API_ENDPOINTS.GET_USERROLE),
			authMockWithRoles,
		);

		const result = authData !== null
			? { authenticated: true, role: authData.role, username: authData.username }
			: { authenticated: false, role: null, username: '' };

		return dispatch(setAuthState(result));
	};
};
