import { AddAntCalibTypeType, AntCalibTypeType } from './antCalibType.types';
import { antCalibTypeListMock, antCalibTypeMock } from './antCalibType.mock';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { antCalibTypeSlice } from './antCalibType.slice';
import { getEnvStateData } from '../../service/envStateData';

export const { setAntCalibTypeList, addAntCalibType, updateAntCalibType } = antCalibTypeSlice.actions;

/**
 * Updates the store with all antenna calibration types obtained from the api
 */
export const fetchAntCalibTypeList = () => {
	return async (dispatch: ApplicationDispatch) => {
		const antCalibTypeList: AntCalibTypeType[] | null =
			await getEnvStateData(getRequest<AntCalibTypeType[]>(API_ENDPOINTS.GET_ANTCALIBTYPES), antCalibTypeListMock);

		const result = antCalibTypeList && { antCalibTypeList };

		return dispatch(setAntCalibTypeList(result));
	};
};

/**
 * Adds an antenna calibration type with the api and updates the store with the newly obtained antenna calibration type
 */
export const addAntCalibTypeElement = ({ antennaCalibrationTypeName }: AddAntCalibTypeType) => {
	return async (dispatch: ApplicationDispatch) => {
		const antCalibType: AntCalibTypeType | null =
			await getEnvStateData(
				postRequest<AntCalibTypeType, AddAntCalibTypeType>(
					API_ENDPOINTS.POST_ANTCALIBTYPE,
					{ antennaCalibrationTypeName }
				),
				antCalibTypeMock,
			);

		return dispatch(addAntCalibType(antCalibType));
	};
};

/**
 * Updates an existing antenna calibration type with the api and updates the store with the updated antenna calibration
 * type
 */
export const updateAntCalibTypeElement = (oldAntCalibType: AntCalibTypeType) => {
	return async (dispatch: ApplicationDispatch) => {
		const newAntCalibType: AntCalibTypeType | null =
			await getEnvStateData(
				putRequest<AntCalibTypeType, AntCalibTypeType>(
					API_ENDPOINTS.PUT_ANTCALIBTYPE,
					oldAntCalibType,
				),
				antCalibTypeMock,
			);

		return dispatch(updateAntCalibType(newAntCalibType));
	};
};
