import { Receiver, ReceiverUpdateType } from './receiver.types';
import { getRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { getEnvStateData } from '../../service/envStateData';
import { receiverMock } from './receiver.mock';
import { receiverSlice } from './receiver.slice';

export const { setReceiverState, resetReceiverState } = receiverSlice.actions;

/**
 * Fetches a receiver based on its ID.
 */
export const fetchReceiver = ({ receiverId }: { receiverId: number }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.GET_RECEIVER.replace('{id}', encodeURIComponent(receiverId));
		const receiver = await getEnvStateData(getRequest<Receiver>(url), receiverMock);

		if (receiver === null) {
			throw new Error(`Cannot find receiver with id ${receiverId}`);
		}

		return dispatch(setReceiverState(receiver));
	};
};

/**
 * Updates a receiver.
 *
 * WARNING: This function assumes that front end validation has already been done.
 */
export const updateReceiver = ({ receiver }: { receiver: ReceiverUpdateType }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.PUT_RECEIVER.replace('{id}', encodeURIComponent(receiver.id));
		const updatedReceiver = await getEnvStateData(putRequest<Receiver, typeof receiver>(url, receiver), receiverMock);

		if (updatedReceiver === null) {
			throw new Error(`Cannot update receiver with id ${receiver.id}`);
		}

		return dispatch(setReceiverState(updatedReceiver));
	};
};
