import React from 'react';

const Show_Time_Series: React.FC = () => {
	return (
		<>
			<h1>Show_Time_Series</h1>
		</>
	);
};

export default Show_Time_Series;

