import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RadomeType, RadomeTypeListType } from './radomeType.types';

import type { ApplicationStateType } from '../store';

const initialState = null as RadomeTypeListType | null;

export const radomeTypeSlice = createSlice({
	name: 'radomeType',
	initialState,
	reducers: {
		setRadomeTypeList: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return action.payload;
		},
		addRadomeType: (state, action: PayloadAction<RadomeType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					radomeTypeList: [action.payload],
				};
			}

			return {
				radomeTypeList: [...state.radomeTypeList, action.payload],
			};
		},
		updateRadomeType: (state, action: PayloadAction<RadomeType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					radomeTypeList: [action.payload],
				};
			}

			return {
				radomeTypeList: state.radomeTypeList.map(radomeType => {
					if (radomeType.id === action.payload?.id) {
						return action.payload;
					}

					return radomeType;
				}),
			};
		},
	},
});

export const selectRadomeTypeList = (state: ApplicationStateType) => state.radomeTypeState;

export default radomeTypeSlice.reducer;
