import HorizontalTable from '../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../components/Table/Table.types';
import { Link } from 'react-router-dom';
import MuiPaper from '../../../components/Paper/Paper';
import React from 'react';
import { Receiver } from '../../../redux/Receiver/receiver.types';
import { momentPrettyFormat } from '../../../service/moment';
import { paperStyles } from '../../../components/Paper/Paper.styles';

const ReceiverDetails = (receiver: Receiver): JSX.Element => {
	const getContent = (receiverData: Receiver): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = receiverData.id !== 0;

		contentList.push(
			{
				title: 'ID:',
				body: isPresent ? <Link to={`/receivers/${encodeURIComponent(`${receiverData.id}`)}`}>{receiverData.id}</Link> : '',
			},
			{
				title: 'Serial number:',
				body: isPresent ? receiverData.serialNumber : '',
			},
			{
				title: 'Site:',
				body: isPresent ? receiverData.site?.siteName : '',
			},
			{
				title: 'Equipment label:',
				body: isPresent ? receiverData.equipmentLabel : '',
			},
			{
				title: 'Elevation cutoff angle:',
				body: isPresent ? receiverData.elevationCutoffAngle : '',
			},
			{
				title: 'Firmware version:',
				body: isPresent ? receiverData.firmwareVersion : '',
			},
			{
				title: 'Receiver type:',
				body: isPresent ? receiverData.receiverType.receiverTypeName : '',
			},
			{
				title: 'IP address:',
				body: isPresent ? receiverData.ipAddress : '',
			},
			{
				title: 'Registered:',
				body: isPresent ? momentPrettyFormat(receiverData.timestampRegistered) : '',
			},
			{
				title: 'Guarantee:',
				body: isPresent ? momentPrettyFormat(receiverData.timestampGuarantee) : '',
			},
			{
				title: 'Discarded/defective:',
				body: isPresent ? JSON.stringify(!receiverData.exist) : '',
			},
			{
				title: 'Additional information:',
				body: isPresent ? receiverData.additionalInformation : '',
			},
		);

		return contentList;
	};

	return (
		<MuiPaper style={receiver.id === 0 ? paperStyles.error : {}} title={'Receiver'}>
			<HorizontalTable content={getContent(receiver)} />
		</MuiPaper >

	);
};

export default ReceiverDetails;
