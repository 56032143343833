import { SiteConfig, SiteConfigMinimal } from '../SiteConfig/siteConfig.types';
import { Agency } from '../Agency/agency.types';
import { Country } from '../Country/country.types';
import { Municipality } from '../Municipality/municipality.types';
import { OperStatType } from '../OperStatType/operStatType.types';
import { TechtonicPlate } from '../TechtonicPlate/techtonicPlate.types';

export interface Site {
	id: number;
	timestampRegistered: string;
	siteName: string;
	active: number;
	preparedBy: string;
	visitAddress: string | null;
	zipCode: string | null;
	city: string | null;
	tempStabilization: string | null;
	accessDescription: string | null;
	subnet: null;
	subnetMask: string | null;
	wanRouterAddress: string | null;
	lanRouterAddress: null;
	luNumber: string | null;
	priority: 0 | 1 | 2 | 3;
	operStatType: OperStatType;
	country: Country;
	municipality: Municipality;
	tectonicPlate: TechtonicPlate;
	agency: Agency;
	additionalInformation: string | null;
	changedBy: string | null;
	landRegistryNumber: string;
	siteConfigs: SiteConfig[];
	siteHists: any[]
}

export interface SiteMinimal {
	id: number,
	siteName: string,
	agencyName: string,
	countryName: string
}

export const siteMinimalTemplate: SiteMinimal = {
	id: 0,
	siteName: '',
	agencyName: '',
	countryName: '',
};

export interface SiteListType {
	siteConfigList: SiteConfigMinimal[];
	siteList: SiteMinimal[];
}
