import { RadomeType, radomeTypeTemplate } from '../RadomeType/radomeType.types';

/**
 * Represents a single radome
 */
export interface Radome {
	id: number;
	radomeType: RadomeType;
	timestampRegistered: string;
	serialNumber: string;
	exist: number;
	additionalInformation: string | null;
}

/**
 * Type of Radome that is used with put requests
 */
export type RadomeUpdateType = Omit<Radome,
	'radomeType' |
	'timestampRegistered'
	> & {
	radomeTypeName: string
};

/**
 * Type of Radome that is used with post requests
 */
export type RadomeCreateType = Omit<Radome,
	'id' |
	'radomeType' |
	'timestampRegistered'
	> & {
	radomeTypeName: string
};

export const radomeTemplate: Radome = {
	id: 0,
	radomeType: radomeTypeTemplate,
	timestampRegistered: '',
	serialNumber: '',
	exist: 0,
	additionalInformation: '',
};
