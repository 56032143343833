import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatetimeFormat, momentFormat } from '../../../service/moment';
import React, { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import { AntennaSummaryType } from '../../../redux/Antenna/antenna.types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { CustomPagination } from '../../../components/Pagination/CustomPagination';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import MuiPaper from '../../../components/Paper/Paper';
import Stack from '@mui/material/Stack';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { fetchAsDataUri } from '../../../service/fetchAsDataUri';

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID',
		flex: 1,
		renderCell: params =>
			<Link to={`/antennas/${encodeURIComponent(params.value)}`}>{params.value}</Link>,
	},
	{
		field: 'site',
		headerName: 'Site',
		flex: 2,
		renderCell: params =>
			<Link to={`/dashboard/${encodeURIComponent(params?.value?.id)}/siteinfo`}>{params?.value?.siteName}</Link>,
		sortComparator: (v1, v2) => v1?.siteName?.localeCompare(v2?.siteName),
	},
	{
		field: 'serialNumber',
		headerName: 'Serial number',
		flex: 2
	},
	{
		field: 'antennaTypeName',
		headerName: 'Antenna type',
		flex: 2
	},
	{
		field: 'radomeTypeName',
		headerName: 'Radome',
		flex: 2,
		renderCell: params =>
			<Link to={`/radomes/${encodeURIComponent(`${params.row.radomeId}`)}`}>{params.value}</Link>,
	},
	{
		field: 'antRefPointTypeName',
		headerName: 'Antenna reference point type',
		flex: 2
	},
	{
		field: 'fourCharIds',
		headerName: 'Siteconfig(s)',
		flex: 2,
	},
	{
		field: 'hasAntCalibrationIds',
		headerName: 'Has calibration',
		flex: 1,
		renderCell: params =>
			<span>{params.value === 1 ? 'YES' : 'NO'}</span>
	},
	{
		field: 'exist',
		headerName: 'Discarded / defective',
		flex: 1,
		renderCell: params =>
			<span>{params.value === 0 ? 'YES' : 'NO'}</span>
	},
	{
		field: 'equipmentLabel',
		headerName: 'Equipment label',
		flex: 1
	},
	{
		field: 'timestampRegistered',
		headerName: 'Registered',
		flex: 1,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{
		field: 'timestampGuarantee',
		headerName: 'Guarantee',
		flex: 1,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{
		field: 'additionalInformation',
		headerName: 'Additional information',
		flex: 5
	},
];

const Antennas: React.FC = () => {
	const [antennas, setAntennas] = useState<AntennaSummaryType[]>([]);

	useEffect(() => {
		const effect = async () => {
			const response = await fetch(API_ENDPOINTS.GET_ANTENNA_SUMMARIES);
			const data = await response.json();

			setAntennas(data);
		};

		effect();
	}, []);

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1>Antennas</h1>
					<Stack spacing={2}>
						<div>
							<ButtonGroup variant='outlined'>
								<Button
									startIcon={<FolderZipIcon />}
									onClick={async () => {
										window.location.href = await fetchAsDataUri(API_ENDPOINTS.GET_ANTENNAS_EXCEL);
									}}>
									Export to Excel
								</Button>
								<Button
									startIcon={<TextSnippetIcon />}
									onClick={async () => {
										window.location.href = await fetchAsDataUri(API_ENDPOINTS.GET_ANTENNAS_CSV);
									}}>
									Export to CSV
								</Button>
							</ButtonGroup>
						</div>
						<Box height={800}>
							<DataGrid
								rows={antennas}
								columns={columns}
								sx={{
									'& .MuiDataGrid-columnHeader': {
										color: 'white',
										backgroundColor: 'primary.dark',
										'& .MuiDataGrid-columnHeaderTitle': {
											fontWeight: 'bold',
										}
									},
									'& .MuiIconButton-root': {
										color: 'white',
									},
									'& .MuiDataGrid-cell': {
										padding: '10px',
									},
									'& .MuiDataGrid-cell:focus': {
										outline: 'initial',
									},
								}}
								getRowHeight={() => 'auto'}
								disableSelectionOnClick={true}
								components={{
									Pagination: CustomPagination,
								}} />
						</Box>
					</Stack>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default Antennas;

