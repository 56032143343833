import {
	AddAntRefPointTypeType,
	AntRefPointType
} from '../../../redux/AntRefPointType/antRefPointType.types';
import { FormListType, addForm, getAttributeList, validateForm } from '../../Dashboard/DashboardTabs/Konfigurasjon/HistService';
import {
	addAntRefPointTypeElement,
	fetchAntRefPointTypeList,
	updateAntRefPointTypeElement,
} from '../../../redux/AntRefPointType/antRefPointType.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useEffect, useState } from 'react';

import MuiTable from '../../../components/Table/Table/Table';
import { regexList } from '../../../service/regex';
import { selectAntRefPointType } from '../../../redux/AntRefPointType/antRefPointType.slice';

interface MuiAntRefPointTypeElement {
	'ID': number;
	'Antenna Reference Point Type Name': string;
}

/**
 * Helper function to map antRefPointType to what's used by MUI table
 */
const antRefPointTypeToMuiElement = ({ id, antennaRefPointTypeName }: AntRefPointType) => ({
	'ID': id,
	'Antenna Reference Point Type Name': antennaRefPointTypeName,
});

/**
 * Helper function to map MUI table element to antRefPointType
 */
const MuiElementToAntRefPointType =
	({ 'ID': id, 'Antenna Reference Point Type Name': antennaRefPointTypeName }:MuiAntRefPointTypeElement): AntRefPointType => ({
		id,
		antennaRefPointTypeName,
	});

const Ant_Ref_Point_Types = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const antRefPointTypeData = useAppSelector(selectAntRefPointType);

	const [formValues, setFormValues] = useState<AddAntRefPointTypeType>({
		antennaRefPointTypeName: ''
	});
	const [error, setError] = useState<any>(undefined);

	useEffect(() => {
		dispatch(fetchAntRefPointTypeList());
	}, [dispatch]);

	// Fields displayed in the antenna reference point type table head
	const formList: FormListType[] = [
		{
			label: 'ID',
			keyName: 'id',
			regex: regexList.freepass,
			number: true,
			readOnly: true,
		},
		{
			label: 'Antenna Reference Point Type Name',
			keyName: 'antennaRefPointTypeName',
			regex: regexList.freepass,
			number: false,
			readOnly: false,
		},
	];

	// Field displayed in the add antenna reference point type popup window
	const newAntRefPointTypeList: FormListType[] = [
		{
			label: 'Antenna Reference Point Type Name',
			keyName: 'antennaRefPointTypeName',
			regex: regexList.freepass,
			number: false,
		},
	];

	const handleFormChange = (keyProp: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues,	[keyProp]: event.target.value });
	};

	return (
		<>
			<h2>Antenna Reference Point Types</h2>
			<MuiTable
				name={'antRefPointTypeList'}
				head={getAttributeList(formList, 'label')}
				body={(antRefPointTypeData?.antRefPointTypeList || []).map(antRefPointTypeToMuiElement)}
				addable={{
					title: 'Add antenna reference point type',
					form: {
						modal: addForm(formValues, newAntRefPointTypeList, error, handleFormChange),
						values: formValues,
						validate: () => {
							const validatedState = validateForm(formValues, formList);

							setError(validatedState.tmpErrObj);

							return validatedState.errorMsgStatus;
						},
					},
					buttonText: 'Add',
					addFuncCallback: (antRefPointType: AntRefPointType) => async () => {
						// We manually let the actions do their dispatching in this strange way because we need to be sure
						// the updates are fully completed before getting the newest antenna reference point type list
						// from the server.
						await addAntRefPointTypeElement(antRefPointType)(dispatch);
						await fetchAntRefPointTypeList()(dispatch);
					},
					param: 0
				}}
				editable={{
					editFuncCallback: (antRefPointType: MuiAntRefPointTypeElement) => async () => {
						await updateAntRefPointTypeElement(MuiElementToAntRefPointType(antRefPointType))(dispatch);
						await fetchAntRefPointTypeList()(dispatch);
					},
					validation: getAttributeList(formList, 'regex'),
					readOnly: getAttributeList(formList, 'readOnly')
				}}
			/>
		</>
	);
};

export default Ant_Ref_Point_Types;
