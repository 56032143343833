import { Link, generatePath, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { fetchSiteConfig, resetSiteConfigState } from '../../redux/SiteConfig/siteConfig.actions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { APP_ROUTES } from '../../service/routes/AppRouter.constants';
import Grid from '@mui/material/Grid';
import Loader from '../../components/Loader/Loader';
import MuiPaper from '../../components/Paper/Paper';
import RouteTabs from '../../components/RouteTabs/RouteTabs';

import { selectSiteConfigState } from '../../redux/SiteConfig/siteConfig.slice';

const SiteConfigSingle = (): JSX.Element => {
	const { siteConfigId = '0' } = useParams<{ siteConfigId: string }>();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchSiteConfig({ siteConfigId: Number.parseInt(siteConfigId) }));

		return () => {
			dispatch(resetSiteConfigState());
		};
	}, [dispatch, siteConfigId]);

	const siteConfig = useAppSelector(selectSiteConfigState);

	if (siteConfigId === undefined || siteConfig === null) {
		return <Loader/>;
	}

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1>Siteconfig - {siteConfig.fourCharId} (ID: {siteConfig.id})</h1>
					<p><Link to={APP_ROUTES.SITECONFIG.LIST}>← Back to siteconfigs</Link></p>
					<Grid item xs={12}>
						<RouteTabs routes={[
							{
								url: generatePath(APP_ROUTES.SITECONFIG.DETAILS, { siteConfigId }),
								id: APP_ROUTES.SITECONFIG.DETAILS,
								label: 'Details',
							},
							{
								url: generatePath(APP_ROUTES.SITECONFIG.EDIT, { siteConfigId }),
								id: APP_ROUTES.SITECONFIG.EDIT,
								label: 'Edit',
							},
						]}/>
					</Grid>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default SiteConfigSingle;
