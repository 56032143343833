import { css } from '@emotion/css';
import { globalStyles } from '../../../../service/styles/global.styles';

export const hovedinfoStyles = {
	configPaper: css`
		margin: '10px';
		&:hover: {
			background-color: ${globalStyles.colors.grey.light};
		}
	`,
};
