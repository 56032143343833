import React from 'react';

const Utstyr = (): JSX.Element => {
	return (
		<>
			<h1>Equipment</h1>
		</>
	);
};

export default Utstyr;
