import { Grid } from '@mui/material';
import HorizontalTable from '../../../../components/Table/HorizontalTable/HorizontalTable';
import { HovedinfoPropsType } from './Hovedinfo.types';
import MuiPaper from '../../../../components/Paper/Paper';
import OperStatChip from '../../../../components/Chip/OperStatChip';
import PriorityChip from '../../../../components/Chip/PriorityChip';
import React from 'react';
import { momentPrettyFormat } from '../../../../service/moment';

const HovedinfoSite: React.FC<HovedinfoPropsType> = (props: HovedinfoPropsType) => {
	const { siteData } = props;

	return (
		<MuiPaper title={`Main information - ${siteData.siteName}`}>
			<Grid container>
				<Grid item xs={12}>
					<PriorityChip priority={siteData.priority} size={'small'}/>
					<OperStatChip type={siteData.operStatType.operStatTypeName} size={'small'} />
				</Grid>
				<Grid item xs={12}>
					<HorizontalTable content={[
						{
							title: 'Established by:',
							body: siteData.preparedBy,
						},
						{
							title: 'Timestamp registered:',
							body: momentPrettyFormat(siteData.timestampRegistered),
						},
						{
							title: 'Country:',
							body: `${siteData.country.countryName}`,
						},
						{
							title: 'Company:',
							body: `${siteData.agency.agencyName}`,
						},
						{
							title: 'Tectonic plate:',
							body: `${siteData.tectonicPlate.tectonicPlateName}`,
						},
						{
							title: 'Site id:',
							body: `${siteData.id}`,
						},
					]} />
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default HovedinfoSite;
