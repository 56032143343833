import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { ApplicationStateType } from '../store';
import { Radome } from './radome.types';

const initialState = null as Radome | null;

export const radomeSlice = createSlice({
	name: 'radome',
	initialState,
	reducers: {
		setRadomeState: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return { ...state, ...action.payload };
		},
		resetRadomeState: () => null,
	},
});

export const selectRadomeState = (state: ApplicationStateType) => state.radomeState;

export default radomeSlice.reducer;
