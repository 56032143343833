import { Grid } from '@mui/material';
import MuiPaper from '../../../components/Paper/Paper';
import MuiTabs from '../../../components/Tabs/Tabs';
import React from 'react';
import { typesRegisterChangeTabsList } from './typesRegisterChangeTabsList';

const Types_Register_Change: React.FC = () => {
	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1>Register and change types</h1>
					<Grid item xs={12}>
						<MuiTabs name={'Types-Register-Change-Tabs-List'} tabsList={typesRegisterChangeTabsList}/>
					</Grid>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default Types_Register_Change;

