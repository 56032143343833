import { Cable } from '../../../redux/Cable/cable.types';
import HorizontalTable from '../../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../../components/Table/Table.types';
import MuiPaper from '../../../components/Paper/Paper';
import React from 'react';
import { momentPrettyFormat } from '../../../service/moment';
import { paperStyles } from '../../../components/Paper/Paper.styles';

const CableDetails = (cable: Cable): JSX.Element => {
	const getContent = (cableData: Cable): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = cableData.id !== 0;

		contentList.push(
			{
				title: 'ID:',
				body: isPresent ? cableData.id : '',
			},
			{
				title: 'Cable type:',
				body: isPresent ? cableData.cableType?.cableTypeName : '',
			},
			{
				title: 'Length:',
				body: isPresent ? cableData.length : '',
			},
			{
				title: 'Registered:',
				body: isPresent ? momentPrettyFormat(cableData.timestampRegistered) : '',
			},
			{
				title: 'Additional information:',
				body: isPresent ? cableData.additionalInformation : '',
			},
		);

		return contentList;
	};

	return (
		<MuiPaper style={cable.id === 0 ? paperStyles.error : {}} title={'Cable'}>
			<HorizontalTable content={getContent(cable)} />
		</MuiPaper >
	);
};

export default CableDetails;
