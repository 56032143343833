import { AddCountryType, Country } from './country.types';
import { countryListMock, countryMock } from './country.mock';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { countrySlice } from './country.slice';
import { getEnvStateData } from '../../service/envStateData';

export const { setCountryList, addCountry, updateCountry } = countrySlice.actions;

/**
 * Updates the store with all countries obtained from the api
 */
export const fetchCountryList = () => {
	return async (dispatch: ApplicationDispatch) => {
		const countryList: Country[] | null =
			await getEnvStateData(getRequest<Country[]>(API_ENDPOINTS.GET_COUNTRY), countryListMock);

		const result = countryList && { countryList };

		return dispatch(setCountryList(result));
	};
};

/**
 * Adds a country with the api and updates the store with the newly obtained country
 */
export const addCountryElement = ({ countryName, countryCode }: AddCountryType) => {
	return async (dispatch: ApplicationDispatch) => {
		const country: Country | null =
			await getEnvStateData(
				postRequest<Country, AddCountryType>(
					API_ENDPOINTS.POST_COUNTRY,
					{ countryName, countryCode }
				),
				countryMock,
			);

		return dispatch(addCountry(country));
	};
};

/**
 * Updates an existing country with the api and updates the store with the updated country
 */
export const updateCountryElement = (oldCountry: Country) => {
	return async (dispatch: ApplicationDispatch) => {
		const newCountry: Country | null =
			await getEnvStateData(
				putRequest<Country, Country>(
					API_ENDPOINTS.PUT_COUNTRY,
					oldCountry,
				),
				countryMock,
			);

		return dispatch(updateCountry(newCountry));
	};
};
