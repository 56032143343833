import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ApplicationStateType } from '../store';
import { Receiver } from './receiver.types';

const initialState = null as Receiver | null;

export const receiverSlice = createSlice({
	name: 'receiver',
	initialState,
	reducers: {
		setReceiverState: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return { ...state, ...action.payload };
		},
		resetReceiverState: () => null,
	},
});

export const selectReceiverState = (state: ApplicationStateType) => state.receiverState;

export default receiverSlice.reducer;
