import React from 'react';

const Connected_Site_Config: React.FC = () => {
	return (
		<>
			<h1>Connected_Site_Config</h1>
		</>
	);
};

export default Connected_Site_Config;

