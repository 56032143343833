import CustomTypography from './CustomTypography';
import React from 'react';
import { TypographyPropsType } from './Typography.types';
import { globalStyles } from '../../service/styles/global.styles';

const SubTitle: React.FC<TypographyPropsType> = (props: TypographyPropsType) => {
	return (
		<CustomTypography
			variant={globalStyles.typography.subTitle}
			className={[props.className].join(' ')}
			style={{
				fontWeight: 'bold',
				...props.style,
			}}
			{...props}

		/>
	);
};

export default SubTitle;
