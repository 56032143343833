import { InfDocType, InfDocTypeType } from '../../../redux/InfDoc/infdoc.types';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ImageList } from './MiscComponents';
import { SiteMinimal } from '../../../redux/Site/site.types';
import TextField from '@mui/material/TextField';
import { fetchSiteList } from '../../../redux/Site/siteList.actions';
import { getRequest } from '../../../service/requests';
import { globalStyles } from '../../../service/styles/global.styles';
import { selectSiteList } from '../../../redux/Site/siteList.slice';
import { useSearchParams } from 'react-router-dom';

/**
 * Shows a list of images, with options to search by Site and Category
 */
const ImagesShow: React.FC = () => {
	const dispatch = useAppDispatch();

	const { siteList } = useAppSelector(selectSiteList);
	const [infDocTypeList, setInfDocTypeList] = useState<InfDocTypeType[]>([]);
	const [infDocList, setInfDocList] = useState<InfDocType[]>([]);

	// We'll store the selected site id and category in the query parameters, that way the
	// page - including the selected site and category - is shareable.
	const [searchParams, setSearchParams] = useSearchParams();
	const siteId = searchParams.get('siteId') !== null ? Number.parseInt(searchParams.get('siteId')!, 10) : null;
	const category = searchParams.get('category');

	let selectedSite: SiteMinimal | null = null;

	for (const site of siteList) {
		if (site.id === siteId) {
			selectedSite = site;
		}
	}

	let selectedCategory: InfDocTypeType | null = null;

	for (const infDocType of infDocTypeList) {
		if (infDocType.infDocTypeName === category) {
			selectedCategory = infDocType;
		}
	}

	useEffect(() => {
		let shouldFetch = true;
		const helper = () => {
			if (shouldFetch) {
				dispatch(fetchSiteList());
			}
		};

		helper();

		return () => {
			shouldFetch = false;
		};
	}, [dispatch]);

	useEffect(() => {
		let shouldFetch = true;
		const helper = async () => {
			if (shouldFetch) {
				const data = await getRequest<typeof infDocTypeList>(API_ENDPOINTS.GET_INFDOCTYPES);

				if (data !== null) {
					setInfDocTypeList(data);
				}
			}
		};

		helper();

		return () => {
			shouldFetch = false;
		};
	}, []);

	useEffect(() => {
		let shouldFetch = true;
		const helper = async () => {
			if (shouldFetch) {
				const query = new URLSearchParams();

				if (siteId === null && category === null) {
					setInfDocList([]);

					return;
				}

				if (siteId !== null) {
					query.set('siteId', siteId.toString(10));
				}

				if (category !== null) {
					query.set('category', category);
				}

				const data = await getRequest<typeof infDocList>(
					API_ENDPOINTS.GET_INFDOCS + '?' + query.toString()
				);

				if (data !== null) {
					setInfDocList(data);
				}
			}
		};

		helper();

		return () => {
			shouldFetch = false;
		};
	}, [category, siteId]);

	if (siteList.length === 0) {
		return (
			<Alert severity='warning'>{'Loading site data...'}</Alert>
		);
	}

	if (infDocTypeList.length === 0) {
		return (
			<Alert severity='warning'>{'Loading categories...'}</Alert>
		);
	}

	return (
		<Box sx={{ margin: globalStyles.margin.root }}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1>Show Images</h1>
					<Grid container direction={'row'} spacing={2}>
						<Grid item xs={2}>
							<Autocomplete
								options={siteList}
								value={selectedSite}
								getOptionLabel={site => site.siteName}
								renderInput={(params) => <TextField {...params} label={'Site'} variant={'outlined'} />}
								onChange={(event, value) => {
									setInfDocList([]);
									setSearchParams(prev => {
										if (value !== null) {
											prev.set('siteId', value.id.toString(10));
										} else {
											prev.delete('siteId');
										}

										return prev;
									});
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<Autocomplete
								options={infDocTypeList}
								value={selectedCategory}
								getOptionLabel={infDocType => infDocType.infDocTypeName}
								renderInput={(params) => <TextField {...params} label={'Category'} variant={'outlined'} />}
								onChange={(event, value) => {
									setInfDocList([]);
									setSearchParams(prev => {
										if (value !== null) {
											prev.set('category', value.infDocTypeName);
										} else {
											prev.delete('category');
										}

										return prev;
									});
								}}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<ImageList infDocs={infDocList} />
				</Grid>
			</Grid>
		</Box>
	);
};

export default ImagesShow;
