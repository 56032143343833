import React from 'react';

const Documents: React.FC = () => {
	return (
		<>
			<h1>Documents</h1>
		</>
	);
};

export default Documents;

