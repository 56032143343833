import { Paper } from '@mui/material';
import { PaperPropsType } from './Paper.types';
import React from 'react';
import SubTitle from '../Typography/SubTitle';
import { paperStyles } from './Paper.styles';

const MuiPaper: React.FC<PaperPropsType> = (props: PaperPropsType) => {
	const classes = paperStyles;

	return (
		<Paper
			elevation={props.elevation === undefined ? 3 : props.elevation}
			className={props.className}
			sx={classes.root}
			style={props.style}
		>
			{
				props.title && <SubTitle text={props.title} />
			}
			{
				props.children
			}
		</Paper>
	);
};

export default MuiPaper;
