import React, { useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { AutocompleteProps } from './Form.types';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

/**
 * Autocomplete input form field.
 */
export const AutocompleteInput: React.FC<AutocompleteProps> = (props) => {
	const {
		control,
		name,
		label,
		id,
		helperText,
		required = false,
		multiple = false,
		isOptionEqualToValue,
		getOptionLabel,
		getOptions,
	} = props;
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<readonly any[]>([]);
	const loading = open && options.length === 0;

	if ([isOptionEqualToValue, getOptionLabel, getOptions].some(v => typeof v === 'undefined')) {
		throw new Error('Expected isOptionEqualToValue, getOptionLabel and getOptions functions to be defined!');
	}

	useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}

		const fetchOptions = async () => {
			if (active) {
				const fetchedOptions = await getOptions();

				setOptions(fetchedOptions);
			}
		};

		fetchOptions();

		return () => {
			active = false;
		};
	}, [getOptions, loading, open]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, onChange, ...fieldProps }, fieldState: { error } }) => {
				return (
					<Autocomplete
						id={id}
						open={open}
						onOpen={() => {
							setOpen(true);
						}}
						onClose={() => {
							setOpen(false);
						}}
						onBlur={onBlur}
						onChange={(event, value) => {
							// For some reason the correct option value is not passed to the
							// react-hook-form object unless we manually pass the second
							// parameter like this.
							onChange(value);
						}}
						multiple={multiple}
						isOptionEqualToValue={isOptionEqualToValue}
						getOptionLabel={getOptionLabel}
						options={loading ? multiple ? fieldProps.value : [fieldProps.value] : options}
						loading={loading}
						{...fieldProps}
						value={fieldProps.value}
						renderInput={(params) =>
							<TextField
								{...params}
								label={label}
								required={required}
								variant='outlined'
								InputProps={{
									...params.InputProps,
									endAdornment:
										<React.Fragment>
											{loading ? <CircularProgress color='inherit' size={20} /> : null}
											{params.InputProps.endAdornment}
										</React.Fragment>,
								}}
								fullWidth={true}
								error={Boolean(error)}
								helperText={error?.message ? error.message : helperText}
							/>
						}
					/>
				);
			}}
		/>
	);
};
