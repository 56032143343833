import Alert, { AlertColor } from '@mui/material/Alert';
import React from 'react';

export interface StatusProps {
	isDirty: boolean;
	isSubmitting: boolean;
	isSubmitted: boolean;
	isValidating: boolean;
	isValid: boolean;
}

export const Status: React.FC<StatusProps> = ({ isDirty, isSubmitting, isSubmitted, isValidating, isValid }) => {
	let severity: AlertColor = 'info';
	let message = 'Please update the fields below.';

	if (isSubmitted) {
		severity = 'success';
		message = 'Successfully submitted the form.';
	} else if (isDirty) {
		if (isValidating) {
			message = 'Validating form...';
		} else if (!isValid) {
			severity = 'error';
			message = 'Please correct errors and fill in required fields.';
		} else if (isValid) {
			severity = 'success';
			message = 'Form is valid.';
		} else if (isSubmitting) {
			message = 'Submitting form...';
		}
	}

	return (
		<Alert severity={severity}>{message}</Alert>
	);
};
