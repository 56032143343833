import { AntCalibTypeListType, AntCalibTypeType } from './antCalibType.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { ApplicationStateType } from '../store';

const initialState = null as AntCalibTypeListType | null;

export const antCalibTypeSlice = createSlice({
	name: 'antCalibType',
	initialState,
	reducers: {
		setAntCalibTypeList: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return action.payload;
		},
		addAntCalibType: (state, action: PayloadAction<AntCalibTypeType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					antCalibTypeList: [action.payload],
				};
			}

			return {
				antCalibTypeList: [...state.antCalibTypeList, action.payload],
			};
		},
		updateAntCalibType: (state, action: PayloadAction<AntCalibTypeType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					antCalibTypeList: [action.payload],
				};
			}

			return {
				antCalibTypeList: state.antCalibTypeList.map(antCalibType => {
					if (antCalibType.id === action.payload?.id) {
						return action.payload;
					}

					return antCalibType;
				}),
			};
		},
	},
});

export const selectAntCalibType = (state: ApplicationStateType) => state.antCalibTypeState;

export default antCalibTypeSlice.reducer;
