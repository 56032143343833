import { APP_ROUTES, EXTERNAL_ROUTES } from '../../service/routes/AppRouter.constants';

import HomeIcon from '@mui/icons-material/Home';
import { navbarElementType } from './NavBar.types';

export const navBarElements: navbarElementType[] = [
	{
		title: '',
		children: [],
		link: APP_ROUTES.ROOT,
		icon: HomeIcon,
	},
	{
		title: 'Site',
		children: [
			{
				title: 'Create',
				// link: APP_ROUTES.SITE.CREATE,
				link: '',
				children: [],
				disabled: true,
			},
			{
				title: 'Update',
				// link: APP_ROUTES.SITE.UPDATE,
				link: '',
				children: [],
				disabled: true,
			},
			{
				title: 'Show all',
				link: APP_ROUTES.SITE.SITES,
				children: []
			},
		],
		link: '',
	},
	{
		title: 'Siteconfig',
		children: [
			{
				title: 'Show and edit siteconfigs',
				link: APP_ROUTES.SITECONFIG.LIST,
				children: [],
			},
			{
				title: 'New',
				link: APP_ROUTES.SITECONFIG.CREATE,
				children: [],
				disabled: false,
			},
		],
		link: '',
	},
	{
		title: 'Equipment',
		children: [
			{
				title: 'Antenna',
				link: '',
				children: [
					{
						title: 'Show and edit antennas',
						link: APP_ROUTES.UTILITIES.ANTENNAS,
						children: [],
					},
					{
						title: 'Create antenna',
						link: APP_ROUTES.UTILITIES.ANTENNA_CREATE,
						children: []
					},
				],
			},
			{
				title: 'Radome',
				link: '',
				children: [
					{
						title: 'Show and edit radomes',
						link: APP_ROUTES.UTILITIES.RADOMES,
						children: []
					},
					{
						title: 'Create radome',
						link: APP_ROUTES.UTILITIES.CREATE_RADOME,
						children: []
					},
				],
			},
			{
				title: 'Receiver',
				children: [],
				link: APP_ROUTES.UTILITIES.RECEIVERS,
			},
			{
				title: 'External clock',
				children: [],
				link: APP_ROUTES.UTILITIES.EXTERNAL_CLOCKS,
				disabled: true,
			},
			{
				title: 'Monument',
				children: [],
				link: APP_ROUTES.UTILITIES.MONUMENTS,
				disabled: true,
			},
			{
				title: 'Network components',
				children: [],
				link: APP_ROUTES.UTILITIES.NETWORKCOMPONENTS,
				disabled: true,
			},
			{
				title: 'Collocated instruments',
				children: [],
				link: APP_ROUTES.UTILITIES.COLLOCATED_INSTRUMENTS,
				disabled: true,
			},
		],
		link: '',
	},
	{
		title: 'Log messages',
		children: [],
		link: APP_ROUTES.LOGGMESSAGES,
		disabled: true,
	},
	{
		title: 'Misc',
		children: [
			{
				title: 'Images',
				link: '',
				children: [
					{
						title: 'Show and edit images',
						link: APP_ROUTES.OTHER.IMAGES.SHOW_IMAGES,
						children: [],
					},
					{
						title: 'Create images',
						link: APP_ROUTES.OTHER.IMAGES.CREATE_IMAGE,
						children: [],
					},
				],
			},
			{
				title: 'Users',
				children: [],
				link: APP_ROUTES.OTHER.USERS,
				disabled: true,
			},
			{
				title: 'Documents',
				children: [],
				link: APP_ROUTES.OTHER.DOCUMENTS,
				disabled: true,
			},
			{
				title: 'Interference',
				children: [],
				link: APP_ROUTES.OTHER.INTERFERENCES,
				disabled: true,
			},
			{
				title: 'Contact person',
				children: [],
				link: APP_ROUTES.OTHER.CONTACTPERSONS,
				disabled: true,
			},
			{
				title: 'Reports',
				children: [
					{
						title: 'Sinex-Sitelog',
						children: [],
						link: APP_ROUTES.OTHER.REPORTS.SINEX_SITELOG,
						disabled: true,
					},
					{
						title: 'IGS-Sitelog',
						children: [],
						link: APP_ROUTES.OTHER.REPORTS.IGS_SITELOG,
						disabled: true,
					},
					{
						title: 'Siteinfo',
						children: [],
						link: APP_ROUTES.OTHER.REPORTS.SITEINFO,
						disabled: true,
					},
					{
						title: 'Coordinate report',
						children: [],
						link: APP_ROUTES.OTHER.REPORTS.COORDINATE_REPORT,
						disabled: true,
					},
				],
				link: '',
				disabled: true,
			},
			{
				title: 'IP addresses',
				children: [
					{
						title: 'Show all connected to site and siteconfig',
						children: [],
						link: APP_ROUTES.OTHER.IP_ADDRESSES.SHOW_ALL_CONNECTED_SITE_CONFIG,
						disabled: true,
					},
					{
						title: 'Show all connected to site and network components',
						children: [],
						link: APP_ROUTES.OTHER.IP_ADDRESSES.SHOW_ALL_CONNECTED_SITE_NETWORKCOMPONENTS,
						disabled: true,
					},
				],
				link: '',
				disabled: true,
			},
			{
				title: 'Timeseries',
				children: [
					{
						title: 'Show timeseries',
						children: [],
						link: APP_ROUTES.OTHER.TIME_SERIES.SHOW_TIME_SERIES,
						disabled: true,
					},
					{
						title: 'Import timeseries',
						children: [],
						link: APP_ROUTES.OTHER.TIME_SERIES.IMPORT_TIME_SERIES,
						disabled: true,
					},
				],
				link: '',
				disabled: true,
			},
			{
				title: 'Types register change',
				children: [],
				link: APP_ROUTES.OTHER.TYPES_REGISTER_CHANGE,
			},
		],
		link: '',
	},
	{
		title: 'Help',
		children: [],
		link: EXTERNAL_ROUTES.HELP.inApp,
		disabled: true,
	},
];
