import type { CreateInfDocType, InfDocType } from '../../../redux/InfDoc/infdoc.types';
import { createConfig, createDefaultValues, createSchema } from './misc';
import { generatePath, useNavigate } from 'react-router-dom';

import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import { APP_ROUTES } from '../../../service/routes/AppRouter.constants';
import Container from '@mui/material/Container';
import { Form } from '../../../components/Form/Form';
import Grid from '@mui/material/Grid';
import MuiPaper from '../../../components/Paper/Paper';
import { getEnvStateData } from '../../../service/envStateData';
import { handleResponse } from '../../../service/requests';
import { infDocMock } from '../../../redux/InfDoc/infDoc.mock';
import { setAlertState } from '../../../redux/Alert/alert.actions';
import { setLoadingStatus } from '../../../redux/Loading/loading.actions';
import { store } from '../../../redux/store';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const submitInfDoc = (cb: (siteId: number) => void) => {
	// We want to allow the calling component to navigate to a page showing the site InfDocs on success,
	// so we accept a callback function and generate the submit handler.
	return async (data: typeof createDefaultValues) => {
		const { id: infDocTypeId } = data.infDocType;
		const { id: siteId } = data.site;
		const { id: siteConfigId = null } = data.siteConfig || {};
		const infDocCreation: CreateInfDocType = {
			timestampWritten: data.timestampWritten,
			writtenBy: data.writtenBy,
			description: data.description,
			infDocTypeId,
			siteId,
			siteConfigId,
			files: data.files,
		};

		// Uploading images require use of multipart/form-data, so we prepare the request in a different
		// way.
		const formData = new FormData();

		formData.set('timestampWritten', infDocCreation.timestampWritten);
		formData.set('writtenBy', infDocCreation.writtenBy);
		formData.set('description', infDocCreation.description);
		formData.set('infDocTypeId', infDocCreation.infDocTypeId.toString(10));
		formData.set('siteId', infDocCreation.siteId.toString(10));
		if (infDocCreation.siteConfigId !== null) {
			formData.set('siteConfigId', infDocCreation.siteConfigId.toString(10));
		}
		for (const file of infDocCreation.files) {
			formData.append('files', file);
		}

		const dispatch = store.dispatch;

		dispatch(setLoadingStatus(true));

		// By calling fetch directly like this, we avoid sending Content-Type: application/json, which would
		// give an error. Here, fetch generates the correct content-type for the multipart form submission.
		const response = fetch(API_ENDPOINTS.POST_INFDOCS, {
			method: 'POST',
			body: formData,
		});

		const updatedInfDocs = await getEnvStateData(
			handleResponse<InfDocType[]>(response),
			[infDocMock]
		);

		if (updatedInfDocs !== null && updatedInfDocs.length > 0) {
			dispatch(setAlertState({
				severity: 'success',
				open: true,
				title: 'Image creation successful',
				message: 'Navigating to list of images for site with id: ' + siteId + '...',
			}));

			// Allow component to navigate on success
			return cb(siteId);
		}

		return undefined;
	};
};

export const ImageUploadForm: React.FC<{}> = () => {
	const navigate = useNavigate();
	const { control, formState, handleSubmit, reset, trigger } = useForm<typeof createDefaultValues>({
		resolver: yupResolver(createSchema),
		mode: 'onChange',
		values: {
			...createDefaultValues,
		},
		defaultValues: {
			...createDefaultValues,
		},
	});

	const {
		isDirty,
		isSubmitting,
		isSubmitted,
		isSubmitSuccessful,
		isValidating,
		isValid,
	} = formState;

	const shouldValidateOnce = true;

	useEffect(() => {
		if (shouldValidateOnce) {
			trigger();
		}
	}, [shouldValidateOnce, trigger]);

	useEffect(() => {
		reset();
	}, [isSubmitSuccessful, reset]);

	return (
		<Container maxWidth='xl'>
			<MuiPaper>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Container maxWidth='xl'>
							<h1>Create images</h1>
						</Container>
						<Form
							control={control}
							onSubmit={handleSubmit(submitInfDoc((siteId) => {
								// Submission was successful, so we navigate to a search page showing all InfDocs for the
								// relevant site.
								const url = generatePath(APP_ROUTES.OTHER.IMAGES.SHOW_IMAGES) + `?siteId=${siteId}`;

								navigate(url);
							}))}
							isDirty={isDirty}
							isSubmitting={isSubmitting}
							isSubmitted={isSubmitted}
							isValidating={isValidating}
							isValid={isValid}
							readOnlyValues={[]}
							{...createConfig}
						/>
					</Grid>
				</Grid>
			</MuiPaper>
		</Container>
	);
};
