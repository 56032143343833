import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatetimeFormat, momentFormat } from '../../../service/moment';
import Box from '@mui/material/Box';
import { CustomPagination } from '../../../components/Pagination/CustomPagination';
import React from 'react';
import { Receiver } from '../../../redux/Receiver/receiver.types';

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID',
		flex: 1,
	},
	{
		field: 'timestampValidFrom',
		headerName: 'Valid from',
		flex: 1,
		renderCell: params =>
			params.value ? momentFormat({ date: params.value, format: DatetimeFormat.DATE }) : params.value
	},
	{
		field: 'preparedBy',
		headerName: 'Prepared by',
		flex: 1,
	},
	{
		field: 'toFirmwareVersion',
		headerName: 'To firmware version',
		flex: 1,
	},
	{
		field: 'toElevationCutoffAngle',
		headerName: 'To elevation cutoff angle',
		flex: 1,
	},
];

const ReceiverHistory = (receiver: Receiver) : JSX.Element => {

	return (
		<Box height={800}>
			<DataGrid
				rows={receiver.receiverHists}
				columns={columns}
				sx={{
					'& .MuiDataGrid-columnHeader': {
						color: 'white',
						backgroundColor: 'primary.dark',
						'& .MuiDataGrid-columnHeaderTitle': {
							fontWeight: 'bold',
						}
					},
					'& .MuiIconButton-root': {
						color: 'white',
					},
					'& .MuiDataGrid-cell': {
						padding: '10px',
					},
					'& .MuiDataGrid-cell:focus': {
						outline: 'initial',
					},
				}}
				getRowHeight={() => 'auto'}
				disableSelectionOnClick={true}
				components={{
					Pagination: CustomPagination,
				}} />
		</Box>
	);
};

export default ReceiverHistory;
