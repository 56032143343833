import React from 'react';

const Loggmessages: React.FC = () => {
	return (
		<>
			<h1>Logmessages</h1>
		</>
	);
};

export default Loggmessages;
