import React, { useEffect, useState } from 'react';
import { fetchSiteDataById, resetSiteData } from '../../redux/Site/siteData.actions';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { APP_ROUTES } from '../../service/routes/AppRouter.constants';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RouteTabs from '../../components/RouteTabs/RouteTabs';
import { SiteConfigMinimal } from '../../redux/SiteConfig/siteConfig.types';
import { SiteMinimal } from '../../redux/Site/site.types';
import TextField from '@mui/material/TextField';
import { dashboardRoutes } from './Dashboard.constants';
import { dashboardStyles } from './Dashboard.styles';
import { fetchSiteList } from '../../redux/Site/siteList.actions';
import { selectSiteData } from '../../redux/Site/siteData.slice';
import { selectSiteList } from '../../redux/Site/siteList.slice';

const SiteSelection: React.FC<{ sites: SiteMinimal[], loading: boolean }> = ({ sites, loading }) => {
	const classes = dashboardStyles;

	const navigate = useNavigate();

	const { siteId } = useParams<{ siteId: string }>();

	let selectedSite: SiteMinimal | null = null;

	for (const site of sites) {
		if (siteId !== undefined && site.id === Number.parseInt(siteId, 10)) {
			selectedSite = site;
		}
	}

	return (
		<Autocomplete
			id={'site-name'}
			options={sites}
			loading={loading}
			getOptionLabel={(siteOption: SiteMinimal) => siteOption.siteName}
			value={selectedSite}
			onChange={
				(event: React.ChangeEvent<{}>, values: SiteMinimal | null) => {
					if (values !== null) {
						navigate(generatePath(APP_ROUTES.DASHBOARD.SITEINFO, { siteId: values.id }));
					}
				}
			}
			renderInput={(params) => <TextField {...params} label={'Site'} variant={'outlined'} />}
			sx={ classes.siteDropdown }
		/>
	);
};

const SiteConfigSelection: React.FC<{ siteConfigs: SiteConfigMinimal[], loading: boolean }> = ({ siteConfigs, loading }) => {
	const classes = dashboardStyles;

	const navigate = useNavigate();

	const { siteConfigId } = useParams<{ siteConfigId: string }>();

	let selectedSiteConfig: SiteConfigMinimal | null = null;

	for (const siteConfig of siteConfigs) {
		if (siteConfigId !== undefined && siteConfig.id === Number.parseInt(siteConfigId, 10)) {
			selectedSiteConfig = siteConfig;
		}
	}

	return (
		<Autocomplete
			id={'site-config'}
			options={siteConfigs}
			loading={loading}
			getOptionLabel={(siteConfigOption: SiteConfigMinimal) => siteConfigOption.fourCharId}
			value={selectedSiteConfig}
			onChange={
				(event: React.ChangeEvent<{}>, values: SiteConfigMinimal | null) => {
					if (values !== null) {
						navigate(generatePath(APP_ROUTES.DASHBOARD.SITECONFIGINFO.DETAILS, { siteId: values.siteId, siteConfigId: values.id }));
					}
				}
			}
			renderInput={(params) => <TextField {...params} label={'Site Config'} variant={'outlined'}/>}
			sx={ classes.siteDropdown }
		/>
	);
};

const Details: React.FC = () => {
	const { siteId } = useParams<{ siteId: string }>();

	const dispatch = useAppDispatch();

	useEffect(() => {
		const fetchData = async () => {
			if (siteId !== undefined) {
				dispatch(resetSiteData());
				await dispatch(fetchSiteDataById(Number.parseInt(siteId, 10)));
			}
		};

		fetchData();
	}, [siteId, dispatch]);

	const siteData = useAppSelector(selectSiteData);

	if (siteId === undefined) {
		return (
			<Alert severity='info'>{'Please select a site or site config.'}</Alert>
		);
	}

	if (siteData === null) {
		return (
			<Alert severity='warning'>{'Loading site data...'}</Alert>
		);
	}

	return (
		<RouteTabs
			routes={
				dashboardRoutes.map(route => ({
					...route,
					url: generatePath(route.id, { siteId })
				}))
			}/>
	);
};

const Dashboard: React.FC = () => {
	const classes = dashboardStyles;

	const dispatch = useAppDispatch();

	const [shouldLoadSites, setShouldLoadSites] = useState<Boolean>(true);
	const siteList = useAppSelector(selectSiteList);

	useEffect(() => {
		const fetchData = async () => {
			await dispatch(fetchSiteList());
			setShouldLoadSites(false);
		};

		if (shouldLoadSites) {
			fetchData();
		}
	}, [dispatch, shouldLoadSites]);

	return (
		<Box sx={ classes.root }>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container direction={'row'} spacing={2}>
						<Grid item xs={2} sx={ classes.sitesDropdownRow }>
							<SiteSelection sites={siteList.siteList} loading={siteList.siteList.length === 0} />
						</Grid>
						<Grid item xs={2} sx={ classes.sitesDropdownRow }>
							<SiteConfigSelection siteConfigs={siteList.siteConfigList} loading={siteList.siteConfigList.length === 0} />
						</Grid>
						<Grid item xs={12}>
							<Details />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Dashboard;
