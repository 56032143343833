import { Backdrop, CircularProgress } from '@mui/material';

import React from 'react';
import { selectLoadingStatus } from '../../redux/Loading/loading.slice';
import { theme } from '../../service/styles/global.styles';
import { useAppSelector } from '../../redux/hooks';

const Loader: React.FC = () => {
	const loadingStatus = useAppSelector(selectLoadingStatus);

	return (
		<Backdrop open={loadingStatus} style={{ zIndex: theme.zIndex.drawer + 1 }}>
			<CircularProgress />
		</Backdrop>
	);
};

export default Loader;
