import { AddReceiverTypeType, ReceiverType } from './receiverType.types';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { receiverTypeListMock, receiverTypeMock } from './receiverType.mock';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { getEnvStateData } from '../../service/envStateData';
import { receiverTypeSlice } from './receiverType.slice';

export const { setReceiverTypeList, addReceiverType, updateReceiverType } = receiverTypeSlice.actions;

/**
 * Updates the store with all receiver types obtained from the api
 */
export const fetchReceiverTypeList = () => {
	return async (dispatch: ApplicationDispatch) => {
		const receiverTypeList: ReceiverType[] | null =
			await getEnvStateData(getRequest<ReceiverType[]>(API_ENDPOINTS.GET_RECEIVERTYPE), receiverTypeListMock);

		const result = receiverTypeList && { receiverTypeList };

		return dispatch(setReceiverTypeList(result));
	};
};

/**
 * Adds a receiver type with the api and updates the store with the newly obtained receiver type
 */
export const addReceiverTypeElement = ({ receiverTypeName }: AddReceiverTypeType) => {
	return async (dispatch: ApplicationDispatch) => {
		const receiverType: ReceiverType | null =
			await getEnvStateData(
				postRequest<ReceiverType, AddReceiverTypeType>(
					API_ENDPOINTS.POST_RECEIVERTYPE,
					{ receiverTypeName }
				),
				receiverTypeMock,
			);

		return dispatch(addReceiverType(receiverType));
	};
};

/**
 * Updates an existing receiver type with the api and updates the store with the updated receiver type
 */
export const updateReceiverTypeElement = (oldReceiverType: ReceiverType) => {
	return async (dispatch: ApplicationDispatch) => {
		const newReceiverType: ReceiverType | null =
			await getEnvStateData(
				putRequest<ReceiverType, ReceiverType>(
					API_ENDPOINTS.PUT_RECEIVERTYPE,
					oldReceiverType,
				),
				receiverTypeMock,
			);

		return dispatch(updateReceiverType(newReceiverType));
	};
};
