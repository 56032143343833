export interface RegexExpType {
	formatDisplay?: string;
	expression: RegExp;
	errorMsg: string;
}

export const regexList = {
	dateTimePrettyFormat: {
		formatDisplay: 'dd.mm.yyyy HH:MM',
		expression: /^([012][0-9]|3[01])\.(([0]{1}[1-9])|([1]{1}[012]{1}))\.(19[0-9]{2}|20[0-9]{2}) ([01][0-9]|[2][0-3]):([0-5][0-9])$/,
		errorMsg: 'Invalid date format!',
	},
	preparedBy: {
		formatDisplay: '1-10 characters',
		expression: /^(.{1,10})$/,
		errorMsg: 'Too many / few characters!',
	},
	radomeId: {
		expression: /^(\d+)$/,
		errorMsg: 'Invalid data! Only Integers',
	},
	cutOffAngle: {
		formatDisplay: '0-3 digits',
		expression: /^(\d{0,3})$/,
		errorMsg: 'Invalid data! Only Integers',
	},
	freepass: {
		expression: /.*/,
		errorMsg: '',
	},
	requestError: {
		expression: /^Error: ([0-9]{3}) - (.+)$/,
		errorMsg: '',
	},
	// Allows 4 uppercase letters, numbers or a combination of those
	fourUppercaseChars: {
		formatDisplay: '4 uppercase characters',
		expression: /^([A-Z0-9]{4})$/,
		errorMsg: 'Invalid data',
	},
};
