import { AntRefPointType, antRefPointTypeTemplate } from '../AntRefPointType/antRefPointType.types';
import { AntennaHist, antennaHistTemplate } from '../AntennaHist/antennaHist.types';
import { AntennaType, antennaTypeTemplate } from '../AntennaType/antennaType.types';
import { Radome, radomeTemplate } from '../Radome/radome.types';
import { SiteMinimal, siteMinimalTemplate } from '../Site/site.types';

/**
 * Represents a single antenna
 */
export interface Antenna {
	id: number,
	fourCharIds: string[],
	antCalibrationIds: number[],
	antennaType: AntennaType,
	antRefPointType: AntRefPointType,
	radome: Radome | null,
	timestampRegistered: string,
	timestampGuarantee: string | null,
	serialNumber: string,
	exist: number,
	additionalInformation: string | null,
	equipmentLabel: string | null,
	antennaHists: AntennaHist[],
	site: SiteMinimal | null
}

/**
 * Represents an Antenna that is used to update an instance
 */
export type AntennaUpdateType = Omit<Antenna,
	'fourCharIds' |
	'antennaType' |
	'antRefPointType' |
	'radome' |
	'antCalibrationIds' |
	'timestampRegistered' |
	'antennaHists' |
	'site'
	> & {
	antennaTypeName: string,
	antRefPointTypeName: string,
	radomeId: number | null,
	timestampValidFrom: string,
	siteId: number | null
}

/**
 * Represents a simplified version of antenna
 */
export type AntennaSummaryType = Omit<Antenna,
	'antCalibrationIds' |
	'antennaType' |
	'antRefPointType' |
	'antennaHists'
	> & {
	hasAntCalibrations: boolean,
	antennaTypeName: string,
	antRefPointType: string,
	radomeId: number,
	radomeTypeName: string,
}

export type AntennaCreateType = Omit<Antenna,
	'id' |
	'fourCharIds' |
	'antennaType' |
	'antRefPointType' |
	'radome' |
	'antCalibrationIds' |
	'timestampRegistered' |
	'antennaHists' |
	'site'
	> & {
	antennaTypeName: string,
	antRefPointTypeName: string,
	radomeId: number | null,
	siteId: number | null
}

export const antennaTemplate: Antenna = {
	id: 0,
	fourCharIds: [],
	antCalibrationIds: [],
	antennaType: antennaTypeTemplate,
	antRefPointType: antRefPointTypeTemplate,
	radome: radomeTemplate,
	timestampRegistered: '',
	timestampGuarantee: '',
	serialNumber: '',
	exist: 0,
	additionalInformation: '',
	equipmentLabel: '',
	antennaHists: [],
	site: siteMinimalTemplate,
};
