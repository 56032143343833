/**
 * Represents a single extClockType
 */
export interface ExtClockType {
	id : number;
	extClockTypeName: string;
}

/**
 * Represents a list of extClock
 */
export interface ExtClockTypeListType {
	extClockTypeList: ExtClockType[];
}

/**
 * Represents an id-less extClockType, suitable for adding new extClockType with the api
 */
export type AddExtClockTypeType = Omit<ExtClockType, 'id'>;

export const extClockTypeTemplate = {
	id: 0,
	extClockTypeName: ''
};
