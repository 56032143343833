export interface StorageLocation {
	id: number,
	path: string,
	pathDescription: string,
}

export const storageLocationTemplate: StorageLocation = {
	id: 0,
	path: '',
	pathDescription: '',
};
