import './index.css';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { theme } from './service/styles/global.styles';

document.title = 'Sestasjon';
const root = createRoot(document.getElementById('root') || document.body);

root.render(
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<App />
		</Provider>
	</ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
