import { Antenna, AntennaCreateType, AntennaUpdateType } from './antenna.types';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { antennaMock } from './antenna.mock';
import { antennaSlice } from './antenna.slice';
import { getEnvStateData } from '../../service/envStateData';

export const { setAntennaState, resetAntennaState } = antennaSlice.actions;

/**
 * Fetches an antenna based on its id
 * @param antennaId
 */
export const fetchAntenna = ({ antennaId }: { antennaId: number }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.GET_ANTENNA.replace('{id}', encodeURIComponent(antennaId));
		const antenna = await getEnvStateData(getRequest<Antenna>(url), antennaMock);

		if (antenna === null) {
			throw new Error(`Cannot find antenna with id: ${antennaId}`);
		}

		return dispatch(setAntennaState(antenna));
	};
};

/**
 * Updates an antenna
 *
 * WARNING: This function assumes that front end validation has already been done.
 */
export const updateAntenna = ({ antenna }: {antenna: AntennaUpdateType }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.PUT_ANTENNA;
		const updatedAntenna = await getEnvStateData(putRequest<Antenna, typeof antenna>(url, antenna), antennaMock);

		if (updatedAntenna === null) {
			throw new Error(`Cannot update antenna with id ${antenna.id}`);
		}

		return dispatch(setAntennaState(updatedAntenna));
	};
};

/**
 * Creates a new antenna
 */
export const createAntenna = ({ antenna }: {antenna: AntennaCreateType}) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.POST_ANTENNA;
		const createdAntenna = await getEnvStateData(postRequest<Antenna, typeof antenna>(url, antenna), antennaMock);

		if (createdAntenna === null) {
			throw new Error('Could not create antenna with serial number: ' + antenna.serialNumber);
		}

		return dispatch(setAntennaState(createdAntenna));
	};
};
