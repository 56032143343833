import { FormControl, FormHelperText, Grid, Input, InputLabel } from '@mui/material';

import React from 'react';
import { RegexExpType } from '../../../../service/regex';

export interface FormListType {
	label: string;
	keyName: string;
	regex: RegexExpType;
	number: boolean;
	defaultValue?: any;
	readOnly?: boolean;
	renderer?: (element: string) => string | JSX.Element;
}

export const validateForm = (formValues: any, formList: FormListType[]): { tmpErrObj: {} | undefined; errorMsgStatus: boolean; } => {
	const tmpErrObj = {};
	const tmpValue: any = formValues;

	let errorMsgStatus = false;

	formList.forEach((checkElement: FormListType) => {
		if (!checkElement.regex.expression.test(tmpValue[checkElement.keyName])) {
			Object.assign(tmpErrObj, { [checkElement.keyName]: checkElement.regex.errorMsg });
			errorMsgStatus = true;
		}

	});

	if (!errorMsgStatus) {
		return {
			tmpErrObj: undefined,
			errorMsgStatus
		};
	}

	return {
		tmpErrObj: tmpErrObj,
		errorMsgStatus
	};
};

// Returns a Form for adding input values
export const addForm = (formValues: any, formList: FormListType[], error: any, handleFormChange: Function): JSX.Element => {

	return <Grid item xs={12}>
		{
			formList.map((formElement: FormListType) => {
				const errorState = error !== undefined && Boolean(error[formElement.keyName]);

				return <Grid key={formElement.keyName} item xs={12}>
					<FormControl style={{ marginBottom: '15px' }} error={errorState}>
						<InputLabel htmlFor={formElement.label}>{formElement.label}</InputLabel>
						<Input
							id={formElement.label}
							aria-describedby={formElement.label}
							type={formElement.number ? 'number' : undefined}
							defaultValue={formElement.defaultValue}
							onChange={handleFormChange(formElement.keyName)}
							readOnly={formElement.readOnly}
						/>
						<FormHelperText id={formElement.label}>
							{formElement.regex.formatDisplay !== undefined ? formElement.regex.formatDisplay : ''}
						</FormHelperText>
					</FormControl>
				</Grid>;
			})
		}
	</Grid>;
};

export const getAttributeList = (formList: FormListType[], propKey: string) => {
	const tmp: any[] = [];

	formList.forEach((formElement: any) => tmp.push(formElement[propKey]));

	return tmp;
};
