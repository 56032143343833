import * as yup from 'yup';
import React, { useEffect } from 'react';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { AntennaSummaryType } from '../../redux/Antenna/antenna.types';
import { Cable } from '../../redux/Cable/cable.types';
import { ExtClock } from '../../redux/ExtClock/extClock.types';
import { Form } from '../../components/Form/Form';
import { FormConfiguration } from '../../components/Form/Form.types';
import Loader from '../../components/Loader/Loader';
import { MonumentSummary } from '../../redux/Monument/monument.types';
import { ReceiverSummary } from '../../redux/Receiver/receiver.types';
import { SiteConfigStatType } from '../../redux/SiteConfigStatType/siteConfigStatType.types';
import { SiteMinimal } from '../../redux/Site/site.types';
import { StorageLocation } from '../../redux/StorageLocation/storageLocation.types';
import { momentPrettyFormat } from '../../service/moment';
import { selectSiteConfigState } from '../../redux/SiteConfig/siteConfig.slice';
import { setAlertState } from '../../redux/Alert/alert.actions';
import { siteConfigTemplate } from '../../redux/SiteConfig/siteConfig.types';
import { store } from '../../redux/store';
import { updateSiteConfig } from '../../redux/SiteConfig/siteConfig.actions';
import { useAppSelector } from '../../redux/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

let selectedSiteId = 0;

const schema = yup.object().shape({
	site: yup.object().required(),
	monument: yup.object().nullable(),
	markRefPoint: yup.object().nullable(),
	extClock: yup.object().nullable(),
	timestampRegistered: yup.string().required(),
	timestampOperational: yup.string().nullable(),
	timestampClosed: yup.string().nullable(),
	timestampEstablished: yup.string().nullable(),
	fourCharId: yup.string().required(),
	preparedBy: yup.string().required(),
	sourceId: yup.number().required(),
	receiver: yup.object().nullable(),
	antenna: yup.object().nullable(),
	cable: yup.object().nullable(),
	siteConfigStatType: yup.object().required(),
	markAntRefPoint: yup.object().nullable(),
	siteConfigHists: yup.array().of(yup.object().required())
		.required(),
	tags: yup.array().of(yup.object().required())
		.nullable(),
	changedBy: yup.string().nullable(),
	additionalInformation: yup.string().nullable(),
	storageLocation: yup.object().nullable(),
	dataFlow: yup.number().required(),
});

const config: Omit<FormConfiguration, 'header' | 'control' | 'onSubmit'> = {
	fields: [
		{
			type: 'text',
			name: 'fourCharId',
			label: 'Fourcharid',
			id: 'sc-fourcharid',
			helperText: '',
			required: true,
		},
		{
			type: 'autocomplete',
			name: 'site',
			label: 'Site',
			id: 'sc-site',
			required: true,
			helperText: 'Select site to refresh components (work in progress)',
			isOptionEqualToValue: (option, value) => option.id === value.id ? selectedSiteId = value.id : 0,
			getOptionLabel: option => option?.siteName || String(option?.id || ''),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_SITE_SUMMARIES);
				const sites: SiteMinimal[] = await res.json();

				return sites;
			},
		},
		{
			type: 'autocomplete',
			name: 'siteConfigStatType',
			label: 'Status',
			id: 'sc-siteConfigStatType',
			helperText: '',
			required: true,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option =>
				option?.siteConfigStatTypeName || '',
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_SITECONFIGSTATTYPES);
				const statTypes: SiteConfigStatType[] = await res.json();

				return statTypes;
			}
		},
		{
			type: 'autocomplete',
			name: 'antenna',
			label: 'Antenna',
			id: 'sc-antenna',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option =>
				(`${option?.antennaTypeName || option?.antennaType?.antennaTypeName || ''}${option?.id
					? ' - '
					: ''}${option?.serialNumber}` || '') +
				(option?.site?.siteName !== ''
					? ` [ ${option?.site?.siteName || ''} ] `
					: ''),
			getOptions: async () => {
				const url = API_ENDPOINTS.GET_ANTENNAS_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const antennas: AntennaSummaryType[] = await res.json();

				return antennas;
			}
		},
		{
			type: 'autocomplete',
			name: 'receiver',
			label: 'Receiver',
			id: 'sc-receiver',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option =>
				(`${option?.receiverTypeName || option?.receiverType?.receiverTypeName || ''}${option?.id
					? ' - '
					: ''}${option?.serialNumber}` || '') +
				(option?.site?.siteName !== ''
					? ` [ ${option?.site?.siteName || ''} ] `
					: ''),
			getOptions: async () => {
				const url = API_ENDPOINTS.GET_RECEIVERS_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const receivers: ReceiverSummary[] = await res.json();

				return receivers;
			}
		},
		{
			type: 'autocomplete',
			name: 'cable',
			label: 'Cable',
			id: 'sc-cable',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option?.id === value?.id,
			getOptionLabel: option =>
				option?.id !== 0
					? `${option?.cableType?.cableTypeName} - ${option?.length}m [${option?.fourCharIds}]`
					: '',
			getOptions: async () => {
				const url = API_ENDPOINTS.GET_CABLES_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const cables: Cable[] = await res.json();

				return cables;
			}
		},
		{
			type: 'autocomplete',
			name: 'monument',
			label: 'Monument',
			id: 'sc-monument',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option?.id === value?.id,
			getOptionLabel: option =>
				`${option?.monumentType?.monumentTypeName || option?.monumentTypeName || ''}` +
				`${option?.id ? ' - ' : ''}` +
				`${option?.foundationTypeName || ''}` +
				`${option?.site !== null ? ' [' + option?.site?.siteName + ']' : ''}` || '',
			getOptions: async () => {

				const url = API_ENDPOINTS.GET_MONUMENTS_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const monuments: MonumentSummary[] = await res.json();

				return monuments;
			}
		},
		{
			type: 'autocomplete',
			name: 'extClock',
			label: 'External clock',
			id: 'sc-extClock',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option =>
				(`${option?.extClockType?.extClockTypeName || ''}${option?.id
					? ' - '
					: ''}${option?.serialNumber}` || '') +
				(option?.site?.siteName !== ''
					? ` [ ${option?.site?.siteName || ''} ] `
					: ''),
			getOptions: async () => {
				const url = API_ENDPOINTS.GET_EXTCLOCKS_AVAILABLE.replace('{siteId}', encodeURIComponent(selectedSiteId !== 0 ? selectedSiteId : 0));
				const res = await fetch(url);
				const extclocks: ExtClock[] = await res.json();

				return extclocks;
			}
		},
		{
			type: 'autocomplete',
			name: 'storageLocation',
			label: 'Storage location',
			id: 'sc-storageLocation',
			helperText: '',
			required: false,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option =>
				option?.path,
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_STORAGELOCATIONS);
				const storageLocations: StorageLocation[] = await res.json();

				return storageLocations;
			}
		},
		{
			type: 'checkbox',
			name: 'cpos',
			label: 'CPOS (to be replaced with tags)',
			id: 'sc-cpos',
		},
		{
			type: 'datetime',
			name: 'timestampEstablished',
			label: 'Established',
			id: 'sc-timestampEstablished',
			helperText: '',
			required: false,
		},
		{
			type: 'checkbox',
			name: 'rinex2',
			label: 'Rinex2 (to be repalced with tags)',
			id: 'sc-rinex2',
		},
		{
			type: 'text',
			name: 'additionalInformation',
			label: 'Additional information',
			id: 'sc-additionalInformation',
			helperText: '',
			required: false,
		},
		{
			type: 'checkbox',
			name: 'rinex3',
			label: 'Rinex3 (to be replaced with tags)',
			id: 'sc-rinex3',
		},
		{
			type: 'autocomplete',
			name: 'tags',
			label: 'Tags',
			id: 'sc-tags',
			multiple: true,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option.tagText || String(option.id),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_TAGS);
				const tags: any[] = await res.json();

				return tags;
			},
		},
	]
};

const SubmitSiteConfig = async (data: typeof siteConfigTemplate) => {
	const dispatch = await store.dispatch;

	const cposElement = document.getElementById('sc-cpos') as HTMLInputElement;
	const rinex3Element = document.getElementById('sc-rinex3') as HTMLInputElement;
	const rinex2Element = document.getElementById('sc-rinex2') as HTMLInputElement;

	let df: number = 0x0000;

	df = cposElement.checked ? df | 0x0001 : df & ~0x0001;
	df = rinex2Element.checked ? df | 0x0002 : df & ~0x0002;
	df = rinex3Element.checked ? df | 0x0004 : df & ~0x0004;

	await dispatch(updateSiteConfig({ siteconfig: {
		id: data.id,
		fourCharId: data.fourCharId,
		siteId: data.site.id,
		receiverId: data.receiver?.id || null,
		antennaId: data.antenna?.id || null,
		cableId: data.cable?.id || null,
		monumentId: data.monument?.id || null,
		markAntRefPointId: data.markAntRefPoint?.id || null,
		extClockId: data.extClock?.id || null,
		additionalInformation: data.additionalInformation,
		timestampEstablished: data.timestampEstablished,
		siteconfigStatTypeId: data.siteConfigStatType.id,
		dataFlow: df,
		storageLocationId: data.storageLocation?.id || null,
		tagIds: data.tags?.map(tag => tag.id) || null
	} }));

	dispatch(setAlertState({
		severity: 'success',
		open: true,
		title: 'Success',
		message: 'Successfully updated  siteconfig with id: ' + data.id
	}));
};

const SiteConfigUpdate: React.FC<{}> = () => {
	const siteConfig = useAppSelector(selectSiteConfigState);

	const { control, formState, handleSubmit, reset, trigger } = useForm<typeof siteConfigTemplate>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		values: {
			...siteConfigTemplate,
			...siteConfig,
		},
		defaultValues: {
			...siteConfigTemplate,
		},
	});

	const {
		isDirty,
		isSubmitting,
		isSubmitted,
		isSubmitSuccessful,
		isValidating,
		isValid,
	} = formState;

	const shouldValidateOnce = siteConfig !== null;

	useEffect(() => {
		if (shouldValidateOnce) {
			trigger();
		}
	}, [shouldValidateOnce, trigger]);

	useEffect(() => {
		reset();
	}, [isSubmitSuccessful, reset]);

	if (siteConfig === null || siteConfig === undefined) {
		return <Loader/>;
	}

	return (
		<Form
			control={control}
			onSubmit={handleSubmit(SubmitSiteConfig)}
			isDirty={isDirty}
			isSubmitting={isSubmitting}
			isSubmitted={isSubmitted}
			isValidating={isValidating}
			isValid={isValid}
			readOnlyValues={[
				{ label: 'Timestamp Registered (UTC Time)', value: momentPrettyFormat(siteConfig?.timestampRegistered) },
				{ label: 'MarkRefPoint', value: siteConfig?.markRefPoint !== null
					? siteConfig.markRefPoint.referenceSystemPosRef.referenceSystemName + ': ' +
						'X: ' + siteConfig.markRefPoint?.posXRef +
						' | Y: ' + siteConfig.markRefPoint.posYRef +
						' | Z: ' + siteConfig.markRefPoint.posZRef : 'none' },
				{ label: 'Eccentricity', value: siteConfig?.markAntRefPoint !== null
					? 'DN: ' + siteConfig.markAntRefPoint.deltaNorth +
						' | DE: ' + siteConfig.markAntRefPoint.deltaEast +
						' | DH: ' + siteConfig.markAntRefPoint.deltaHeight : 'none' },
			]}
			{...config}
		/>
	);

};

export default SiteConfigUpdate;
