import { FormListType, addForm, getAttributeList, validateForm } from './HistService';
import React, { useState } from 'react';
import { ReceiverHist, ReceiverHistCreateParamType } from '../../../../redux/ReceiverHist/receiverHist.types';
import {
	addReceiverHistElement,
	deleteReceiverHistElement,
	putReceiverHistElement,
	putSiteConfigHistTimestampUpdated,
} from '../../../../redux/Site/siteData.actions';
import { groupBy, sortGrouped } from '../../../../service/groupListService';

import MuiAccordion from '../../../../components/Accordion/Accordion';
import MuiTable from '../../../../components/Table/Table/Table';
import { SiteConfig } from '../../../../redux/SiteConfig/siteConfig.types';
import { SiteConfigHist } from '../../../../redux/SiteConfigHist/siteConfigHist.types';
import { TablePropsType } from '../../../../components/Table/Table.types';
import { momentPrettyFormat } from '../../../../service/moment';
import { regexList } from '../../../../service/regex';
import { selectSiteData } from '../../../../redux/Site/siteData.slice';
import { useAppSelector } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';

const receiverHistTableFormList: FormListType[] = [
	{
		label: 'Valid from',
		keyName: 'timestampValidFrom',
		regex: regexList.dateTimePrettyFormat,
		number: false,
	},
	{
		label: 'Established by',
		keyName: 'preparedBy',
		regex: regexList.preparedBy,
		number: false,
	},
	{
		label: 'To firmware version',
		keyName: 'toFirmwareVersion',
		regex: regexList.freepass,
		number: false,
	},
	{
		label: 'To elevation cutoff angle',
		keyName: 'toElevationCutoffAngle',
		regex: regexList.cutOffAngle,
		number: true,
	},
];

const receiverHist2TableElement = (receiverHist: ReceiverHist): { id: number, [key: string]: any } => ({
	'Valid from': momentPrettyFormat(receiverHist.timestampValidFrom),
	'Established by': receiverHist.preparedBy,
	'From firmware version': receiverHist.fromFirmwareVersion,
	'To firmware version': receiverHist.toFirmwareVersion,
	'From elevation cutoff angle': receiverHist.fromElevationCutoffAngle,
	'To elevation cutoff angle': receiverHist.toElevationCutoffAngle,
	...receiverHist,
});

interface ReceiverHistTableProps {
	receiverHists: ReceiverHist[];
	tableProps?: Omit<TablePropsType, 'name' | 'head' | 'body'>;
}

const ReceiverHistTable = ({ receiverHists, tableProps = {} }: ReceiverHistTableProps): JSX.Element => {
	return (
		<MuiTable
			name={'Receiver-History'}
			head={getAttributeList(receiverHistTableFormList, 'label')}
			body={receiverHists.map(receiverHist2TableElement)}
			{ ...tableProps }
		/>
	);
};

const SiteConfigReceiverHist = (): JSX.Element => {
	const { siteConfigId } = useParams<{ siteConfigId: string }>();
	// Get fourCharId from siteconfigId
	const siteData = useAppSelector(selectSiteData);

	let fourCharId = '';

	if (siteData !== null && siteConfigId !== undefined) {
		const siteConfigs = siteData.siteConfigs;

		for (const siteConfig of siteConfigs) {
			if (siteConfig.id.toString() === siteConfigId) {
				fourCharId = siteConfig.fourCharId;
			}
		}
	}

	const [formValues, setFormValues] = useState<ReceiverHistCreateParamType>({
		receiverId: 0,
		timestampValidFrom: '',
		preparedBy: '',
		toFirmwareVersion: '',
		toElevationCutoffAngle: 0,
	});

	const handleFormChange = (keyProp: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues, [keyProp]: event.target.value });
	};

	const [error, setError] = useState<any>(undefined);

	const receiverHistDataList: any[] = [];

	let receiverHistGrouped: any[] = [];

	(siteData?.siteConfigs || []).forEach((siteConfig: SiteConfig) => {
		(siteConfig.siteConfigHists || []).forEach((siteConfigHistsElement: SiteConfigHist) => {
			if (siteConfigHistsElement.toReceiver === null) {
				return;
			}

			if (siteConfigHistsElement.toReceiver.receiverHists === undefined) {
				return;
			}

			const fromDate = momentPrettyFormat(siteConfigHistsElement.timestampValidFrom);

			siteConfigHistsElement.toReceiver.receiverHists.forEach((histElement: ReceiverHist) => {
				const tmp = {
					...receiverHist2TableElement(histElement),
					'Config': siteConfig.fourCharId,
					'SiteConfigHist': siteConfigHistsElement,
					'Receiver': siteConfigHistsElement.toReceiver?.serialNumber,
					'Type': siteConfigHistsElement.toReceiver?.receiverType.receiverTypeName,
					'From date': fromDate,
				};

				// Get ReceiverHists for the current fourCharId
				if (tmp.Config === fourCharId) {
					if (receiverHistDataList.find(x => x.id === tmp.id) === undefined) {
						receiverHistDataList.push(tmp);
					}
				}
			});
		});
	});

	if (receiverHistDataList.length > 0) {
		const groupedData = groupBy(receiverHistDataList, 'Receiver');
		const sortedGroupedReceiverHistList = sortGrouped(groupedData, 'Receiver', 'From date');

		sortedGroupedReceiverHistList.forEach((sortedGroupElement: any) => {
			sortedGroupElement.forEach((groupElement: any, index: number) => {
				const toElevationCutoffAngle: number = groupElement['Cutoff Angle'];
				const toFirmwareVersion: string = groupElement.Firmware;

				if (toElevationCutoffAngle === 0 && groupElement['From elevation cutoff angle'] === 0 && index + 1 !== sortedGroupElement.length) {
					groupElement['Cutoff Angle'] = '';
				}
				if (toFirmwareVersion === '0' && groupElement['From firmware version'] === '0' &&
					index + 1 !== sortedGroupElement.length) {
					groupElement.Firmware = '';
				}
			});
		});

		receiverHistGrouped = sortedGroupedReceiverHistList;
	}

	if (receiverHistDataList.length === 0) {
		return <></>;
	}

	if (receiverHistGrouped === undefined) {
		return <></>;
	}

	return (
		<>
			{
				receiverHistGrouped.map((serialNumber: any[], index: number) =>
					<MuiAccordion
						key={serialNumber[0].Receiver}
						id={index}
						name={serialNumber[0].Receiver}
						title={[
							{
								label: 'Receiver:',
								value: serialNumber[0].Receiver,
							},
							{
								label: serialNumber[0].Config,
								value: serialNumber[0].Config,
								badge: true,
							},
							{
								label: 'Type:',
								value: serialNumber[0].Type,
							},
							{
								label: 'Valid from:',
								value: serialNumber[0]['From date'],
								editable: {
									editFuncCallback: putSiteConfigHistTimestampUpdated,
									param: serialNumber[0].SiteConfigHist,
									validation: regexList.dateTimePrettyFormat,
								},
							}
						]}
						body={
							<ReceiverHistTable
								receiverHists={serialNumber}
								tableProps={{
									editable: {
										editFuncCallback: putReceiverHistElement,
										validation: getAttributeList(receiverHistTableFormList, 'regex'),
									},
									deleteFuncCallback: deleteReceiverHistElement,
									addable: {
										title: 'Add receiver history',
										form: {
											modal: addForm(formValues, receiverHistTableFormList, error, handleFormChange),
											values: formValues,
											validate: () => {
												const validatedState = validateForm(formValues, receiverHistTableFormList);

												setError(validatedState.tmpErrObj);

												return validatedState.errorMsgStatus;
											},
										},
										buttonText: 'Add',
										addFuncCallback: addReceiverHistElement,
										param: serialNumber[0].Receiver,
									}
								}}
							/>
						}
					/>
				)
			}
		</>
	);
};

export default SiteConfigReceiverHist;
export { ReceiverHistTable };
