import React from 'react';

const Siteinfo: React.FC = () => {
	return (
		<>
			<h1>Siteinfo</h1>
		</>
	);
};

export default Siteinfo;

