import * as yup from 'yup';
import React, { useEffect } from 'react';
import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import { Form } from '../../../components/Form/Form';
import { FormConfiguration } from '../../../components/Form/Form.types';
import MuiPaper from '../../../components/Paper/Paper';
import { Radome } from '../../../redux/Radome/radome.types';
import { RadomeType } from '../../../redux/RadomeType/radomeType.types';
import { selectRadomeState } from '../../../redux/Radome/radome.slice';
import { setAlertState } from '../../../redux/Alert/alert.actions';
import { store } from '../../../redux/store';
import { uploadRadome } from '../../../redux/Radome/radome.actions';
import { useAppSelector } from '../../../redux/hooks';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Declare the form schema for validation purposes.
 */
const schema = yup.object({
	serialNumber: yup.string()
		.required(),
	radomeType: yup.object({
		id: yup.number()
			.defined(),
		radomeTypeName: yup.string()
			.defined()
			.required(),
	})
		.defined()
		.required(),
	exist: yup.number()
		.transform((val, originalVal) => {
			// The form control is a CheckboxInput, which works with boolean values.
			// Because exist is a number, we must access the original value because
			// Yup will have failed to parse it as a boolean.
			return originalVal ? 1 : 0;
		})
		.defined(),
});

// Declare the default values
const defaultValues: Omit<Radome, 'id' | 'timestampRegistered'> = {
	radomeType: { id: 0, radomeTypeName: '' },
	serialNumber: '',
	exist: 1,
	additionalInformation: '',
};

/**
 * Declare the form configuration used for constructing the form components.
 */
const config: Omit<FormConfiguration, 'control' | 'onSubmit'> = {
	fields: [
		{
			type: 'text',
			name: 'serialNumber',
			label: 'Serial Number',
			id: 'radome-serial-number',
			helperText: 'Remember to update headers in old rinex files!',
			required: true,
		},
		{
			type: 'autocomplete',
			name: 'radomeType',
			label: 'Radome Type',
			id: 'radome-radome-type',
			required: true,
			helperText: 'Remember to update headers in old rinex files!',
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option?.radomeTypeName || String(option?.id || ''),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_RADOMETYPES);
				const radomeTypes: RadomeType[] = await res.json();

				return radomeTypes;
			},
		},
		{
			type: 'checkbox',
			name: 'exist',
			label: 'Exist',
			id: 'radome-exist',
		},
		{
			type: 'multilineText',
			name: 'additionalInformation',
			label: 'Additional Information',
			id: 'radome-additional-information',
		}
	],
};

/**
 * Handler for submitting the radome edit form.
 */
const submitRadome = async (data: typeof defaultValues) => {
	const dispatch = store.dispatch;

	await dispatch(uploadRadome({ radome: {
		radomeTypeName: data.radomeType.radomeTypeName,
		serialNumber: data.serialNumber,
		exist: data.exist === 1 ? 1 : 0,
		additionalInformation: data.additionalInformation,
	} }));

	dispatch(setAlertState({
		severity: 'success',
		open: true,
		title: 'Success',
		message: 'New radome created with serialnumber: ' + data.serialNumber
	}));
};

/**
 * A radome create form component.
 */
export const RadomeCreate: React.FC<{}> = () => {
	const radome = useAppSelector(selectRadomeState);

	const { control, formState, handleSubmit, reset, trigger } = useForm<typeof defaultValues>({
		resolver: yupResolver(schema),
		mode: 'onChange',
		values: {
			...defaultValues,
		},
		defaultValues: {
			...defaultValues,
		},
	});

	const {
		isDirty,
		isSubmitting,
		isSubmitted,
		isSubmitSuccessful,
		isValidating,
		isValid,
	} = formState;

	const shouldValidateOnce = radome !== null;

	useEffect(() => {
		if (shouldValidateOnce) {
			trigger();
		}
	}, [shouldValidateOnce, trigger]);

	useEffect(() => {
		reset();
	}, [isSubmitSuccessful, reset]);

	return (
		<MuiPaper>
			<h1>Create new radome</h1>
			<Form
				control={control}
				onSubmit={handleSubmit(submitRadome)}
				isDirty={isDirty}
				isSubmitting={isSubmitting}
				isSubmitted={isSubmitted}
				isValidating={isValidating}
				isValid={isValid}
				readOnlyValues={[
				]}
				{...config}
			/>
		</MuiPaper>
	);
};
