// Currently named COORDSYS in DB
export interface ReferenceSystem {
	id: number,
	// Currently named COORDSYSNAME in DB
	referenceSystemName: string
}

export const referenceSystemTemplate: ReferenceSystem = {
	id: 0,
	referenceSystemName: '',
};
