
import { ApplicationDispatch, ApplicationGetState } from '../store';
import { SiteConfig, SiteConfigCreateType, SiteConfigTagUpdate, SiteConfigUpdateType } from './siteConfig.types';
import { getRequest, postRequest, putRequest } from '../../service/requests';
import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { getEnvStateData } from '../../service/envStateData';
import { siteConfigMock } from './siteConfig.mock';
import { siteConfigSlice } from './siteConfig.slice';

export const { setSiteConfigState, resetSiteConfigState } = siteConfigSlice.actions;

export const fetchNewSiteConfig = () => {
	return async (dispatch: ApplicationDispatch) => {
		const siteConfigData: SiteConfig | null =
			await getEnvStateData(getRequest<SiteConfig>(API_ENDPOINTS.GET_NEW_SITECONFIG), siteConfigMock);

		return dispatch(setSiteConfigState(siteConfigData));
	};
};

export const fetchSiteConfig = ({ siteConfigId }: { siteConfigId: number }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.GET_SITECONFIG.replace('{siteConfigId}', encodeURIComponent(siteConfigId));
		const siteConfig = await getEnvStateData(getRequest<SiteConfig>(url), siteConfigMock);

		if (siteConfig === null) {
			throw new Error(`Cannot find site config with id: ${siteConfigId}`);
		}

		return dispatch(setSiteConfigState(siteConfig));
	};
};

export const createSiteConfig = ({ siteconfig }: {siteconfig: SiteConfigCreateType}) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.POST_SITECONFIG;
		const createdSiteconfig = await getEnvStateData(postRequest<SiteConfig, typeof siteconfig>(url, siteconfig), siteConfigMock);

		if (createdSiteconfig === null) {
			throw new Error('Could not create siteconfig with fourcharid: ' + siteconfig.fourCharId);
		}

		return dispatch(setSiteConfigState(createdSiteconfig));
	};
};

export const updateSiteConfig = ({ siteconfig }: {siteconfig: SiteConfigUpdateType }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.PUT_SITECONFIG;
		const updatedSiteconfig = await getEnvStateData(putRequest<SiteConfig, typeof siteconfig>(url, siteconfig), null);

		if (updatedSiteconfig === null) {
			throw new Error(`Cannot update siteconfig with id ${siteconfig.id}`);
		}

		return dispatch(setSiteConfigState(updatedSiteconfig));
	};
};

// This updates a tag for a siteconfig based on which URL is passed (add/remove)
export const updateTagForSiteConfig = (url: string, siteConfigTag: SiteConfigTagUpdate) => {
	return async (dispatch: ApplicationDispatch) => {
		const updatedSiteConfig = await getEnvStateData(putRequest<SiteConfig, SiteConfigTagUpdate>(url, siteConfigTag), null);

		if (updatedSiteConfig === null) {
			throw new Error(`Failed to update siteconfig (Fourcharid: ${siteConfigTag.fourCharId}, Tag id: ${siteConfigTag.tagId})`);
		}

		return dispatch(setSiteConfigState(updatedSiteConfig));
	};
};

// This is an update(PUT) to add a linked tag on a siteconfig
export const addTagToSiteConfig = (siteConfigTag: SiteConfigTagUpdate) => {
	return async (dispatch: ApplicationDispatch) => {
		await updateTagForSiteConfig(API_ENDPOINTS.PUT_SITECONFIG_ADD_TAG, siteConfigTag)(dispatch);
	};
};

// This is an update(PUT) to remove a linked tak on a siteconfig
export const removeTagFromSiteConfig = (siteConfigTag: SiteConfigTagUpdate) => {
	return async (dispatch: ApplicationDispatch) => {
		await updateTagForSiteConfig(API_ENDPOINTS.PUT_SITECONFIG_REMOVE_TAG, siteConfigTag)(dispatch);
	};
};
