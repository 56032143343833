import { Chip } from '@mui/material';
import HorizontalTable from '../../components/Table/HorizontalTable/HorizontalTable';
import { HorizontalTableContentType } from '../../components/Table/Table.types';
import { Link } from 'react-router-dom';
import MuiPaper from '../../components/Paper/Paper';
import React from 'react';
import { SiteConfig } from '../../redux/SiteConfig/siteConfig.types';
import { momentPrettyFormat } from '../../service/moment';

const SiteConfigDetails = (siteConfig: SiteConfig): JSX.Element => {

	const statTypeName = siteConfig.siteConfigStatType.siteConfigStatTypeName;

	const getKeyDetails = (siteConfigData: SiteConfig): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		const isPresent = siteConfigData.id !== 0;

		contentList.push(
			{
				title: 'ID:',
				body: isPresent
					? <Link to={`/siteconfigs/${encodeURIComponent(`${siteConfigData.id}`)}/details`}>{siteConfigData.id}</Link>
					: '',
			},
			{
				title: 'Fourchar Id:',
				body: isPresent ? siteConfigData.fourCharId : '',
			},
			{
				title: 'Source id:',
				body: isPresent ? siteConfigData.sourceId : '',
			},
			{
				title: 'RTCM id:',
				body: isPresent ? siteConfigData.rtcmId : '',
			},
			{
				title: 'Path:',
				body: isPresent ? siteConfigData.storageLocation?.path : '',
			},
			{
				title: 'Prepared by:',
				body: isPresent ? siteConfigData.preparedBy : '',
			},
			{
				title: 'Changed by:',
				body: isPresent ? siteConfigData.changedBy : '',
			},
			{
				title: 'Operational:',
				body: isPresent && siteConfigData.timestampOperational !== null ? momentPrettyFormat(siteConfigData.timestampOperational) : '',
			},
			{
				title: 'Closed:',
				body: isPresent && siteConfigData.timestampClosed !== null ? momentPrettyFormat(siteConfigData.timestampClosed) : '',
			},
			{
				title: 'Established:',
				body: isPresent && siteConfigData.timestampEstablished !== null ? momentPrettyFormat(siteConfigData.timestampEstablished) : '',
			},
			{
				title: 'Registered:',
				body: siteConfigData.timestampRegistered !== null
					? momentPrettyFormat(siteConfigData.timestampRegistered)
					: '',
			}
		);

		return contentList;
	};

	const getDataflowDetails = (): HorizontalTableContentType[] => {
		const contentList: HorizontalTableContentType[] = [];

		contentList.push(
			{
				title: 'In CPOS:',
				body: siteConfig.dataFlow & 0x0001 ? 'YES' : 'NO',
			},
			{
				title: 'Rinex-2:',
				body: siteConfig.dataFlow & 0x0002 ? 'YES' : 'NO',
			},
			{
				title: 'Rinex-3:',
				body: siteConfig.dataFlow & 0x0004 ? 'YES' : 'NO',
			}
		);

		return contentList;
	};

	return (
		<MuiPaper title={siteConfig.fourCharId}>
			<Chip
				label={ siteConfig.siteConfigStatType.siteConfigStatTypeName }
				color={
					statTypeName === 'Operational' ? 'success'
						: statTypeName === 'Temporary' ? 'info'
							: statTypeName === 'Closed' ? 'error'
								: 'default'
				}
				style={{ margin: '5px' }}
			/>

			<br/>

			{siteConfig.antenna === null ? <Chip style={{ margin: '5px' }} label={'Mising antenna'} color={'error'}/> : ''}
			{siteConfig.receiver === null ? <Chip style={{ margin: '5px' }} label={'Missing receiver'} color={'error'}/> : ''}
			{siteConfig.storageLocation === null ? <Chip style={{ margin: '5px' }} label={'Missing storage location'} color={'error'}/> : ''}
			{siteConfig.cable === null ? <Chip style={{ margin: '5px' }} label={'Missing cable'} color={'error'}/> : ''}
			{siteConfig.markAntRefPoint === null ? <Chip style={{ margin: '5px' }} label={'Missing eccentricity'} color={'error'}/> : ''}
			{siteConfig.monument === null ? <Chip style={{ margin: '5px' }} label={'Missing monument'} color={'error'}/> : ''}
			{siteConfig.markRefPoint === null ? <Chip style={{ margin: '5px' }} label={'Missing coordinates'} color={'error'}/> : ''}
			{siteConfig.extClock === null ? <Chip style={{ margin: '5px' }} label={'Missing external clock'} color={'error'}/> : ''}

			<HorizontalTable content={getKeyDetails(siteConfig)}/>
			<HorizontalTable content={getDataflowDetails()}/>
		</MuiPaper>
	);
};

export default SiteConfigDetails;
