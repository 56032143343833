import { Alert, AlertTitle, Snackbar, SnackbarCloseReason } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { AlertPropsType } from './Alert.types';
import { alertStyles } from './Alert.styles';
import { selectAlertState } from '../../redux/Alert/alert.slice';
import { setAlertState } from '../../redux/Alert/alert.actions';

const MuiAlert: React.FC<AlertPropsType> = (props: AlertPropsType) => {
	const classes = alertStyles;
	const alertState = useAppSelector(selectAlertState);
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState<boolean>(alertState.open);

	useEffect(() => {
		setOpen(alertState.open);
	}, [alertState]);

	const handleClose = (event?: Event | React.SyntheticEvent, reason?: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
		dispatch(setAlertState({
			...alertState,
			open: false,
		}));
	};

	return (
		<Snackbar open={open} autoHideDuration={alertState.constant ? null : null} onClose={handleClose}>
			<Alert
				onClose={handleClose}
				variant={'filled'}
				severity={alertState.severity}
				sx={classes.root}
				className={props.className}
				style={props.style}
			>
				<AlertTitle>{alertState.title}</AlertTitle>
				{alertState.message}
			</Alert>
		</Snackbar>
	);
};

export default MuiAlert;
