// Component displayed on press Misc -> Types register change -> Countries
import { AddCountryType, Country } from '../../../redux/Country/country.types';
import { FormListType, addForm, getAttributeList, validateForm } from '../../Dashboard/DashboardTabs/Konfigurasjon/HistService';
import { addCountryElement, fetchCountryList, updateCountryElement } from '../../../redux/Country/country.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useEffect, useState } from 'react';

import MuiTable from '../../../components/Table/Table/Table';
import { regexList } from '../../../service/regex';
import { selectCountry } from '../../../redux/Country/country.slice';

interface MuiCountryElement {
	'ID': number;
	'Country Name': string;
	'Country Code': string;
}

/**
 * Helper function to map country to what's used by MUI table
 */
const countryToMuiElement = ({ id, countryName, countryCode }: Country) => ({
	'ID': id,
	'Country Name': countryName,
	'Country Code': countryCode
});

/**
 * Helper function to map MUI table element to country.
 */
const MuiElementToCountry = ({ 'ID': id, 'Country Name': countryName, 'Country Code': countryCode }: MuiCountryElement): Country => ({
	id,
	countryName,
	countryCode,
});

const Countries = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const countryData = useAppSelector(selectCountry);

	const [formValues, setFormValues] = useState<AddCountryType>({
		countryName: '',
		countryCode: ''
	});
	const [error, setError] = useState<any>(undefined);

	useEffect(() => {
		dispatch(fetchCountryList());
	}, [dispatch]);

	// Fields displayed in the country table head
	const formList: FormListType[] = [
		{
			label: 'ID',
			keyName: 'id',
			regex: regexList.freepass,
			number: true,
			readOnly: true,
		},
		{
			label: 'Country Name',
			keyName: 'countryName',
			regex: regexList.freepass,
			number: false,
			readOnly: false,
		},
		{
			label: 'Country Code',
			keyName: 'countryCode',
			regex: regexList.freepass,
			number: false,
			readOnly: false,
		}
	];

	// Field displayed in the add country popup window
	const newTagList: FormListType[] = [
		{
			label: 'Country Name',
			keyName: 'countryName',
			regex: regexList.freepass,
			number: false,
		},
		{
			label: 'Country Code',
			keyName: 'countryCode',
			regex: regexList.freepass,
			number: false,
		},
	];

	const handleFormChange = (keyProp: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({ ...formValues,	[keyProp]: event.target.value });
	};

	return (
		<>
			<h2>Countries</h2>
			<MuiTable
				name={'countryList'}
				head={getAttributeList(formList, 'label')}
				body={(countryData?.countryList || []).map(countryToMuiElement)}
				addable={{
					title: 'Add country',
					form: {
						modal: addForm(formValues, newTagList, error, handleFormChange),
						values: formValues,
						validate: () => {
							const validatedState = validateForm(formValues, formList);

							setError(validatedState.tmpErrObj);

							return validatedState.errorMsgStatus;
						},
					},
					buttonText: 'Add',
					addFuncCallback: (country: Country) => async () => {
						// We manually let the actions do their dispatching in this strange way because we need to be sure
						// the updates are fully completed before getting the newest country list from the server.
						await addCountryElement(country)(dispatch);
						await fetchCountryList()(dispatch);
					},
					param: 0
				}}
				editable={{
					editFuncCallback: (country: MuiCountryElement) => async () => {
						await updateCountryElement(MuiElementToCountry(country))(dispatch);
						await fetchCountryList()(dispatch);
					},
					validation: getAttributeList(formList, 'regex'),
					readOnly: getAttributeList(formList, 'readOnly')
				}}
			/>
		</>
	);
};

export default Countries;
