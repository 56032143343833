import { Antenna, antennaTemplate } from '../Antenna/antenna.types';
import { Cable, cableTemplate } from '../Cable/cable.types';
import { ExtClock, extClockTemplate } from '../ExtClock/extClock.types';
import { MarkAntRefPoint, markAntRefPointTemplate } from '../MarkAntRefPoint/markAntRefPoint.types';
import { MarkRefPoint, markRefPointTemplate } from '../MarkRefPoint/markRefPoint.types';
import { Monument, monumentTemplate } from '../Monument/monument.types';
import { Receiver, receiverTemplate } from '../Receiver/receiver.types';
import { SiteConfigHist, siteConfigHistTemplate } from '../SiteConfigHist/siteConfigHist.types';
import { SiteConfigStatType, siteConfigStatTypeTemplate } from '../SiteConfigStatType/siteConfigStatType.types';
import { SiteMinimal, siteMinimalTemplate } from '../Site/site.types';
import { StorageLocation, storageLocationTemplate } from '../StorageLocation/storageLocation.types';
import { Tag } from '../Tag/tag.types';

export interface SiteConfig {
	id: number;
	site: SiteMinimal,
	monument: Monument | null;
	markRefPoint: MarkRefPoint | null;
	extClock: ExtClock | null;
	timestampRegistered: string;
	timestampOperational: string | null;
	timestampClosed: string | null;
	timestampEstablished: string | null;
	fourCharId: string;
	preparedBy: string;
	sourceId: number;
	rtcmId: number | null;
	receiver: Receiver | null;
	antenna: Antenna | null;
	cable: Cable | null;
	siteConfigStatType: SiteConfigStatType;
	markAntRefPoint: MarkAntRefPoint | null;
	siteConfigHists: SiteConfigHist[];
	tags: Tag[] | null;
	changedBy: string | null;
	additionalInformation: string | null;
	storageLocation: StorageLocation | null;
	dataFlow: number;
	siteconfigVersion: number | null;
}

export interface SiteConfigCreateType {
	fourCharId: string,
	siteId: number,
	receiverId: number | null,
	antennaId: number | null,
	cableId: number | null,
	monumentId: number | null,
	markAntRefPointId: number | null,
	markRefPointId: number | null,
	extClockId: number | null,
	additionalInformation: string | null,
	timestampEstablished: string | null,
	siteconfigStatTypeId: number | null,
	dataFlow: number,
	storageLocationId: number | null,
	tagIds: number[] | null
}

export interface SiteConfigUpdateType {
	id: number,
	fourCharId: string,
	siteId: number,
	receiverId: number | null,
	antennaId: number | null,
	cableId: number | null,
	monumentId: number | null,
	markAntRefPointId: number | null,
	extClockId: number | null,
	additionalInformation: string | null,
	timestampEstablished: string | null,
	siteconfigStatTypeId: number | null,
	dataFlow: number,
	storageLocationId: number | null,
	tagIds: number[] | null
}

export interface SiteConfigMinimal {
	id: number;
	siteId: number;
	fourCharId: string;
	sourceId: number;
	// timestampRegistrated: string,
	preparedBy: string;
}

export interface SiteConfigSummaryType {
	id: number,
	fourCharId: string;
	timestampOperational: string;
	timestampEstablished: string;
	timestampRegistered: string;
	dataFlow: number;
	sourceId: number;
	site: SiteMinimal;
	receiverId: number;
	antennaId: number;
	storageLocationPath: string;
	siteConfigStatTypeName: string;
}

// Used to update the relation between siteconfig and tag (SITECONFIG_TAG in DB)
export interface SiteConfigTagUpdate {
	fourCharId: string;
	tagId: number;
}

// Create new siteconfig
export type AddSiteConfigType = Omit<SiteConfigMinimal, 'id'>;
export type AddSiteConfigParamType = AddSiteConfigType;

export const siteConfigTemplate: SiteConfig = {
	id: 0,
	site: siteMinimalTemplate,
	monument: monumentTemplate,
	markRefPoint: markRefPointTemplate,
	extClock: extClockTemplate,
	timestampRegistered: '',
	timestampOperational: '',
	timestampClosed: null,
	timestampEstablished: '',
	fourCharId: '',
	preparedBy: '',
	sourceId: 0,
	rtcmId: 0,
	receiver: receiverTemplate,
	antenna: antennaTemplate,
	cable: cableTemplate,
	siteConfigStatType: siteConfigStatTypeTemplate,
	markAntRefPoint: markAntRefPointTemplate,
	siteConfigHists: [siteConfigHistTemplate],
	tags: [],
	changedBy: '',
	additionalInformation: '',
	storageLocation: storageLocationTemplate,
	dataFlow: 0,
	siteconfigVersion: 0,
};
