
import { editConfig, editDefaultValues, editSchema } from './misc';
import { formatFakeLocalTime, momentPrettyFormat } from '../../../service/moment';

import { Form } from '../../../components/Form/Form';
import { Loading } from './MiscComponents';
import type { UpdateInfDocType } from '../../../redux/InfDoc/infdoc.types';

import { selectInfDoc } from '../../../redux/InfDoc/infdoc.slice';
import { store } from '../../../redux/store';
import { updateInfDoc } from '../../../redux/InfDoc/infDoc.actions';
import { useAppSelector } from '../../../redux/hooks';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const submitInfDoc = async (data: typeof editDefaultValues) => {
	const { id: infDocTypeId } = data.infDocType;
	const { id: siteId } = data.site;
	const { id: siteConfigId = null } = data.siteConfig || {};
	const putData: UpdateInfDocType = {
		id: data.id,
		timestampWritten: data.timestampWritten,
		writtenBy: data.writtenBy,
		description: data.description,
		infDocTypeId,
		siteId,
		siteConfigId,
	};

	await store.dispatch(updateInfDoc({ infDoc: putData }));
};

export const ImageEditForm: React.FC<{}> = () => {
	const infDoc = useAppSelector(selectInfDoc);

	const { control, formState, handleSubmit, reset, trigger } = useForm<typeof editDefaultValues>({
		resolver: yupResolver(editSchema),
		mode: 'onChange',
		values: {
			...editDefaultValues,
			...infDoc,
			timestampWritten: formatFakeLocalTime(infDoc?.timestampWritten),
		},
		defaultValues: {
			...editDefaultValues,
		},
	});

	const {
		isDirty,
		isSubmitting,
		isSubmitted,
		isSubmitSuccessful,
		isValidating,
		isValid,
	} = formState;

	const shouldValidateOnce = infDoc !== null;

	useEffect(() => {
		if (shouldValidateOnce) {
			trigger();
		}
	}, [shouldValidateOnce, trigger]);

	useEffect(() => {
		reset();
	}, [isSubmitSuccessful, reset]);

	if (infDoc === null) {
		return <Loading />;
	}

	return (
		<Form
			control={control}
			onSubmit={handleSubmit(submitInfDoc)}
			isDirty={isDirty}
			isSubmitting={isSubmitting}
			isSubmitted={isSubmitted}
			isValidating={isValidating}
			isValid={isValid}
			readOnlyValues={[
				{ label: 'Filename', value: infDoc?.filename },
				{ label: 'Timestamp Stored (UTC Time)', value: momentPrettyFormat(infDoc?.timestampStored) },
			]}
			{...editConfig}
		/>
	);
};
