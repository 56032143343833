import { AddTagParamType, Tag } from '../../../redux/Tag/tag.types';
import { FormListType, addForm, getAttributeList, validateForm } from '../../Dashboard/DashboardTabs/Konfigurasjon/HistService';
import React, { useEffect, useState } from 'react';
import { addTagElement, deleteTagElement, fetchTagList, putTagElement } from '../../../redux/Tag/tag.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import MuiTable from '../../../components/Table/Table/Table';
import { regexList } from '../../../service/regex';
import { selectTagList } from '../../../redux/Tag/tag.slice';

// Component displayed on press Misc -> Types register change -> Tags
const Tags = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const tagData = useAppSelector(selectTagList);

	// Array of all tags in the component
	const [tagDataList, setTagDataList] = useState<any[]>([]);
	// Array used when adding new tag
	const [formValues, setFormValues] = useState<AddTagParamType>({
		tagText: '',
	});

	const [error, setError] = useState<any>(undefined);

	// Fields displayed in the Tag table head
	const formList: FormListType[] = [
		{
			label: 'ID',
			keyName: 'id',
			regex: regexList.freepass,
			number: true,
			readOnly: true,
		},
		{
			label: 'Tag',
			keyName: 'tagText',
			regex: regexList.freepass,
			number: false,
			readOnly: false,
		},
	];

	// Field displayed in the Add tag popup window
	const newTagList: FormListType[] = [
		{
			label: 'Tag',
			keyName: 'tagText',
			regex: regexList.freepass,
			number: false,
		},
	];

	useEffect(() => {
		const fetchData = async () => {
			await dispatch(fetchTagList());
		};

		fetchData();

	}, [dispatch]);

	useEffect(() => {
		const tmpList: any[] = [];

		if (tagData !== null) {
			const tagArray = Object.values(tagData);

			if (tagArray !== undefined) {
				tagArray.forEach((tagElement: Tag) => {
					if (tagElement.id !== null) {
						const tmp = {
							'ID': tagElement.id,
							'Tag': tagElement.tagText,
						};

						// Adds only unique elements (on ID) to the array of objects
						if (tmpList.find(x => x.ID === tmp.ID) === undefined) {
							tmpList.push(tmp);
						}
					}
				});
			}
		}

		// Sort the array on the tag text
		tmpList.sort((a, b) => {
			if (a.Tag.toLowerCase() < b.Tag.toLowerCase()) {
				return -1;
			}
			if (a.Tag.toLowerCase() > b.Tag.toLowerCase()) {
				return 1;
			}

			return 0;
		});
		setTagDataList(tmpList);

	}, [tagData]);

	const handleFormChange = (keyProp: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormValues({
			...formValues,
			[keyProp]: event.target.value
		});
	};

	return (
		<>
			<h2>Tags</h2>
			{
				<>
					<MuiTable
						name={'Taglist'}
						head={getAttributeList(formList, 'label')}
						body={tagDataList}
						addable={{
							title: 'Add tag',
							form: {
								modal: addForm(formValues, newTagList, error, handleFormChange),
								values: formValues,
								validate: () => {
									const validatedState = validateForm(formValues, formList);

									setError(validatedState.tmpErrObj);

									return validatedState.errorMsgStatus;
								},
							},
							buttonText: 'Add',
							addFuncCallback: addTagElement,
							param: 0
						}}
						deleteFuncCallback={deleteTagElement}
						editable={{
							editFuncCallback: putTagElement,
							validation: getAttributeList(formList, 'regex'),
							readOnly: getAttributeList(formList, 'readOnly')
						}}
					/>
				</>
			}
		</>
	);
};

export default Tags;
