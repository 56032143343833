import * as yup from 'yup';

import type { FormConfiguration, TestComponents } from '../../../components/Form/Form.types';
import type { InfDocType, InfDocTypeType } from '../../../redux/InfDoc/infdoc.types';
import { API_ENDPOINTS } from '../../../service/routes/ApiEndpoints';
import { SiteConfigMinimal } from '../../../redux/SiteConfig/siteConfig.types';
import type { SiteMinimal } from '../../../redux/Site/site.types';
import { formatFakeUtcTime } from '../../../service/moment';
import moment from 'moment';

const testTimestampIsValid: TestComponents = [
	'isDate',
	'${path} is not a valid datetime',
	(val: any) => {
		if (val === '') {
			return true;
		}

		return moment.utc(val, moment.ISO_8601).isValid();
	}
];

const editSchemas = {
	site: yup.object({
		id: yup.number()
			.required()
			.min(1),
	})
		.required(),
	siteConfig: yup.object({
		id: yup.number()
			.required()
			.min(1),
	})
		.defined()
		.nullable(),
	infDocType: yup.object({
		id: yup.number()
			.required()
			.min(1),
	})
		.required(),
	timestampWritten: yup.string()
		.required()
		.transform(formatFakeUtcTime)
		.test(...testTimestampIsValid),
	writtenBy: yup.string()
		.required(),
	description: yup.string()
		.defined()
		.nullable(),
};

const createSchemas = {
	...editSchemas,
	files: yup.array().of(
		yup.mixed()
			.required()
	)
		.test('atLeastOne', 'Please select at least one file', (val: any) => {
			return val.length > 0;
		})
		.test('atMost10MB', 'Combined file size cannot exceed 10 MB', (val: any) => {
			const files = val as File[];

			// Only test when we actually have files selected;
			if (files.length === 0) {
				return true;
			}

			// 10 MB
			const maxSize = 1024 * 1024 * 10;

			const actualSize = files
				.map(f => f.size)
				.reduce((combinedSize, currentSize) => combinedSize + currentSize, 0);

			return maxSize >= actualSize;
		})
};

export const editSchema = yup.object(editSchemas);

export const createSchema = yup.object(createSchemas);

export const editDefaultValues: Omit<InfDocType, 'timestampStored' | 'filename'> = {
	id: 0,
	site: { id: 0, siteName: '' },
	siteConfig: null,
	infDocType: { id: 0, infDocTypeName: '' },
	timestampWritten: '',
	writtenBy: '',
	description: '',
};

export const createDefaultValues: Omit<typeof editDefaultValues, 'id'> & { files: any[] } = {
	...editDefaultValues,
	files: [],
};

export const editConfig: Omit<FormConfiguration, 'control' | 'onSubmit'> = {
	fields: [
		{
			type: 'autocomplete',
			name: 'site',
			label: 'Site',
			id: 'infdoc-site',
			required: true,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option?.siteName || String(option?.id || ''),
			getOptions: async () => {
				// TODOo: Make more performant
				const res = await fetch(API_ENDPOINTS.GET_SITE_AND_SITE_CONFIGS);
				const { siteList } = await res.json() as { siteList: SiteMinimal[] };

				return siteList;
			},
		},
		{
			type: 'autocomplete',
			name: 'siteConfig',
			label: 'Site config',
			id: 'infdoc-site-config',
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option?.fourCharId || String(option?.id || ''),
			getOptions: async () => {
				// TODOo: Make more performant
				const res = await fetch(API_ENDPOINTS.GET_SITE_AND_SITE_CONFIGS);
				const { siteConfigList } = await res.json() as { siteConfigList: SiteConfigMinimal[] };

				return siteConfigList;
			},
		},
		{
			type: 'autocomplete',
			name: 'infDocType',
			label: 'Category',
			id: 'infdoc-infdoctype',
			required: true,
			isOptionEqualToValue: (option, value) => option.id === value.id,
			getOptionLabel: option => option?.infDocTypeName || String(option?.id || ''),
			getOptions: async () => {
				const res = await fetch(API_ENDPOINTS.GET_INFDOCTYPES);

				return res.json() as Promise<InfDocTypeType[]>;
			}
		},
		{
			type: 'datetime',
			name: 'timestampWritten',
			label: 'Captured (Timestamp written)',
			id: 'infdoc-timestamp-written',
			required: true,
		},
		{
			type: 'text',
			name: 'writtenBy',
			label: 'Written by',
			id: 'infdoc-written-by',
			required: true,
		},
		{
			type: 'multilineText',
			name: 'description',
			label: 'Description',
			id: 'infdoc-description',
		}
	],
};

export const createConfig: typeof editConfig = {
	fields: [
		{
			type: 'file',
			name: 'files',
			label: 'Files',
			id: 'infdoc-files',
			required: true,
		},
		...editConfig.fields,
	],
};
