import { AntennaType, AntennaTypeListType } from './antennaType.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ApplicationStateType } from '../store';

const initialState = null as AntennaTypeListType | null;

export const antennaTypeSlice = createSlice({
	name: 'antennaType',
	initialState,
	reducers: {
		setAntennaTypeList: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return action.payload;
		},
		addAntennaType: (state, action: PayloadAction<AntennaType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return { antennaTypeList: [action.payload] };
			}

			return { antennaTypeList: [...state.antennaTypeList, action.payload] };
		},
		updateAntennaType: (state, action: PayloadAction<AntennaType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return { antennaTypeList: [action.payload] };
			}

			return {
				antennaTypeList: state.antennaTypeList.map(antennaType => {
					if (antennaType.id === action.payload?.id) {
						return action.payload;
					}

					return antennaType;
				}),
			};
		},
	},
});

export const selectAntennaType = (state: ApplicationStateType) => state.antennaTypeState;

export default antennaTypeSlice.reducer;
