import { ExtClockType, ExtClockTypeListType } from './extClockType.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { ApplicationStateType } from '../store';

const initialState = null as ExtClockTypeListType | null;

export const extClockTypeSlice = createSlice({
	name: 'extClockType',
	initialState,
	reducers: {
		setExtClockTypeList: (state, action: PayloadAction<typeof initialState>) => {
			if (action.payload === null) {
				return state;
			}

			return action.payload;
		},
		addExtClockType: (state, action: PayloadAction<ExtClockType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					extClockTypeList: [action.payload],
				};
			}

			return {
				extClockTypeList: [...state.extClockTypeList, action.payload],
			};
		},
		updateExtClockType: (state, action: PayloadAction<ExtClockType | null>) => {
			if (action.payload === null) {
				return state;
			}

			if (state === null) {
				return {
					extClockTypeList: [action.payload],
				};
			}

			return {
				extClockTypeList: state.extClockTypeList.map(extClockType => {
					if (extClockType.id === action.payload?.id) {
						return action.payload;
					}

					return extClockType;
				}),
			};
		},
	},
});

export const selectExtClockTypeList = (state: ApplicationStateType) => state.extClockTypeState;

export default extClockTypeSlice.reducer;
