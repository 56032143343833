import moment from 'moment';

export enum DatetimeFormat {
	DATETIME = 'DD.MM.YYYY HH:mm',
	DATE = 'DD.MM.YYYY',
}

interface FormatProps {
	date: string,
	format: DatetimeFormat,
}

/**
 * Read a timestamp as if it were local time, ignoring any timezone information.
 *
 * @returns a string, which might be empty if unable to parse input data.
 */
export const formatFakeLocalTime = (inp: string | null | undefined): string => {
	if (inp === null || inp === undefined) {
		return '';
	}

	if (inp === '') {
		return '';
	}

	const m = moment.utc(inp);

	if (!m.isValid()) {
		return inp;
	}

	const date = m.format('YYYY-MM-DD');
	const time = m.format('HH:mm');

	const localTime = moment(`${date} ${time}`);

	if (localTime.isValid()) {
		return localTime.format('YYYY-MM-DDTHH:mmZ');
	}

	return inp;
};

/**
 * Write a timestamp as if it were UTC time, faking any timezone information.
 *
 * @returns a string, which might be empty if unable to parse input data.
 */
export const formatFakeUtcTime = (inp: string | null | undefined): string => {
	if (inp === null || inp === undefined) {
		return '';
	}

	if (inp === '') {
		return '';
	}

	const m = moment(inp);

	if (!m.isValid()) {
		return inp;
	}

	const date = m.format('YYYY-MM-DD');
	const time = m.format('HH:mm');

	return moment.utc(`${date}T${time}Z`).toISOString();
};

export const momentFormat = ({ date, format }: FormatProps): string => {
	return moment(date)
		.utc()
		.format(format);
};

export const momentPrettyFormat = (date: string): string => {
	return momentFormat({ date, format: DatetimeFormat.DATETIME });
};

export const momentApiFormat = (date: string): string => {
	return moment(date, DatetimeFormat.DATETIME).parseZone()
		.utc(true)
		.toJSON();
};
