import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { fetchReceiver, resetReceiverState } from '../../../redux/Receiver/receiver.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { APP_ROUTES } from '../../../service/routes/AppRouter.constants';
import Grid from '@mui/material/Grid';
import Loader from '../../../components/Loader/Loader';
import MuiPaper from '../../../components/Paper/Paper';
import MuiTabs from '../../../components/Tabs/Tabs';
import { Receiver } from '../../../redux/Receiver/receiver.types';
import ReceiverDetails from './ReceiverDetails';
import ReceiverHistory from './ReceiverHistory';
import ReceiverInstallationHist from './ReceiverInstallationHist';
import { ReceiverUpdate } from './ReceiverUpdate';
import { selectReceiverState } from '../../../redux/Receiver/receiver.slice';

const Content = (receiver: Receiver): JSX.Element => {
	return (
		<div>
			<MuiTabs name={`Receiver-${receiver.id}-Alternatives`} tabsList={[
				{ label: 'Details', component: <ReceiverDetails {...receiver} /> },
				{ label: 'Edit', component: <ReceiverUpdate /> },
				{ label: 'Receiver History', component: <ReceiverHistory { ...receiver }/> },
				{ label: 'Installation History', component: <ReceiverInstallationHist {...receiver} /> },
			]} />
		</div>
	);
};

const ReceiverSingle: React.FC = () => {
	const { id = '0' } = useParams<{ id: string }>();
	const dispatch = useAppDispatch();

	// Receiver is used in several child components, so let's fetch it here.
	useEffect(() => {
		dispatch(fetchReceiver({ receiverId: Number.parseInt(id, 10) }));

		// When navigating away (i.e. the component is unmounted), let's clear the receiver.
		return () => {
			dispatch(resetReceiverState());
		};
	}, [dispatch, id]);

	const receiver = useAppSelector(selectReceiverState);

	if (receiver === null) {
		return <Loader/>;
	}

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<p><Link to={APP_ROUTES.UTILITIES.RECEIVERS}>← Back to Receivers</Link></p>
					<h1>Receiver {receiver.receiverType.receiverTypeName} {receiver.serialNumber} (ID: {receiver.id})</h1>
					<Grid item xs={12}>
						<Content {...receiver} />
					</Grid>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default ReceiverSingle;
