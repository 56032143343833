import type { InfDocType, UpdateInfDocType } from './infdoc.types';
import { deleteRequest, getRequest, putRequest } from '../../service/requests';

import { API_ENDPOINTS } from '../../service/routes/ApiEndpoints';
import { ApplicationDispatch } from '../store';
import { getEnvStateData } from '../../service/envStateData';
import { infDocMock } from './infDoc.mock';
import { infDocSlice } from './infdoc.slice';
import { setAlertState } from '../Alert/alert.actions';

export const { setInfDoc, resetInfDoc } = infDocSlice.actions;

/**
 * Fetches an infDoc based on its ID.
 */
export const fetchInfDoc = ({ infDocId } : { infDocId: number }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.GET_INFDOC.replace('{id}', infDocId.toString(10));
		const infDoc = await getEnvStateData(getRequest<InfDocType>(url), infDocMock);

		if (infDoc === null) {
			throw new Error(`Cannot find infDoc with id ${infDocId}`);
		}

		return dispatch(setInfDoc(infDoc));
	};
};

/**
 * Updates an infDoc.
 */
export const updateInfDoc = ({ infDoc }: { infDoc: UpdateInfDocType }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.PUT_INFDOC;
		const updatedInfDoc = await getEnvStateData(putRequest<InfDocType, typeof infDoc>(url, infDoc), infDocMock);

		if (updatedInfDoc === null) {
			throw new Error(`Cannot update infDoc with id ${infDoc.id}`);
		}

		dispatch(setAlertState({
			severity: 'success',
			open: true,
			title: 'Image update successful',
			message: `Successfully updated image with id ${infDoc.id}`,
		}));

		return dispatch(setInfDoc(updatedInfDoc));
	};
};

/**
 * Delete an infDoc.
 */
export const deleteInfDoc = ({ id }: { id: number }) => {
	return async (dispatch: ApplicationDispatch) => {
		const url = API_ENDPOINTS.DELETE_INFDOC.replace('{id}', id.toString(10));
		const deletedInfDoc = await getEnvStateData(deleteRequest<InfDocType>(url), infDocMock);

		if (deletedInfDoc === null) {
			throw new Error(`Cannot delete infDoc with id ${id}`);
		}

		dispatch(setAlertState({
			severity: 'success',
			open: true,
			title: 'InfDoc deletion successful',
			message: `Successfully deleted infDoc with id ${id}`,
		}));

		return dispatch(resetInfDoc());
	};
};
