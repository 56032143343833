import React from 'react';

const Networkcomponents: React.FC = () => {
	return (
		<>
			<h1>Networkcomponents</h1>
		</>
	);
};

export default Networkcomponents;

