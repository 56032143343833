import React from 'react';

const Bilder = (): JSX.Element => {
	return (
		<>
			<h1>Images</h1>
		</>
	);
};

export default Bilder;
