import { ReceiverHist, receiverHistTemplate } from '../ReceiverHist/receiverHist.types';
import { ReceiverType, receiverTypeTemplate } from '../ReceiverType/receiverType.types';
import { SatSystem, satSystemTemplate } from '../SatSystem/satSystem.types';
import { SiteMinimal, siteMinimalTemplate } from '../Site/site.types';

export interface Receiver {
	id: number;
	site: SiteMinimal | null;
	timestampRegistered: string;
	timestampGuarantee: string;
	serialNumber: string;
	equipmentLabel: string | null;
	firmwareVersion: string | null;
	fourCharIds?: string[];
	ipAddress: string | null;
	exist: number | null;
	elevationCutoffAngle: number | null;
	receiverType: ReceiverType;
	receiverHists: ReceiverHist[];
	satSystems: SatSystem[];
	additionalInformation?: string | null;
}

export interface ReceiverSummary {
	id: number;
	serialNumber: string;
	receiverTypeName: string;
	satSystemNames: string;
	firmware: string;
	elevationCutOffAngle: number;
	timestampRegistrated: string;
	timestampGuarantee: string;
	equipmentLabel: string;
	site: SiteMinimal | null;
	siteConfigFourCharId: string;
	ipAddress: string;
	exist: number;
	additionalInformation: string;
}

export type ReceiverUpdateType = Omit<Receiver, 'site' | 'timestampRegistered' | 'receiverHists'> & { timestampSet: string };

export const receiverTemplate: Receiver = {
	id: 0,
	site: siteMinimalTemplate,
	timestampRegistered: '',
	timestampGuarantee: '',
	serialNumber: '',
	equipmentLabel: '',
	firmwareVersion: '',
	fourCharIds: [''],
	ipAddress: '',
	exist: null,
	elevationCutoffAngle: null,
	receiverType: receiverTypeTemplate,
	receiverHists: [receiverHistTemplate],
	satSystems: [satSystemTemplate],
	additionalInformation: '',
};
