import { ImageCard, Loading } from './MiscComponents';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { fetchInfDoc, resetInfDoc } from '../../../redux/InfDoc/infDoc.actions';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { APP_ROUTES } from '../../../service/routes/AppRouter.constants';
import Grid from '@mui/material/Grid';
import { ImageActions } from './ImageActions';
import { ImageEditForm } from './ImageEditForm';
import { InfDocType } from '../../../redux/InfDoc/infdoc.types';
import MuiPaper from '../../../components/Paper/Paper';
import MuiTabs from '../../../components/Tabs/Tabs';
import { selectInfDoc } from '../../../redux/InfDoc/infdoc.slice';

const Heading: React.FC<Pick<InfDocType, 'id'>> = ({ id }) => {
	const infDoc = useAppSelector(selectInfDoc);

	return (
		<h1>
			{
				infDoc === null
					? <span>Image (ID: <code>{id}</code>)</span>
					: <span>Image <code>{infDoc.filename}</code> (ID: <code>{id}</code>)</span>
			}
		</h1>
	);
};

const Details: React.FC<Pick<InfDocType, 'id'>> = ({ id }) => {
	const infDoc = useAppSelector(selectInfDoc);

	if (infDoc === null) {
		return <Loading />;
	}

	return <ImageCard infDoc={infDoc} />;
};

const Content: React.FC<Pick<InfDocType, 'id'>> = ({ id }) =>
	<div>
		<MuiTabs name={`InfDoc-${id}-Alternatives`} tabsList={[
			{ label: 'Details', component: <Details id={id} /> },
			{ label: 'Edit', component: <ImageEditForm /> },
			{ label: 'Actions', component: <ImageActions /> },
		]} />
	</div>;

const ImageSingle: React.FC = () => {
	const dispatch = useAppDispatch();
	const { id: idParam = '0' } = useParams<{ id: string }>();
	const id = Number.parseInt(idParam, 10);
	const infDoc = useAppSelector(selectInfDoc);

	useEffect(() => {
		dispatch(fetchInfDoc({ infDocId: id }));

		return () => {
			dispatch(resetInfDoc());
		};
	}, [dispatch, id]);

	let backLink = '';

	if (infDoc !== null) {
		const params = new URLSearchParams();

		params.set('siteId', infDoc.site.id.toString(10));
		params.set('category', infDoc.infDocType.infDocTypeName);

		backLink = APP_ROUTES.OTHER.IMAGES.SHOW_IMAGES + '?' + params.toString();
	}

	return (
		<MuiPaper>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{
						backLink !== ''
							? <p><Link to={backLink}>← Back to images</Link></p>
							: <p>← Back to images</p>
					}
					<Heading id={id} />
					<Grid item xs={12}>
						<Content id={id} />
					</Grid>
				</Grid>
			</Grid>
		</MuiPaper>
	);
};

export default ImageSingle;
