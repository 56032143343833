import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { InputProps } from './Form.types';
import React from 'react';

/**
 * Checkbox input form field.
 *
 * The value managed by the control should be a boolean.
 */
export const CheckboxInput: React.FC<InputProps> = ({ control, name, label, id, disabled = false, required = false }) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, onChange, value, ref, name: fieldName }, fieldState: { error } }) => {
				const checked = Boolean(value);

				return (
					<FormControl error={Boolean(error)}>
						<FormControlLabel
							label={label}
							name={fieldName}
							control={
								<Checkbox
									id={id}
									required={required}
									disabled={disabled}
									checked={checked}
									onBlur={onBlur}
									ref={ref}
									onChange={event => onChange(event.target.checked)}
								/>
							}
						/>
						{
							error?.message
								? <FormHelperText>{error.message}</FormHelperText>
								: <></>
						}
					</FormControl>
				);
			}}
		/>
	);
};
